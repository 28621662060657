import React, { useEffect } from 'react'
import CommanButton from '../../component/CommanButton'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountStatusReportList } from '../../redux/slice/RevenueReport'
import { useSignal } from '@preact/signals-react'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../../component/loader/Loader'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify'

export default function AccountStatusReport() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token?.data?.token)
  const data = useSignal([])
  const onLoad = useSignal(false)
  const currentPage = useSignal(1)

  const monthYear = useSignal()
  const billingCycle = useSignal('')

  const getData = async (date) => {
    onLoad.value = true;
    try {
      // Fetch data using dispatch
      const response = await dispatch(getAccountStatusReportList({ token: token, billingDate: date }));
      console.log(response?.payload?.data?.body)

      data.value =  response?.payload?.data?.body
    } catch (err) {
      console.error(err);
    } finally {
      onLoad.value = false;
    }
  };


  // useEffect(() => {
  //   getData("")
  // }, [])


  const downloadCSV = () => {
    const csvData = [
      [
        "Date",
        "Billing Cycle",
        "Market",
        "Company Name",
        "Create Date",
        "Billing ID",
        "Service ID",
        "Billing Number",
        "Credit Limit",
        "Available Balance",
        "Name",
        "Email",
        "Mobile",
        "Sales Resp.",
        "ToneCast Status",
        "Last Campaign Name"
    ],
      ...data.value?.map(item => [
        dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value,  // Month-Year + Billing Cycle
        billingCycle.value,           // Billing Cycle
        item.market,                  // Market
        item.adminUser,               // Admin User
        item.companyName,             // Company Name
        // item.creationDate,            // Creation Date
        item.billingAccountId,        // Billing Account Id
        item.serviceAccountId,        // Service Account Id
        item.billingNumber,           // Billing Number
        item.creditLimit,             // Credit Limit
        item.availableBalance,        // Available Balance
        item.name,                    // Name
        item.email,                   // Email
        item.mobile,                  // Mobile
        item.salesRepresentative,     // Sales Representative
        item.tonecastStatus,          // Tonecast Status
        item.dateLastCampaign         // Date of Last Campaign
      ])
    ].map(e => e.join(",")).join("\n");

    let cycle = "01" ;
    if (billingCycle.value === '05') {
      cycle = "01"
    } else if (billingCycle.value === '20') {
      cycle = "02"
    } else if (billingCycle.value === '30') {
      cycle = "03"
    }


    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `Tonecast_Accounts_Report_${dayjs(data.value[0]?.Date).format('YYYYMM')}_Cycle${cycle}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onSubmit = () => {
    if (monthYear.value === undefined) {
      toast.error("Enter month and year")
    } else if (billingCycle.value?.length === 0) {
      toast.error("Select Billing cycle")
    } else {
      getData(dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value)
    }
  }


  const perPage = 10;
  let indexofLast = currentPage.value * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = data.value?.slice(indexofFirst, indexofLast)

  if (onLoad.value) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className='mx-3' >
        <div className='d-flex justify-content-between my-2'>
          <h3 className='fw-bolder'>Account Status Reports ✨</h3>
          <CommanButton disabled={data.value?.length === 0} onClick={downloadCSV}  >Download</CommanButton>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label={'"month" and "year"'} views={['month', 'year']} value={monthYear.value} onChange={(e) => { monthYear.value = e }} />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl sx={{ mx: 2, minWidth: 250 }} >
            <InputLabel id="demo-select-small-label">Billing Cycle</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={billingCycle.value}
              label="Billing Cycle"
              onChange={(e) => { billingCycle.value = e.target.value }}

            >
              <MenuItem value={"05"}>01</MenuItem>
              <MenuItem value={"20"}>02</MenuItem>
              <MenuItem value={"30"}>03</MenuItem>
            </Select>
          </FormControl>
          <div>
            <CommanButton onClick={onSubmit} >Submit</CommanButton>
          </div>
        </div>

        {
          data.value?.length === 0 ?
            <div className='d-flex justify-content-center fw-bold mx-5' >No Data found</div>
            :
            <>
              <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className='bodyColor'>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Cycle</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Market</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campany Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Create Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing ID</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Service ID</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Number</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Credit Limit</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Available Balance</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Email</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Mobile</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Sales Resp.</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>ToneCast Status</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Last Campaign Name</TableCell>
                      
                    </TableRow>
                  </TableHead>

                  <TableBody className="">
                    {activePage?.map((each, index) => {
                      return <ViewList key={index} data={each} billingCycle={billingCycle} monthYear={monthYear} />
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
                {
                  data.value?.length > perPage &&
                  <div className='d-flex justify-content-center my-4'>
                    <Pagination count={Math.ceil(data.value?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                  </div>
                }
            </>
        }
      </div>
    );
  }

}



const ViewList = ({ data, billingCycle, monthYear }) => {

  return (
    <TableRow >
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value}
      </TableCell>
      <TableCell align="center" >
        {billingCycle.value}
      </TableCell>
      <TableCell align="center" >
        {data.market}
      </TableCell>
      <TableCell align="center" >
        {data.adminUser}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.companyName}
      </TableCell>
      {/* <TableCell align="center" >
        {data.creationDate}
      </TableCell> */}
      <TableCell align="center" >
        {data.billingAccountId}
      </TableCell>
      <TableCell align="center" >
        {data.serviceAccountId}
      </TableCell>
      <TableCell align="center" >
        {data.billingNumber}
      </TableCell>
      <TableCell align="center" >
        {data.creditLimit}
      </TableCell>
      <TableCell align="center" >
        {data.availableBalance}
      </TableCell>
      <TableCell align="center" >
        {data.name}
      </TableCell>
      <TableCell align="center" >
        {data.email}
      </TableCell>
      <TableCell align="center" >
        {data.mobile}
      </TableCell>
      <TableCell align="center" >
        {data.salesRepresentative}
      </TableCell>
      <TableCell align="center" >
        {data.tonecastStatus}
      </TableCell>
      <TableCell align="center" >
        {data.dateLastCampaign?.slice(0,10)}
      </TableCell>


    </TableRow>
  );
}