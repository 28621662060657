import React, { useEffect } from 'react';
import { Avatar, Box, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signal } from '@preact/signals-react'
import { useDispatch, useSelector } from 'react-redux';
import { addToken } from '../../redux/slice/TokenSlice';
import { getProfileData } from '../../redux/slice/ProfileSlice';


let anchorEl = signal(null)

function HeaderComponent() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let token = useSelector(state => state.token?.data?.token)
    let userId = useSelector(state => state.vendor?.data?.user_id)
    const userDetails = useSelector(state => state.profile?.data?.username)
    const roleId = useSelector(state => state.vendor?.data?.roleId)

    useEffect(() => {
        dispatch(getProfileData({ token: token, id: userId }))
        anchorEl.value = null
    }, [])



    return (
        <Box sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'header',
            zIndex: 10,
            height: '40px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            px: 2,
            width: '100%',
            // borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
        }}>

            <Box>
                <IconButton onClick={(event) => { anchorEl.value = event.currentTarget }} >
                    <Chip
                        avatar={
                            <Avatar
                                style={{ color: '#fff' }}
                                sx={{ bgcolor: 'primary.main' }}>
                                {userDetails?.charAt(0)?.toUpperCase()}
                            </Avatar>
                        }
                        label={userDetails}
                        variant='outlined'
                    // size='small'
                    />
                </IconButton>
                <Menu
                    sx={{ mt: '30px', zIndex: 40 }}
                    id="menu-appbar"
                    anchorEl={anchorEl.value}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl.value)}
                    onClose={() => { anchorEl.value = null }}
                >
                    <MenuItem >
                        <Typography sx={{ fontWeight: 'bold', fontSize: '18px', color: 'primary' }}>
                            Hello {userDetails}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { navigate('/'); anchorEl.value = null }}>
                        <Typography sx={{
                            "&:hover": {
                                color: 'primary'
                            }
                        }}>
                            My Profile
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { navigate('/manageCharges') ;anchorEl.value = null }}>
                        <Typography sx={{
                            "&:hover": {
                                color: 'primary'
                            }
                        }}>
                            Manage Charges
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { navigate('/login'); anchorEl.value = null; sessionStorage.clear(); dispatch(addToken(null)); window.location.reload(); }}>
                        <Typography sx={{
                            "&:hover": {
                                color: 'primary'
                            }
                        }}>
                            Logout
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Box >
    );
}



export default HeaderComponent;

