import { Button, Container, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ActionRequestService, allVendorRequest, deleteRequestService } from '../../redux/slice/ServiceManager'
import { useSignal } from '@preact/signals-react'
import Loader from '../../component/loader/Loader'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs'

export default function AdminManageCharges() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let token = useSelector(state => state.token?.data?.token)
  let userId = useSelector(state => state.vendor?.data?.user_id)
  const roleId = useSelector(state => state.vendor?.data?.roleId)

  const requestData = useSignal([])
  const loading = useSignal(false)
  const currentPage = useSignal(1)

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    loading.value = true
    dispatch(allVendorRequest({ 'token': token, 'userId': userId }))
      .then((resp) => {
        // console.log(resp)
        if (resp?.payload?.status === 200) {
          requestData.value = resp?.payload?.data?.body
        }
        loading.value = false

      })
      .catch((err) => {
        loading.value = false
        toast.error("Error while creating")
      });
  }

  const requestAction = (status, reqId) => {
    loading.value = true
    dispatch(ActionRequestService({ 'token': token, 'status': status, 'updateDate': dayjs().format('YYYY-MM-DD HH:mm:ss'), 'requestId': reqId }))
      .then((resp) => {
        console.log(resp)
        if (resp?.payload?.status === 200) {
          // requestData.value = resp?.payload?.data?.body
          getList()
        }
        loading.value = false

      })
      .catch((err) => {
        loading.value = false
        toast.error("Error Found")
        console.log(err)
      });
  }

  const requestDelete = (reqId) => {
    loading.value = true
    dispatch(deleteRequestService({ 'token': token, 'requestId': reqId }))
      .then((resp) => {
        console.log(resp)
        if (resp?.payload?.status === 200) {
          // requestData.value = resp?.payload?.data?.body
          getList()
        }
        loading.value = false

      })
      .catch((err) => {
        loading.value = false
        toast.error("Error Found")
        console.log(err)
      });
  }


  const perPage = 15;
  let indexofLast = currentPage.value * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = requestData.value?.slice(indexofFirst, indexofLast)
  console.log(activePage)

  if (loading.value) {
    return <Loader />
  } else {

    return (
      <Container>
        <div className='d-flex justify-content-between my-2'>
          <h3 className='fw-bolder'>Manage Charges ✨</h3>
          {/* <Link
                          to='/campaign/addpvm'
                          style={{ textDecoration: 'none' }}>
                          <CommanButton type="submit" className="" ><AddIcon />Add PVM</CommanButton>
                      </Link> */}
        </div>

        {
          requestData.value?.length === 0 ?
            <div className='d-flex justify-content-center fw-bold' >No Data found</div>
            :
            <>
              <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className='bodyColor'>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Request Date</TableCell>
                      {
                        roleId === 1
                        &&
                        <TableCell align="center" style={{ fontWeight: 'bolder' }}>Creator Name</TableCell>
                      }
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Charges</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Status</TableCell>
                      {
                        roleId !== 1
                          ?
                          <>
                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>Approve Date</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>Delete</TableCell>
                          </>
                          :
                          <>
                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>Approve</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>Reject</TableCell>
                          </>
                      }
                      {/* <TableCell align="center" style={{ fontWeight: 'bolder' }}>Delete</TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody className="">
                    {
                      activePage?.map((each, index) => {
                        return <DataRow key={index} data={each} requestAction={requestAction} requestDelete={requestDelete} />
                      })
                    }
                  </TableBody>
                </Table>
                {
                  requestData.value?.length > perPage &&
                  <div className='d-flex justify-content-center my-4'>
                    <Pagination count={Math.ceil(requestData.value?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                  </div>
                }
              </TableContainer>
            </>
        }
      </Container>
    )
  }
}





const DataRow = ({ data, requestAction,requestDelete }) => {
  const roleId = useSelector(state => state.vendor?.data?.roleId)

  // console.log(data)
  return (
    <TableRow >
      <TableCell align='center' >
        {data.requestDate?.slice(0, 10)}
      </TableCell>
      {
        roleId === 1
        &&
        <TableCell align="center" >
          {data.username}
        </TableCell>
      }
      <TableCell align="center" >
        {data.serviceCharge?.serviceName}
      </TableCell>
      <TableCell align="center" >
        ${data.serviceCharge?.serviceCharges}
      </TableCell>
      <TableCell align="center" >
        {data.status}
      </TableCell>
      {
        roleId !== 1
          ?
          <>
            <TableCell align="center" >
              {data.status === "P" ? (data.updateDate?.slice(0, 10) === data.requestDate?.slice(0, 10) ? "Initiation" : data.updateDate?.slice(0, 10)) : data.updateDate?.slice(0, 10)}
            </TableCell>
            <TableCell align='center' >
              <IconButton onClick={()=>{requestDelete(data?.requestId)}} >
                <DeleteForeverIcon style={{ color: 'red' }} />
              </IconButton>
            </TableCell>
          </>
          :
          <>
            <TableCell align="center" >
              <Button disabled={data?.status !== 'P'} onClick={() => { requestAction('A', data?.requestId) }} variant='outlined' >Approve</Button>
            </TableCell>
            <TableCell align="center" >
              <Button disabled={data?.status !== 'P'} onClick={() => { requestAction('R', data?.requestId) }} variant='outlined' color='error' >Reject</Button>
            </TableCell>
          </>
      }
    </TableRow>
  );
}




{/* {
          campApprove?.linkId === parseInt(process.env.REACT_APP_CAMPAIGN_APPROVAL_LINK) &&
          <TableCell align="center" >
              <p style={textStyle} onClick={() => { approval("A", data.value.CAMPAIGN_ID) }} onMouseEnter={() => { isHovered.value = true }}
                  onMouseLeave={() => { isHovered.value = false }} >Approve</p>
          </TableCell>
      }
      <TableCell align="center" >
          <Tooltip title={`Modify ${data.value.CAMPAIGN_NAME}`} >
              <Link to='/campaign/modifyPVM' state={{ data: data.value }} >
                  <EditNoteSharpIcon />
              </Link>
          </Tooltip>
      </TableCell>
      <TableCell align="center">
          <Tooltip title={`Delete ${data.value.CAMPAIGN_NAME}`} >
              <button className='border-0 bg-transparent' onClick={() => { modal.value = !modal.value }}>
                  <DeleteForeverIcon style={{ color: 'red' }} />
              </button>
          </Tooltip>

          <Modal show={modal.value} onHide={() => { modal.value = !modal.value }}>
              <Modal.Header closeButton>
                  <Modal.Title className='text-danger'>Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure, you want to delete this {data.value.CAMPAIGN_NAME} ?</Modal.Body>
              <Modal.Footer>
                  <CommanButton className='btn btn-danger' onClick={() => remove(data.value.CAMPAIGN_ID)}>
                      Delete
                  </CommanButton>
              </Modal.Footer>
          </Modal>
      </TableCell> */}