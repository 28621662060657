import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../component/loader/Loader'
import { getAllVendorCreditHistory, getAllVendorCreditHistorySpecifiedBYDate } from './slice.js/CreditHistory'
import { Pagination, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { toast } from 'react-toastify'

export default function CreditHistoryDaily() {
  let { month, year } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [data, setdata] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  // const userId = useSelector(state => state?.profile?.data?.useridd)
  // const availableBalance = useSelector(state => state?.profile?.data?.availbalance)
  // const creditLimit = useSelector(state => state?.profile?.data?.creditlimit)
  const token = useSelector(state => state?.token?.data?.token)
  const userId = useSelector(state => state?.profile?.data?.useridd)

  if ((month === undefined) || (month === null) || (year === undefined) || (year === null)) {
    navigate('/creditHistory')
  }

  let monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  function findMonthIndex(month) {
    const index = monthName.indexOf(month);
    return index + 1;
  }

  useEffect(() => {
    let monthNumber = findMonthIndex(month)

    let data = {
      year: year,
      month: monthNumber,
      userId: userId,
      token: token
    }

    dispatch(getAllVendorCreditHistorySpecifiedBYDate(data))
      .then((response) => {
        if (response?.payload?.data?.httpStatusCode === 200) {
          setdata(response?.payload?.data?.body)
        } else {
          toast.error('Internal server error')
        }
        setLoading(false)
      }).catch((error) => {
        console.log(error)
        setLoading(false)
        toast.error("Error while getting list")
      })
  }, [userId])

  const perPage = 15;
  let indexofLast = currentPage * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = data?.slice(indexofFirst, indexofLast)

  if (loading) {
    return <Loader />
  } else {
    return (
      <div className='mx-3'>
        {
          data?.length === 0
            ?
            <div className='d-flex justify-content-center fs-5 fw-bold'>
              Data not found
            </div>
            :
            <TableContainer >
              <Table>
                <TableHead style={{ background: '' }}>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                      Date of approval
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                      Id
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                      Service
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                      Concept
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                      Invoiced amount
                    </TableCell>
                  </TableRow>
                </TableHead>


                {
                  activePage?.map((reportsData, index) => {
                    return (
                      <CreditHistoryDailyList
                        key={index}
                        data={reportsData}
                      />
                    )
                  })
                }


              </Table>
            </TableContainer>
        }
        {
          data?.length > perPage &&
          <div className='d-flex justify-content-center my-4'>
            <Pagination count={Math.ceil(data?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { setCurrentPage(p) }} />
          </div>
        }
      </div>
    )
  }
}


function CreditHistoryDailyList({ data }) {
  const [isHovered, setIsHovered] = useState(false);


  const tableRowStyle = {
    cursor: "pointer",
    boxShadow: isHovered ? '1px 2px 7px grey' : 'none'
  }

  return (

    <TableRow style={tableRowStyle} className='reportsList' onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} >
      <TableCell align="center" style={{ color: '#6366f1', fontWeight: '700', fontSize: '12px', height: '4em' }}>{data?.Date_of_approval}</TableCell>
      <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{data?.User_ID}</TableCell>
      <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{data?.Service}</TableCell>
      <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{data?.Concept}</TableCell>
      <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{data?.Invoiced_amount}</TableCell>
    </TableRow>
  )
}