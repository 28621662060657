import { configureStore } from '@reduxjs/toolkit'
import ProfileReducer from '../slice/ProfileSlice'
import VendorReducer from '../slice/VendorDataSlice'
import TokenReducer from '../slice/TokenSlice'
import ListCorp from '../slice/ListCorp'
import AWSvoices from '../slice/AWSvoices'
import LbsTemplates from '../slice/LbsTemplates'
import ReportPvm from '../slice/ReportPvm'
import ReportPvmBySpecificId from '../slice/ReportPvmBySpecificId'
import PvmSummaryReports from '../slice/PvmSummaryReports'
import ListAd from '../slice/ListAd'
import ListPVM from '../slice/ListPVM'
import ReportCorp from '../../pages/reporting/corpReport/slice/ReportCorp'
import ReportAd from '../../pages/reporting/adReport/slice/ReportAd'
import OriginationNumber from '../slice/OriginationNumber'
import CountryCode from '../slice/CountryCode'
import { CorpPackDetails } from '../slice/Pricing'

export const store = configureStore({
    reducer: {
        profile: ProfileReducer,
        vendor: VendorReducer,
        token: TokenReducer,
        awsVoices: AWSvoices,
        lbsTemplates: LbsTemplates,
        reportPvm: ReportPvm,
        reportPvmBySpecificId: ReportPvmBySpecificId,
        getPvmSummaryReports: PvmSummaryReports,
        pvmList: ListPVM,
        corpList: ListCorp,
        adList: ListAd,
        reportCorp: ReportCorp,
        reportAd: ReportAd,
        originationNumber: OriginationNumber,
        countryCode: CountryCode,
        corpPackDetails: CorpPackDetails
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    // devTools: false
})


