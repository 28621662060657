import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProgressBar from '../../../component/progressBar/ProgressBar';
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep';
import OuterBox from '../../../component/OuterBox';
import SelectInput from '../../../component/SelectInput';
import CommanButton from '../../../component/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { createCorp, getAllTemplatesList, getCorpList } from '../../../redux/slice/ListCorp';
import { useSignal } from '@preact/signals-react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { getCorpPackDetails } from '../../../redux/slice/Pricing';
import Loader from '../../../component/loader/Loader';
import { getProfileData } from '../../../redux/slice/ProfileSlice';

let audio = null;
const toastId = "createCorp"

const AddCorp = () => {
    const templateName = useSignal('');
    const employeeNo = useSignal([0, 0, 0]);
    const listTxt = useSignal(null);
    const audioFile = useSignal(null);
    const scheduleFrom = useSignal(null);
    const scheduleTo = useSignal(null);
    const days = useSignal([]);
    const review = useSignal(false);
    const p1 = useSignal('active');
    const p2 = useSignal('active');
    const p3 = useSignal('active');
    const p4 = useSignal('active');
    const p5 = useSignal('active');
    const audioPreview = useSignal(false);
    const loading = useSignal(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const packDetails = useSignal([])
    const backdroploading = useSignal(false)

    const availblnc = useSelector(state => state.profile.data?.availbalance)
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)


    const corpPackDetails = () => {
        loading.value = true
        dispatch(getCorpPackDetails(token))
            .then((response) => {
                if (response?.payload?.data?.httpStatusCode === 200) {
                    packDetails.value = response?.payload?.data?.body
                } else {
                    toast.error('Internal server error', { toastId: toastId })
                }
                loading.value = false
            }).catch((err) => {
                toast.error('Error while getting pack list', { toastId: toastId })
                loading.value = false
            })
    }

    useEffect(() => {
        corpPackDetails()
    }, [])


    // useEffect(() => {
    //     scheduleValidation()
    // }, [scheduleFrom.value, scheduleTo.value, days.value])


    // useEffect(() => {
    //     reviewValidation();
    // }, [review.value])

    const daysEntry = (event) => {
        const value = days.value?.indexOf(event.target?.value);

        if (value < 0) {
            days.value = [...days.value, event.target?.value]
            // setDays((prevState) => [...prevState, event.target.value]);
        } else {
            days.value = days.value?.filter((day) => day !== event.target.value)
            // setDays((prevState) => prevState.filter((day) => day !== event.target.value));
        }
    };

    const onFileHandler = (e) => {
        if (e.target?.files[0]?.size <= 2000000) {
            // setAudioFile(e.target?.files[0]);
            audioFile.value = e.target?.files[0]
        } else {
            toast.error("file must be less than 2MB", { toastId: toastId });
        }
    };

    // const templateValidation = async () => {
    //     // let flag = false

    //     if (templateName.value.length === 0) {
    //         p1.value = 'active'
    //         toast.error("Please enter template name", { toastId: toastId })
    //         return false
    //     } else {
    //         loading.value = true
    //         dispatch(getAllTemplatesList(token))
    //             .then((resp) => {
    //                 if (resp?.payload?.data?.httpStatusCode === 200) {
    //                     const foundString = resp?.payload?.data?.body?.find(element => element.toLowerCase() === templateName.value?.toLowerCase().trim());
    //                     if (foundString !== undefined) {
    //                         loading.value = false
    //                         toast.error("Template name already exists", { toastId: toastId })
    //                         p1.value = 'active'
    //                         return false
    //                     } else {
    //                         if (parseFloat(employeeNo.value) <= availblnc) {
    //                             loading.value = false
    //                             p1.value = 'completed'
    //                             return true
    //                         } else {
    //                             p1.value = 'active'
    //                             loading.value = false
    //                             toast.error("Credit Limit is not enough " + `You have US $ ${availblnc} available balance and US $1 per employee. (i.e. ${availblnc} * 1 = ${availblnc * 1} )`, { toastId: toastId });
    //                             return false
    //                         }
    //                     }
    //                 }
    //                 loading.value = false
    //             }).catch(() => {
    //                 p1.value = 'active'
    //                 loading.value = false
    //                 toast.error("Error while checking template name", { toastId: toastId })
    //                 return false
    //             })

    //     }
    //     // return flag;
    // }


    const templateValidation = async () => {
        if (templateName.value.length === 0) {
            p1.value = 'active';
            toast.error("Please enter template name", { toastId: toastId });
            return false;
        } else if (parseFloat(employeeNo.value[0]) === 0) {
            p1.value = 'active';
            toast.error("Please select pack", { toastId: toastId });
            return false;
        }
        else {
            backdroploading.value = true;

            try {
                const resp = await dispatch(getAllTemplatesList(token));

                if (resp?.payload?.data?.httpStatusCode === 200) {
                    const foundString = resp?.payload?.data?.body?.find(element => element.toLowerCase() === templateName.value?.toLowerCase().trim());

                    if (foundString !== undefined) {
                        backdroploading.value = false;
                        toast.error("Template name already exists", { toastId: toastId });
                        p1.value = 'active';
                        return false;
                    } else {
                        if (parseFloat(employeeNo.value[1]) <= availblnc) {
                            backdroploading.value = false;
                            console.log(availblnc - parseFloat(employeeNo.value[1]))
                            p1.value = 'completed';
                            return true;
                        } else {
                            p1.value = 'active';
                            backdroploading.value = false;
                            toast.error(`Credit Limit is not enough. Please select pack`, { toastId: toastId });
                            return false;
                        }
                    }
                }
            } catch (error) {
                p1.value = 'active';
                backdroploading.value = false;
                toast.error("Error while checking template name", { toastId: toastId });
                return false;
            }

            backdroploading.value = false;
        }
    };


    const listTxtValidation = () => {
        let flag = false
        if (listTxt.value !== null) {
            p2.value = 'completed'
            flag = true
        } else {
            p2.value = 'active'
            toast.error("Please upload your list file", { toastId: toastId })
            flag = false
        }
        return flag;
    }

    const audioFileValidation = () => {
        let flag = false
        if (audioFile.value !== null) {
            p3.value = 'completed'
            flag = true
        } else {
            p3.value = 'active'
            toast.error("Please upload your audio file", { toastId: toastId })
            flag = false
        }
        return flag;
    }

    if (scheduleFrom.value !== null && scheduleTo.value !== null && days.value?.length !== 0) {
        p4.value = 'completed'
    } else {
        p4.value = 'active'
    }


    if (review.value === true) {
        p5.value = 'completed'
    } else {
        p5.value = 'active'
    }



    const lastValidation = async () => {
        await templateValidation().then((result) => {
            const v1 = result;
            const v2 = listTxtValidation();
            const v3 = audioFileValidation();
            return new Promise((resolve, reject) => {
                if (v1 && v2 && v3) {
                    resolve();
                } else {
                    toast.error("enter detail in above field", { toastId: toastId });
                }
            });
        });

    };

    const formData = () => {
        let formData = null;
        if (p1.value === "completed" && p2.value === "completed" && p3.value === "completed" && p4.value === "completed" && p5.value === "completed") {
            formData = new FormData();
            const uniqueArray = [...new Set(days.value)];
            const dayData = uniqueArray.join(",");
            const time = '00:00-23:59'
            const data = {
                "template_name": templateName.value?.trim(),
                "start_date": dayjs(scheduleFrom.value).format('YYYY-MM-DD'),
                "end_date": dayjs(scheduleTo.value).format('YYYY-MM-DD'),
                "day_schedular": dayData,
                "time": time,
                "no_of_employee": employeeNo.value[2],
                "pack_id": employeeNo.value[0]
            }

            formData.append('filetxt', listTxt.value);
            formData.append('filewav', audioFile.value);
            formData.append('corpRbt', JSON.stringify(data));
        } else {
            toast.error("enter details");
        }

        return new Promise((resolve, reject) => {
            if (formData !== null) {
                resolve(formData);
            } else {
                reject("form data is null");
            }
        });
    };

    const onSubmitHandler = () => {
        lastValidation()
            .then(() => {
                return formData();
            })
            .then((data) => {
                backdroploading.value = true
                return dispatch(createCorp({ data: data, token: token, id: userId }))
            })
            .then((resp) => {
                console.log(resp)
                if (resp?.payload?.data?.httpStatusCode === 200) {
                    dispatch(getProfileData({ token: token, id: userId }))
                    dispatch(getCorpList({ token: token, id: userId }))
                    navigate('/successtemp', { state: { temptype: 'Corp-RBT', mode: 'submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewCorpRbt' } })
                } else {
                    toast.error("Internal server error")
                }
                backdroploading.value = false
            })
            .catch((err) => {
                backdroploading.value = false
                toast.error("Error while creating")
            });
    };


    const audioPlay = () => {
        const audioURL = URL.createObjectURL(audioFile.value);
        if (audioPreview.value) {
            audio.pause();
            audioPreview.value = false
        } else {
            audio = new Audio(audioURL);
            audio.play();
            audioPreview.value = true
            let audioEnded = () => {
                if (audio.ended) {
                    // audio.play();
                    audioPreview.value = false
                    clearInterval(intervalID);
                    // console.log("audio ended")
                }
            }
            let intervalID = setInterval(audioEnded, 1000)
        }
    };


    if (loading.value) {
        return <Loader />
    } else {
        return (
            <>

                <div>
                    <ProgressBar>
                        <ProgressBarStep value="1" mode={p1.value} />
                        <ProgressBarStep value="2" mode={p2.value} />
                        <ProgressBarStep value="3" mode={p3.value} />
                        <ProgressBarStep value="4" mode={p4.value} />
                        <ProgressBarStep value="5" mode={p5.value} />
                    </ProgressBar>
                </div>
                <div className='d-flex justify-content-between my-2 mx-2'>
                    <h3 className='fw-bolder'>Create Corp-RBT campaign ✨</h3>
                    <Link to='/campaign/viewCorpRbt' style={{ textDecoration: 'none' }}>
                        <CommanButton type="submit" className="btnBack mb-3" ><ArrowBackIosIcon />Back</CommanButton>
                    </Link>
                </div>


                <div className="">
                    <OuterBox value="1" able={false}>
                        <div className="my-2 w-100">
                            <label htmlFor="" className="form-label fw-semibold formLabel">
                                Name your Corp-RBT campaign. &nbsp;
                                <span className="form-text">
                                    This name identifies your Corp-RBT campaign so you can re-use it in the future. Type your template name below -
                                </span>
                            </label>
                            <div className="row g-3 justify-content-center">
                                <div className="col-auto">
                                    <input
                                        type="text"
                                        className="form-control border-secondary"
                                        id="templateName"
                                        onChange={(event) => { templateName.value = event.target.value; }}
                                        maxLength={15}
                                        placeholder="Enter Corp-RBT Name"
                                        value={templateName.value}
                                    />
                                </div>
                                <div className="col-auto d-flex flex-col">
                                    <div className="">
                                        <select id="choices" className='p-2 rounded' name="choices" onChange={(event) => { employeeNo.value = JSON.parse(event.target.value) }}>
                                            <option value={JSON.stringify(employeeNo.value)} >Select Pack</option>
                                            {
                                                packDetails.value?.map((each, index) => {
                                                    return (
                                                        <option
                                                            value={JSON.stringify([each?.id, parseFloat((each?.price)?.slice(1)), each?.no_of_employee])}
                                                            key={index} >{each?.details}-[{each?.price}] for {each?.no_of_employee}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <CommanButton type="submit" className="btnBack mb-3" onClick={templateValidation}>
                                        Submit
                                    </CommanButton>
                                </div>
                            </div>
                        </div>
                    </OuterBox>

                    <OuterBox value="2" able={p1.value === "active"}>
                        <div className="my-2 w-100">
                            <label htmlFor="" className="form-label fw-semibold formLabel">
                                Upload your list . &nbsp;
                                <span className="form-text">
                                    This list is a CSV file that contains staff phone numbers that will be programmed with the Corp-RBT. If you have more than 1 list, you must create another template for the new list.
                                </span>
                            </label>
                            <div className="row g-3 justify-content-center">
                                <div className="col-auto">
                                    <input
                                        type="file"
                                        accept=".csv"
                                        className="form-control border-secondary"
                                        id="listTxt"
                                        onChange={(event) => {
                                            listTxt.value = null
                                            if (event.target?.files[0]?.size <= 2000000) {
                                                listTxt.value = event.target?.files[0]
                                            } else {
                                                toast.error("file must be less than 2MB", { toastId: toastId });
                                            }
                                        }}
                                        disabled={p1.value === "active"}
                                    />
                                    <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}>
                                        *Extension Must be .csv file (maxsize-2mb)
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <CommanButton type="submit" className="btnBack mb-3" onClick={listTxtValidation} disabled={p1.value === "active"}>
                                        Submit
                                    </CommanButton>
                                </div>
                            </div>
                        </div>
                    </OuterBox>

                    <OuterBox value="3" able={p2.value === "active"}>
                        <div className="my-2 w-100">
                            <label htmlFor="" className="form-label fw-semibold formLabel">
                                Upload your audio file . &nbsp;
                                <span className="form-text">
                                    This is a *.wav file that represents your Corp-RBT. This is the message that will be heard when somebody calls your staff.
                                </span>
                            </label>
                            <div className="row g-3 justify-content-center">
                                <div className="col-auto">
                                    <input
                                        type="file"
                                        accept=".wav"
                                        className="form-control border-secondary"
                                        id="audioFile"
                                        onChange={onFileHandler}
                                        disabled={p2.value === "active"}
                                    />
                                    <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}>
                                        *Extension Must be .wav file (maxsize-2mb)
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <CommanButton
                                        type="submit"
                                        className="btnBack mb-3"
                                        onClick={audioFileValidation}
                                        disabled={p2.value === "active"}
                                    >
                                        Submit
                                    </CommanButton>
                                </div>
                                <div className='col-auto'>
                                    <Tooltip title="Play / Stop" >
                                        <div className="col-auto">
                                            <IconButton disabled={p2.value === "active" || audioFile.value === null} onClick={audioPlay} >
                                                {audioPreview.value ? <StopIcon /> : <PlayArrowIcon />}
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </OuterBox>

                    <OuterBox value="4" able={p3.value === "active"}>
                        <div className="my-2 w-100">
                            <label htmlFor="" className="form-label fw-semibold formLabel">
                                Schedule. &nbsp;
                                <span className="form-text">Set up days and time at which your Corp-RBT will run.</span>
                            </label>
                            <div className="row g-4 justify-content-center">

                                <div className='d-flex justify-content-center align-items-center' >
                                    <span className='align-self-center mx-4' >Date range</span>
                                    <CalendarMonthIcon fontSize='large' className='mx-2' />
                                    <span className='align-self-center mx-2' >From</span>
                                    <ReactDatePicker
                                        selected={scheduleFrom.value}
                                        dateFormat="dd-MM-YYYY"
                                        className='p-2 border-1 rounded'
                                        minDate={new Date()}
                                        onChange={(date) => (scheduleFrom.value = date)}
                                        disabled={p3.value === "active"}
                                    />

                                    <span className='align-self-center mx-2' >to</span>
                                    <ReactDatePicker
                                        selected={scheduleTo.value}
                                        dateFormat="dd-MM-YYYY"
                                        className='p-2 border-1 rounded'
                                        minDate={scheduleFrom.value}
                                        onChange={(date) => { scheduleTo.value = date }}
                                        disabled={(scheduleFrom.value === null) || (p3.value === 'active')}
                                    />

                                </div>

                                <div className='d-flex justify-content-center align-items-center' >
                                    <p className='align-self-center mx-4' >Day Range</p>
                                    <CalendarTodayIcon fontSize='large' className='mx-2' />
                                    <div
                                        className="col-auto"
                                        style={{
                                            margin: '5px 10px',
                                            minWidth: '100px',
                                            maxWidth: '150px',
                                            height: '100px',
                                            overflowY: 'scroll',
                                            padding: '4px',
                                            border: '1px solid ',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        <SelectInput type="checkbox" id="monday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Monday" title="Monday" />
                                        <br />
                                        <SelectInput type="checkbox" id="tuesday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Tuesday" title="Tuesday" />
                                        <br />
                                        <SelectInput type="checkbox" id="wednesday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Wednesday" title="Wednesday" />
                                        <br />
                                        <SelectInput type="checkbox" id="thursday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Thursday" title="Thursday" />
                                        <br />
                                        <SelectInput type="checkbox" id="friday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Friday" title="Friday" />
                                        <br />
                                        <SelectInput type="checkbox" id="saturday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Saturday" title="Saturday" />
                                        <br />
                                        <SelectInput type="checkbox" id="sunday" onChange={daysEntry} disabled={scheduleFrom.value === null || scheduleTo.value === null || p3.value === "active"} value="Sunday" title="Sunday" />
                                    </div>


                                </div>
                            </div>

                        </div>
                    </OuterBox>

                    <OuterBox value="5" able={p4.value === "active"}>
                        <div className="my-2 w-100">
                            <label htmlFor="" className="form-label fw-semibold formLabel">
                                Review. &nbsp;
                                <span className="form-text">Confirm that all the information has been filled correctly.</span>
                            </label>
                            <div className="row g-3 justify-content-center">
                                <div className="form-check col-auto">
                                    <input className="form-check-input border-secondary" type="checkbox" id="review" onChange={() => { review.value = !review.value }} disabled={p4.value === "active"} />
                                    <label className="form-check-label" htmlFor="">
                                        I ́ve reviewed all the information that will be submitted
                                    </label>
                                </div>
                            </div>
                        </div>
                    </OuterBox>

                    <div className="d-flex justify-content-center">
                        <CommanButton type="submit" className="btnBack mb-3" onClick={onSubmitHandler}>
                            Save and submit campaign
                        </CommanButton>
                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdroploading.value}
                    // onClick={}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </>
        );
    }
}

export default AddCorp;
