import React from 'react';
import OuterBox from '../../../component/OuterBox';
import ProgressBar from '../../../component/progressBar/ProgressBar';
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep';
import { toast } from 'react-toastify';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSignal } from '@preact/signals-react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { modifyPvm } from '../../../redux/slice/ListPVM';
import CommanButton from '../../../component/CommanButton';
import dayjs from 'dayjs';
import { getPvmList, modifyPvm } from '../../../redux/slice/ListPVM';



const UPCPVM = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const getData = useSignal(location.state?.data)
    const dispatch = useDispatch();
    const audioFile = useSignal(null)
    const upgrade = useSignal(null)
    const scheduleFrom = useSignal(null)
    const scheduleTo = useSignal(null)
    const p1 = useSignal('active')
    const p2 = useSignal('active')
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)
    // console.log(getData.value)

    const onSubmitHandler = () => {
        // Your data validation logic here
        let formData = null;
        let start = scheduleFrom.value
        let end = scheduleTo.value

        let data = {
            campaign_name: getData.value?.CAMPAIGN_NAME,
            startdate: `${start.$y}-${((start.$M + 1 + '').length) === 1 ? "0" + (start.$M + 1 + '') : (start.$M + 1 + '')}-${((start.$D + '').length) === 1 ? "0" + (start.$D + '') : (start.$D + '')}` + ` ${((start.$H + '').length) === 1 ? "0" + (start.$H + '') : (start.$H + '')}:${((start.$m + '').length) === 1 ? "0" + (start.$m + '') : (start.$m + '')}:00`,
            enddate: `${end.$y}-${((end.$M + 1 + '').length) === 1 ? "0" + (end.$M + 1 + '') : (end.$M + 1 + '')}-${((end.$D + '').length) === 1 ? "0" + (end.$D + '') : (end.$D + '')}` + ` ${((end.$H + '').length) === 1 ? "0" + (end.$H + '') : (end.$H + '')}:${((end.$m + '').length) === 1 ? "0" + (end.$m + '') : (end.$m + '')}:00`,
        }


        if (p1.value && p2.value === 'completed') {

            formData = new FormData();
            if (upgrade.value === "yes") {
                formData.append('uploadList', audioFile.value);
            } else if (upgrade.value === "no") {
                formData.append('uploadList', null);
            }
            formData.append('saveCampaign', JSON.stringify(data))

            let send = {
                data: formData,
                id: getData.value?.CAMPAIGN_ID,
                token: token
            }

            dispatch(modifyPvm(send))
                .then((resp) => {
                    if (resp?.payload?.data?.httpStatusCode === 200) {
                        dispatch(getPvmList({ token: token, id: userId }))
                        navigate('/successtemp', { state: { temptype: 'Voice Campaign', mode: 're-submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewpvm' } })
                    } else {
                        toast.error('Internal server error')
                    }
                }).catch((err) => {
                    toast.error('Error while modify')
                })
        }

    };

    return (
        <>
            <ProgressBar>
                <ProgressBarStep value="1" mode={p1.value} />
                <ProgressBarStep value="2" mode={p2.value} />
            </ProgressBar>

            <div className='container-fluid m-2 '>
                <div className="d-flex justify-content-between my-2 mx-4">
                    <div className='mb-4 border border-secondary p-3 rounded w-75 ' style={{ border: '1px solid', }}>
                        <div className='fw-bold fs-5'>Service : PVM Voice Campaign</div>
                        <div className='fw-bold fs-5'>Selected Campaign : {getData.value?.CAMPAIGN_NAME}</div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/campaign/viewpvm">
                            <CommanButton ><ArrowBackIosIcon />Back</CommanButton>
                        </Link>
                    </div>
                </div>

                <OuterBox value="1" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="fw-semibold formLabel ">Update your employee list.</label>
                        <span className="form-text"></span>
                        <div className='d-flex flex-column align-items-center'>
                            <div>
                                <div className='form-check d-flex align-items-center '>
                                    <input className="form-check-input mx-2 border-secondary my-2" type="radio" name="upgrade" id="upgrade" value="no" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'completed' }} />
                                    <label className="form-check-label mx-2" htmlFor="">
                                        I will use the exact same list
                                    </label>
                                </div>
                                <div className='d-flex form-check flex-wrap  mt-3'>
                                    <input className="form-check-input mx-2 border-secondary" type="radio" name="upgrade" id="upgrade" value="yes" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'active' }} />
                                    <label className="form-check-label mx-2" >
                                        I want to update my list
                                    </label>

                                    {/* <button type="submit" className="btn btnBack mx-2" disabled={upgrade.value === 'no' ? true : false} >Submit</button> */}
                                </div>

                            </div>
                            {
                                upgrade.value === 'yes' &&
                                <div className='my-4 d-flex '>
                                    <div>
                                        <input type="file" accept='.txt' className="form-control border-secondary" id="audioFile" onChange={(e) => { (e.target.files[0].size < 2000000) ? audioFile.value = e.target.files[0] : toast.error("file size") }} disabled={upgrade.value === 'no' ? true : false} />
                                        <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .txt file(maxsize-2mb)</label>
                                    </div>
                                    <div className='mx-2'>
                                        <CommanButton type='submit' text='Submit' disabled={upgrade.value === 'no' ? true : false} onClick={() => { audioFile.value === null ? toast.error('Please select audio file') : p1.value = 'completed' }} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="2" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel ">Re-schedule.
                            <span className="form-text">Set up days and time at which your RBT will run</span>
                        </label>
                        <div className="d-flex justify-content-center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker label="From" className='mx-2' value={scheduleFrom.value} onChange={(e) => { scheduleFrom.value = e; p2.value = 'active' }} minDate={dayjs(new Date())} minTime={dayjs('0000-00-00T07:00')} maxTime={dayjs('0000-00-00T19:00')} ampm={false}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock
                                        }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker label="To" className='mx-2' value={scheduleTo.value} onChange={(e) => { scheduleTo.value = e; p2.value = 'completed' }} disabled={scheduleFrom.value === null} minDate={scheduleFrom.value} minTime={dayjs('0000-00-00T07:00')} maxTime={dayjs('0000-00-00T19:00')} ampm={false}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock
                                        }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>
                </OuterBox>
            </div>

            <div className="d-flex justify-content-center my-4">
                <CommanButton text="SEND TEMPLATE" onClick={onSubmitHandler} />

            </div>
        </>
    );
};

export default UPCPVM;
