import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false,
    isModify: false,
};

// Async thunk for fetching the PVM list
export const getPvmList = createAsyncThunk("getPvmList", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getAllCampaign/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching campaign by ID
export const getCampaignById = createAsyncThunk("getCampaignById", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getCampaignById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for creating a PVM campaign
export const createPvm = createAsyncThunk("createPvm", async ({ token, data }) => {
    try {
        const response = await axiosInstance.post("/saveCampaign", data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

export const getProfileDataById = createAsyncThunk("getProfileDataById", async ({ id, token }) => {
    try {
        const response = await axiosInstance.get(`/getVendorById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return response.data; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});

export const  SendCampaningMail = createAsyncThunk(
    'mail/SendCampaningMail',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post('/sendCampaningMail', data.data, {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data from the response
      } catch (error) {
        // Handle specific status codes if needed
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred'); // Use rejectWithValue to handle errors in the slice
      }
    }
  );
  

  export const  GetServiceCampaignMailling = createAsyncThunk(
    'mail/GetServiceCampaignMailling',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(`/getServiceCampaignMailling/${data.id}`,  {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data from the response
      } catch (error) {
        // Handle specific status codes if needed
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred'); // Use rejectWithValue to handle errors in the slice
      }
    }
  );
  



// Async thunk for modifying a PVM campaign
export const modifyPvm = createAsyncThunk("modifyPvm", async ({ token, id, data }) => {
    try {
        const response = await axiosInstance.put(`/updateCampaign/${id}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for deleting a PVM campaign
export const deletePvm = createAsyncThunk("deletePvm", async ({ token, id }) => {
    try {
        const response = await axiosInstance.delete(`/deleteCampaignById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching campaign ID names
export const getCampaignByIdNames = createAsyncThunk("getCampaignByIdNames", async (token) => {
    try {
        const response = await axiosInstance.get("/getCampaignByIdNames", {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching PVM pack details
export const getPVMPackDetails = createAsyncThunk("getPVMPackDetails", async (token) => {
    try {
        const response = await axiosInstance.get("/getPvmPackDetails", {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// PVM slice
export const ListPVM = createSlice({
    name: 'pvmList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPvmList.fulfilled, (state, action) => {
                state.data = action.payload?.body;
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getPvmList.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getPvmList.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            })
    },
});

export default ListPVM.reducer;
