import React, { Component } from 'react'
import './ProgressBar.css'

export class ProgressBarStep extends Component {
    render() {
        return (
            <div className={`stepper-item  ${this.props.mode}`}>
                <div className="step-counter">{this.props.value}</div>
                {/* <div class="step-name">{this.props.name}</div> */}
            </div>
        )
    }
}

export default ProgressBarStep