import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import Router from './Router';
import { useSignal } from '@preact/signals-react';
// import LinearProgressCountUp from './component/loader/LinearProgressCountUp';
import Loader from './component/loader/Loader';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function App() {
  const loading = useSignal(false);

  useEffect(() => {
    const handleLoad = () => {
      // Once the page is fully loaded, set loaded to true
      setTimeout(() => {
        loading.value = false
      }, 1000);
    };

    window.addEventListener('load', handleLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: '#7a85e6',
        main: '#5a67e0',
        dark: '#4852b3',
        contrastText: '#ffffff'
      },
      // secondary: blue,
      tableHeader: '#b4e0ff',
      header:'#ffffff',
      chip:'#80c2ee'
    },
    // components: {
    //   MuiTableHead: {
    //     styleOverrides: {
    //       root: {
    //         '& .MuiTableCell-head': {
    //           fontWeight: '600',
    //           fontSize: '1em',
    //           // margin: 6,
    //           padding: 6,
    //           mx:16,
    //           my:6
    //         },
    //         border: 4
    //       },
    //     }
    //   },
    //   MuiTableBody: {
    //     styleOverrides: {
    //       root: {
    //         '& .MuiTableRow-root': {
    //           '&:hover': {
    //             backgroundColor: '#eeeeee',
    //           }
    //         },
    //         '& .MuiTableCell-root': {
    //           paddingX:16,
    //           mx:16
    //         }
    //       }
    //     }
    //   }
    // },
    typography: {
      // htmlFontSize: 18,
      fontFamily: "Inter, sans-serif"
    }
  });




  if (loading.value) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader />
      </div>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <RouterProvider router={Router()} />
        <ToastContainer theme='colored' autoClose='1500' transition={Flip} closeOnClick  pauseOnHover />
      </ThemeProvider>
    )
  }
}
