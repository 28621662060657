import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: {},
    isLoading: true,
    error: false
};

// Async thunk for fetching profile data
export const getProfileData = createAsyncThunk("getProfileData", async ({ id, token }) => {
    try {
        const response = await axiosInstance.get(`/getVendorById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return response.data; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});

// Slice for profile data
export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getProfileData.fulfilled, (state, action) => {
                state.data = action.payload.body?.[0] || {}; // Adjust based on actual response structure
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getProfileData.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getProfileData.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default ProfileSlice.reducer;
