import axios from "axios";
import { toast } from "react-toastify";
import { getNavigate } from "../../component/sidebar/Playground";



export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {

    if (error.message === "Network Error") {
      const navigate = getNavigate();
      if (navigate) {
        navigate('/Notfound');
        setTimeout(() => {
          sessionStorage.clear()
          navigate('/login')
        }, 5000);
      }
    }

    if (error.response.status === 401) {
      const navigate = getNavigate();
      if (navigate) {
        navigate('/login');
        sessionStorage.clear()
        setTimeout(() => {
          toast.dismiss()
          toast.error('Session Expired !')
        }, 100);
      }
    }
    
    return Promise.reject(error);
  }
);




