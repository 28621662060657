import React from 'react';
import { TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useSignal } from '@preact/signals-react';
import BackDropLoader from './../../../component/loader/BackDropLoader'


function CorpReportList(props) {
    const token = useSelector(state => state.token?.data?.token)
    const loading = useSignal(false)

    const handleDownload = async (filePath) => {
        if (filePath) {
            const parts = filePath.split('/');

            let fileName = parts[parts.length - 1]
            try {
                loading.value = true
                const response = await fetch(`${process.env.REACT_APP_API_URL}/SystemConfig/download`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({ filename: filePath })
                });

                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                loading.value = false
            } catch (error) {
                toast.error('Error while downloading')
                console.error('Error downloading file:', error);
                loading.value = false
            }
        } else {
            toast.error('There is no file available')
        }

    };


    return (
        <>
            <TableBody className=''>
                <TableRow key={props.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className='reportsList' >

                    <TableCell align="center" style={{ fontSize: '12px', height: '4em' }}>{props.data.reportDate.slice(0, 10)}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{props.data.newSubscriber}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.unsubscription}</TableCell>

                    <TableCell component="th" scope="row" align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.totalSubscriber} </TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.totalIncomingCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.internationalCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.localCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.data.timeOfExposure}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        <Tooltip title="Download per day csv file" >
                            <button onClick={() => { handleDownload(props.data.default_file_path) }} style={{ border: 'none', textDecoration: 'underLine', color: '#6366f1', fontWeight: '700', backgroundColor: 'transparent' }}>
                                <DownloadIcon />
                            </button>
                        </Tooltip>
                        <BackDropLoader opener={loading.value} />
                    </TableCell> 
                </TableRow>
            </TableBody>
        </>
    );

}

export default CorpReportList;