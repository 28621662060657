import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false
};

// Async thunk for fetching PVM summary reports
export const getPvmSummaryReports = createAsyncThunk("getPvmSummaryReports", async (data) => {
    try {
        const response = await axiosInstance.get(`/SystemConfig/getPVMSummaryReportsByID/${data.id}`, {
            headers: {
                "Authorization": `Bearer ${data.token}`
            },
        });
        return response; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});



// Slice for PVM summary reports
export const PvmSummaryReports = createSlice({
    name: 'pvmSummaryReports',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPvmSummaryReports.fulfilled, (state, action) => {
                state.data = action.payload?.data?.body || null; // Adjust based on actual response structure
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getPvmSummaryReports.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getPvmSummaryReports.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default PvmSummaryReports.reducer;



export const getMsisdnDetailsByCampaignId = createAsyncThunk("getMsisdnDetailsByCampaignId", async (data) => {
    try {
        const response = await axiosInstance.get(`/getMsisdnDetailsByCampaignId/${data.campId}/${data.date}`, {
            headers: {
                "Authorization": `Bearer ${data.token}`
            },
        });
        return response; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});
