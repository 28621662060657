import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AWS from 'aws-sdk'
import { Link, Redirect, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Backdrop, CircularProgress, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useSignal } from '@preact/signals-react';
import CommanButton from '../../../component/CommanButton';
import ProgressBar from '../../../component/progressBar/ProgressBar';
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep';
import OuterBox from '../../../component/OuterBox';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { getVoices } from '../../../redux/slice/AWSvoices';
import { getLbsTemplates } from '../../../redux/slice/LbsTemplates';
import { getCountryCode } from '../../../redux/slice/CountryCode';
import { getOriginationNumber } from '../../../redux/slice/OriginationNumber';
import { createPvm, getCampaignByIdNames, getPvmList, getPVMPackDetails, GetServiceCampaignMailling, SendCampaningMail } from '../../../redux/slice/ListPVM';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SelectInput from '../../../component/SelectInput';
import Loader from '../../../component/loader/Loader'
import { RequestService } from '../../../redux/slice/ServiceManager';

let audio = null;
const toastId = 'createPVM'
const AddPvm = () => {


    const templateName = useSignal('');
    const callNo = useSignal(0);
    const personalized = useSignal(null);
    const messageRadio = useSignal('');
    const audioMessage = useSignal(null);
    const welcomeFile = useSignal(null);
    const menuFile = useSignal(null);
    const thanksFile = useSignal(null);
    const ttsTextArea = useSignal('');
    const ttsVoiceTone = useSignal('');
    const ttsVoiceSpeed = useSignal('');
    const textData = useSignal('');
    const originNo = useSignal('');
    const country = useSignal('');
    const listTxt = useSignal(null);
    const scheduleFrom = useSignal(null);
    const scheduleTo = useSignal(null);
    const startTime = useSignal(null);
    const lastTime = useSignal(null);
    const days = useSignal('')
    const CTA = useSignal('-1');
    const review = useSignal(false);
    const p1 = useSignal('active');
    const p2 = useSignal('active');
    const p3 = useSignal('active');
    const p4 = useSignal('active');
    const p5 = useSignal('active');
    const p6 = useSignal('active');
    const p7 = useSignal('active');
    const p8 = useSignal('active');
    const Url = useSignal('');
    const redirectStatus = useSignal('');
    const getTemplates = useSignal([]);
    const modal = useSignal(false);
    const whichMessage = useSignal('write');
    const writeMessage = useSignal('');
    const existMessage = useSignal('');
    const whichAudio = useSignal('');
    const response = useSignal([]);
    const availblnc = useSignal(0);
    const audioPreview = useSignal(false);
    const welcomePreview = useSignal(false);
    const menuPreview = useSignal(false);
    const thanksPreview = useSignal(false);
    const existTemplateName = useSignal('');
    const countryCodes = useSignal([]);
    const originationNumber = useSignal([]);
    // const packDetailsList = useSignal([]);
    const getVoice = useSignal([]);
    const loading = useSignal(false)
    const LoaderDate = useSignal(false)
    const selectPack = useSignal(-1)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // let userId = useSelector(state => state.profile.data?.useridd)
    let userName = useSelector(state => state.profile.data?.username)
    const token = useSelector(state => state.token?.data?.token)
    const getProfileData = useSelector(state => state.profile.data)
    const awsVoice = useSelector(state => state.awsVoices.data)
    const LbsTemplates = useSelector(state => state.lbsTemplates.data)
    const CountryCode = useSelector(state => state.countryCode.data)
    const OriginNo = useSelector(state => state.originationNumber.data)
    const userId = useSelector(state => state.vendor?.data?.user_id)
    const packId = useSelector(state => state.profile?.data?.packId)
    const requestData = useSignal({})

    // const packDetails = () => {
    //     loading.value = true
    //     dispatch(getPVMPackDetails(token))
    //         .then((response) => {
    //             if (response?.payload?.data?.httpStatusCode === 200) {
    //                 packDetailsList.value = response?.payload?.data?.body
    //             } else {
    //                 toast.error('Internal server error', { toastId: toastId })
    //             }
    //             loading.value = false
    //         }).catch((err) => {
    //             toast.error('Error while getting pack list', { toastId: toastId })
    //             loading.value = false
    //         })
    // }


    let getdata = () => {
        LoaderDate.value = true
        dispatch(getVoices(token))
            .then(resp => {
                return dispatch(getLbsTemplates(token))
            })
            .then(resp => {
                return dispatch(getCountryCode(token))
            })
            .then(resp => {
                return dispatch(getOriginationNumber(token))
            })
            .then(resp => {
                return dispatch(GetServiceCampaignMailling({ token: token, id: 2 }))
            }).then((resp) => {
                if (resp?.payload?.status === 200) {
                    // console.log(resp?.payload?.data)
                    requestData.value = resp?.payload?.data?.body[0]
                    LoaderDate.value = false
                }

            })

        // packDetails()
    }



    useEffect(() => {
        getdata()
    }, [])

    useEffect(() => {
        availblnc.value = getProfileData?.availbalance
        getTemplates.value = LbsTemplates
        countryCodes.value = CountryCode
        getVoice.value = awsVoice
        originationNumber.value = OriginNo
    }, [getProfileData, awsVoice, LbsTemplates, CountryCode, OriginNo])


    const onWhatMessage = () => {
        let type = whichMessage.value

        if (type === "write") {
            if (writeMessage.value?.length !== 0) {
                ttsTextArea.value = writeMessage.value
                modal.value = false
            } else {
                toast.error("Message cannot be empty")
            }
        } else if (type === "exist") {
            if (existMessage.value?.length !== 0) {
                ttsTextArea.value = existMessage.value
                modal.value = false
            } else {
                toast.error("Please select your template")
            }
        }
    }

    const onReviewChange = () => {
        review.value = !review.value
        reviewValidation()
    }


    // const onFileHandler =

    const templateNameValidation = async () => {
        if (templateName.value?.length === 0) {
            p1.value = "active"
            toast.error("Please enter your template name", { toastId: toastId })
        } else if (callNo.value.length > 7) {
            p1.value = "active"
            toast.error(`keep short calls allowed`, { toastId: toastId })
        } else if (parseFloat(callNo.value) <= 0) {
            p1.value = "active"
            toast.error(`You have ${availblnc.value} maximum calls limit`, { toastId: toastId })
        } else if (parseFloat(callNo.value) > parseFloat(availblnc.value)) {
            p1.value = "active"
            toast.error(`Credit Limit is not enough. You have ${availblnc.value} maximum calls limit`, { toastId: toastId })
        } else {
            loading.value = true
            try {
                const resp = await dispatch(getCampaignByIdNames(token));
                console.log(resp)
                if (resp?.payload?.data?.httpStatusCode === 200) {
                    const foundString = resp?.payload?.data?.body?.find(element => element.toLowerCase() === templateName.value?.toLowerCase().trim());

                    if (foundString !== undefined) {
                        toast.error("Template name already exists", { toastId: toastId });
                        p1.value = 'active';
                    } else {
                        p1.value = 'completed';

                    }
                    loading.value = false
                }
            } catch (error) {
                p1.value = 'active';
                // backdroploading.value = false;
                toast.error("Error while checking template name", { toastId: toastId });
                // return false;
            }
        }
    }


    if ((CTA.value === '-1')) {
        p2.value = 'active'
    } else {
        p2.value = 'completed'
    }

    if (personalized.value === null) {
        p3.value = 'active'
    } else {
        p3.value = 'completed'
    }



    const messageTypeValidation = async() => {

        let updatedMsg = ttsTextArea.value;
        let count = 0;

        // Iterate over each placeholder pattern $(varX) and replace with varArray values
        await updatedMsg.replace(/\$\(([^)]+)\)/g, (match) => {
            count++;
        });

        if (messageRadio.value === "text" && personalized.value === "Yes" && count === 0) {
            toast.error("Personalized On then Select Dynamic message ")
        } else if (messageRadio.value === "text" && personalized.value === "No" && count > 0) {
            toast.error("Personalized Off then Select Non Dynamic message ")
        } else if ((messageRadio.value === 'audio') && (audioMessage.value === null) && (whichAudio.value === 'Single')) {
            p4.value = "active"
            toast.error("Please enter audio file message ")
        }
        else if ((messageRadio.value === 'audio') && (whichAudio.value === 'Multiple') && (welcomeFile.value === null)) {
            p4.value = "active"
            toast.error("Please enter welcome file message ")

        }
        else if ((messageRadio.value === 'audio') && (whichAudio.value === 'Multiple') && (menuFile.value === null)) {
            p4.value = "active"
            toast.error("Please enter menu file message ")

        }
        else if ((messageRadio.value === 'audio') && (whichAudio.value === 'Multiple') && (thanksFile.value === null)) {
            p4.value = "active"
            toast.error("Please enter thanks file message ")

        }
        else if ((messageRadio.value === 'text') && (ttsTextArea.value.length === 0)) {
            p4.value = "active"
            toast.error("Please enter text field file message ")
        }
        else if ((messageRadio.value === 'text') && (ttsVoiceTone.value.length === 0)) {
            p4.value = "active"
            toast.error("Please enter voice tone file message ")
        }
        else if ((messageRadio.value === 'text') && (ttsVoiceSpeed.value.length === 0)) {
            p4.value = "active"
            toast.error("Please enter voice speed file message ")
        }
        else if ((messageRadio.value.length === 0)) {
            p4.value = "active"
            toast.error("Please select message type ")
        }
        else {
            p4.value = "completed"
        }
    }

    const originNoValidation = () => {
        if (originNo.value.length !== 0) {
            p5.value = "completed"
        } else {
            p5.value = "active"
            toast.error("Please enter your origin number")
        }
    }

    const listTxtValidation = () => {
        if ((listTxt.value === null)) {
            p6.value = "active"
            toast.error("Please upload your list file")
        } else if (country.value?.length === 0) {
            p6.value = "active"
            toast.error("Please select the country code")
        }
        else {
            p6.value = "completed"
        }
    }

    if ((scheduleFrom.value !== null) && (scheduleTo.value !== null)) {
        p7.value = "completed"
    } else {
        p7.value = "active"
    }


    const reviewValidation = () => {
        if (review.value === true) {
            p8.value = "completed"
        } else {
            p8.value = "active"
        }
    }

    const getFormData = async () => {

        let errorMessage = "";

        let updatedMsg = ttsTextArea.value;
        let count = 0;

        // Iterate over each placeholder pattern $(varX) and replace with varArray values
        await updatedMsg.replace(/\$\(([^)]+)\)/g, (match) => {
            count++;
        });



        let formData = null;
        if (messageRadio.value === "text" && personalized.value === "Yes" && count === 0) {
            errorMessage = "Personalized On then Select Dynamic message ";
        } else if (messageRadio.value === "text" && personalized.value === "No" && count > 0) {
            errorMessage = "Personalized Off then Select Non Dynamic message ";
        } else if (p1.value && p2.value && p3.value && p4.value && p5.value && p6.value && p7.value && p8.value === 'completed') {
            let dayType = ''

            if (days.value.length === 7) {
                dayType = "8"
            } else {
                dayType = days.value
            }



            if ((messageRadio.value === "audio") && (whichAudio.value === 'Single')) {
                formData = new FormData();
                let data = {
                    "campaign_name": templateName.value,
                    "startdate": `${dayjs(scheduleFrom.value).format('YYYY-MM-DD')} ${dayjs(startTime.value).format('HH:mm')}:00`,
                    "persanolized": "Off",
                    "enddate": `${dayjs(scheduleTo.value).format('YYYY-MM-DD')} ${dayjs(lastTime.value).format('HH:mm')}:00`,
                    "startHour": `${dayjs(startTime.value).format('HHmm')}`,
                    "endHour": `${dayjs(lastTime.value).format('HHmm')}`,
                    "userId": userId,
                    "created_by": userName,
                    "admin_msisdn": "7070270313",
                    "promptType": whichAudio.value,
                    "interfacee": "O",
                    "sms_origination_num": originNo.value,
                    "obd_app": "-1",
                    "status": "N",
                    "balance": "5000",
                    "priority": "1",
                    "day_of_week": dayType,
                    "camp_recycle": "1",
                    "policy_id": "-99",
                    "countryCode": country,
                    "interaction": CTA.value,
                    "packID": packId
                }


                formData.append('audioFile', audioMessage.value);
                formData.append('uploadList', listTxt.value);
                formData.append('saveCampaign', JSON.stringify(data))

            }
            else if ((messageRadio.value === "audio") && (whichAudio.value === 'Multiple')) {
                formData = new FormData();
                let data = {
                    "campaign_name": templateName.value,
                    "startdate": `${dayjs(scheduleFrom.value).format('YYYY-MM-DD')} ${dayjs(startTime.value).format('HH:mm')}:00`,
                    "persanolized": "Off",
                    "enddate": `${dayjs(scheduleTo.value).format('YYYY-MM-DD')} ${dayjs(lastTime.value).format('HH:mm')}:00`,
                    "startHour": `${dayjs(startTime.value).format('HHmm')}`,
                    "endHour": `${dayjs(lastTime.value).format('HHmm')}`,
                    "userId": userId,
                    "created_by": userName,
                    "admin_msisdn": "7070270313",
                    "promptType": whichAudio.value,
                    "interfacee": "O",
                    "sms_origination_num": originNo.value,
                    "obd_app": "-1",
                    "status": "N",
                    "balance": "5000",
                    "priority": "1",
                    "day_of_week": dayType,
                    "camp_recycle": "1",
                    "policy_id": "-99",
                    "countryCode": country,
                    "interaction": CTA.value,
                    "packID": packId
                }


                formData.append('welcomeFile', welcomeFile.value);
                formData.append('menuFile', menuFile.value);
                formData.append('thanksFile', thanksFile.value);
                formData.append('uploadList', listTxt.value);
                formData.append('saveCampaign', JSON.stringify(data))

            }
            else if (messageRadio.value === "text") {
                formData = new FormData();
                let data = {
                    "campaign_name": templateName.value,
                    "startdate": `${dayjs(scheduleFrom.value).format('YYYY-MM-DD')} ${dayjs(startTime.value).format('HH:mm')}:00`,
                    "persanolized": personalized.value === "Yes" ? "On" : "Off",
                    "enddate": `${dayjs(scheduleTo.value).format('YYYY-MM-DD')} ${dayjs(lastTime.value).format('HH:mm')}:00`,
                    "startHour": `${dayjs(startTime.value).format('HHmm')}`,
                    "endHour": `${dayjs(lastTime.value).format('HHmm')}`,
                    "userId": userId,
                    "campaign_message": ttsTextArea.value,
                    "voiceTone": ttsVoiceTone.value,
                    "voiceSpeed": ttsVoiceSpeed.value,
                    "created_by": userName,
                    "admin_msisdn": "7070270313",
                    "promptType": "none",
                    "interfacee": "O",
                    "sms_origination_num": originNo.value,
                    "obd_app": "-1",
                    "status": "N",
                    "balance": "5000",
                    "priority": "1",
                    "day_of_week": dayType,
                    "camp_recycle": "1",
                    "policy_id": "-99",
                    "countryCode": country,
                    "interaction": CTA.value,
                    "packID": packId
                }


                formData.append('audioFile', null);
                formData.append('uploadList', listTxt.value)
                formData.append('saveCampaign', JSON.stringify(data))

            }
            else {
                alert("Please enter your message type")
            }

        } else {
            errorMessage = "enter all details"
        }

        return new Promise((resolve, reject) => {
            if (errorMessage.length === 0) {
                resolve(formData)
            }
            else {
                reject(errorMessage)
            }
        })


    }


    const onSubmitHandler = async () => {
        loading.value = true;
        getFormData()
            .then((data) => {
                return dispatch(createPvm({ data: data, token: token }))
            })
            .then((resp) => {
                console.log(resp)
                redirectStatus.value = resp?.payload?.data?.httpStatusCode
                response.value = resp?.payload?.data
                if (resp?.payload?.data?.httpStatusCode === 200) {
                    if (resp?.payload?.data?.message === 'Campaign created Sucessfully') {
                        console.log(resp?.payload?.data)
                        dispatch(getPvmList({ token: token, id: userId }))
                        let username = getProfileData?.username

                        let recipientMail = getProfileData?.email;

                        let salesemail = getProfileData?.salesemail 

                        let sendMailData = {
                            "recipient": recipientMail,
                            "msgBody": `Dear ${username},\n\n ${requestData.value?.serviceDescription} ${resp?.payload?.data?.body[0]?.campaign_ID} , ${resp?.payload?.data?.body[0]?.campaign_name} `,
                            "subject": requestData.value?.serviceSubject,
                            "date": dayjs().format('YYYY-MM-DD HH:mm:ss'),
                            "salesemail":salesemail
                        };
                        return dispatch(SendCampaningMail({ 'data': sendMailData, "token": token }))
                        // navigate('/successtemp', { state: { temptype: 'Voice campaign', mode: 'submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewpvm' } })
                    } else {
                        toast.error(resp?.payload?.data?.message)
                    }
                } else {
                    toast.error('Internal server error')
                }

            })
            .then((resp) => {
                console.log(resp)
                if (resp?.payload?.status === 200) {
                    // requestData.value = resp?.payload?.data?.body
                    navigate('/successtemp', { state: { temptype: 'Voice campaign', mode: 'submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewpvm' } })
                }
                loading.value = false

            })
            .catch((err) => {
                loading.value = false
                toast.error(err)
            })
    }


    const handleGenerateSpeech = async () => {
        if (ttsTextArea.value.length === 0) {
            toast.error('Select Any Template')
        } else if (ttsVoiceTone.value.length === 0) {
            toast.error('Select Voice Tone')
        } else if (ttsVoiceSpeed.value.length === 0) {
            toast.error('Select Voice Speed')
        } else {
            let speechText = '';
            if (personalized.value || (textData.value.length !== 0)) {
                let varString = textData.value;
                let msgTemplate = ttsTextArea.value;
                let varArray = varString.split(",");
                // let msgArray = msgTemplate.split(" ");

                let updatedMsg = msgTemplate;
                let count = 0;

                // Iterate over each placeholder pattern $(varX) and replace with varArray values
                updatedMsg = updatedMsg.replace(/\$\(([^)]+)\)/g, (match) => {
                    return varArray[count++];
                });
                console.log(updatedMsg)



                // return updatedMsg;
                // let count = 1;
                // let updateMsg = "";
                // for (let a in msgArray) {
                //     if (msgArray[a] === "$(var" + count + ")" || msgArray[a] === "$(var" + count + ").") {
                //         msgArray[a] = varArray[count - 1];
                //         count++;
                //     }
                //     updateMsg = updateMsg + msgArray[a] + " ";
                // }
                speechText = updatedMsg;
            } else {
                speechText = ttsTextArea.value;
            }

            let playSpeed = ttsVoiceSpeed.value;
            AWS.config.region = 'us-east-1';
            AWS.config.engine = "neural";

            let speechParams = {
                OutputFormat: "mp3",
                SampleRate: '16000',
                Text: "",
                TextType: "ssml",
                VoiceId: ttsVoiceTone.value
            };

            let tag;
            if (playSpeed === "slow") {
                tag = '<speak><prosody rate="slow">';
            } else if (playSpeed === "fast") {
                tag = '<speak><prosody rate="fast">';
            } else {
                tag = '<speak><prosody>';
            }
            let message = tag + " " + speechText + "</prosody></speak>";
            speechParams.Text = message;

            // Create Polly service object and presigner
            let polly = new AWS.Polly({ apiVersion: '2016-06-10' });
            let signer = new AWS.Polly.Presigner(speechParams, polly);

            try {
                signer.getSynthesizeSpeechUrl(speechParams, (error, url) => {
                    if (error) {
                        console.error('Polly error:', error);
                    } else {
                        console.log('Generated URL:', url);
                        var audio = new Audio(url);
                        audio.addEventListener('error', (e) => {
                            console.error('Audio playback error:', e);
                        });
                        audio.play().catch((e) => {
                            console.error('Playback failed:', e);
                        });
                    }
                });
            } catch (error) {
                console.error('Error generating speech:', error);
            }
        }
    };



    const audioPlay = (file, doing) => {
        // console.log(file, doing);

        const audioURL = URL.createObjectURL(file);
        const stateProperty = `${doing}`;

        if (this.state[stateProperty]) {
            audio.pause()
            this.setState({
                [stateProperty]: false
            })
        } else {
            audio = new Audio(audioURL)
            audio.play()
            this.setState({
                [stateProperty]: true
            })
        }

    }

    const daysEntry = (event) => {
        const { value, checked } = event.target;
        // let { days } = this.state;

        let newDays = '';
        let inserted = false;
        let removed = false;

        for (let i = 0; i < days.value.length; i++) {
            const currentValue = days.value[i];
            if (parseInt(currentValue) > parseInt(value) && !inserted && checked) {
                newDays += value;
                inserted = true;
            }
            if (parseInt(currentValue) !== parseInt(value)) {
                newDays += currentValue;
            } else {
                removed = true; // Mark as removed
            }
        }

        // If value wasn't inserted and it wasn't removed (if it existed), add it to the end
        if (!inserted && !removed && checked && !newDays.includes(value)) {
            newDays += value;
        }

        days.value = newDays;
    };


    let weekDayDisable = ((scheduleFrom.value === null ? true : false) || (p6.value === 'active' ? true : false) || (scheduleTo.value === null ? true : false) || (startTime.value === null ? true : false) || (lastTime.value === null ? true : false))

    if (LoaderDate.value) {
        return <Loader />
    }
    return (
        <div className='mx-3'>
            <ProgressBar>
                <ProgressBarStep value="1" mode={p1.value} />
                <ProgressBarStep value="2" mode={p2.value} />
                <ProgressBarStep value="3" mode={p3.value} />
                <ProgressBarStep value="4" mode={p4.value} />
                <ProgressBarStep value="5" mode={p5.value} />
                <ProgressBarStep value="6" mode={p6.value} />
                <ProgressBarStep value="7" mode={p7.value} />
                <ProgressBarStep value="8" mode={p8.value} />
            </ProgressBar>



            <div className='d-flex justify-content-between my-2 mx-2'>
                <h3 className='fw-bolder'>Create PVM Campaign ✨</h3>
                <Link
                    to='/campaign/viewpvm'
                    style={{ textDecoration: 'none' }}>
                    <CommanButton type="submit" className="btnBack mb-3" ><ArrowBackIosIcon />Back</CommanButton>
                </Link>
            </div>


            <div className='my-2' >

                <OuterBox value="1" able='false' >
                    <div className="my-2 w-100" >
                        <label htmlFor="templateName" className="form-label fw-semibold formLabel">Name your PVM campaign. &nbsp;
                            <span className="form-text">This name identifies your campaign so you can re-use it in future. Type your campaign name below - </span></label>
                        <div className="row g-3 justify-content-center">

                            <div className="col-auto">
                                <input type="text" className="form-control border-secondary" id="templateName" maxLength={15} value={templateName.value} onChange={(e) => { templateName.value = e.target.value }} placeholder='Enter PVM Campaign Name' />
                            </div>
                            <div className="col-auto d-flex ">
                                <div className=''>
                                    <Tooltip title={<h6> Per Call 1$ </h6>} style={{ fontSize: '15px' }} >
                                        <input type="number" className="form-control border-secondary" id="callNo" onChange={(e) => { callNo.value = e.target.value }} placeholder='No. of calls allowed' />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-auto">
                                <CommanButton type="submit" className="btnBack mb-3" onClick={templateNameValidation}>Submit</CommanButton>
                            </div>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="2" able={p1.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Customer Interaction. &nbsp;
                            <span className="form-text">By activating this feature, you
                                are expecting a call-to-action from the subscriber.
                                Be sure to make the indication of this call-to-action
                                clear in your message.</span></label>
                        <div className="d-flex justify-content-center">
                            <ToggleButtonGroup
                                size='small'
                                value={CTA.value}
                                exclusive
                                onChange={(e, v) => { CTA.value = v }}
                                disabled={p1.value === 'active' ? true : false}
                                sx={{
                                    boxShadow: 1,
                                    borderRadius: 2,
                                }}
                            >
                                <ToggleButton value="1" aria-label="1" >
                                    <CheckIcon />
                                </ToggleButton>
                                <ToggleButton value="0" aria-label="0" >
                                    <ClearIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>

                            {/*  <div className="col-auto d-flex flex-col mx-2">
                                <div className="">
                                    <select id="choices" className='p-2 rounded' name="choices" disabled={CTA.value === '-1'} value={selectPack.value} onChange={(e)=>{selectPack.value = parseInt(e.target.value)}} >
                                        <option >Select Pack</option>
                                        {
                                            packDetailsList.value?.map((each, index) => {
                                                return (
                                                    <option
                                                        value={each.id}
                                                        key={index} >{each?.details}- [{CTA.value === '0' ? each.one_way_fees : each.two_way_fees}] for {each?.no_of_employee} </option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="3" able={p2.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Personalization.&nbsp;
                            <span className="form-text">This feature allows you to personalize each message. Make certain that you include variables in your list in step 6. </span></label>
                        <div className="d-flex justify-content-center">
                            <ToggleButtonGroup
                                size='small'
                                value={personalized.value}
                                exclusive
                                onChange={(e, v) => { personalized.value = v; console.log(v) }}
                                disabled={p2.value === 'active' ? true : false}
                                sx={{
                                    boxShadow: 1,
                                    borderRadius: 2,
                                }}
                            >
                                <ToggleButton value="Yes" aria-label="Yes" >
                                    <CheckIcon />
                                </ToggleButton>
                                <ToggleButton value="No" aria-label="No" >
                                    <ClearIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="4" able={p3.value === 'active' ? true : false}>
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Select type of message. &nbsp;
                            <span className="form-text">Please tell us if your message will be personalized per number. If this feature is off it means you will have 1 unique message to many.</span></label>
                        <div className='d-flex flex-column align-items-center'>
                            <div>
                                <div className="form-check m-2">
                                    <div className='d-flex align-items-center'>
                                        <input className="form-check-input mx-2 border-secondary" type="radio" name="messageRadio" onChange={(e) => { messageRadio.value = e.target.value }} id="messageRadio" value="audio" disabled={(personalized.value === 'Yes') || (p3.value === 'active' ? true : false)} />
                                        <label className="form-check-label mx-2" htmlFor="">
                                            Audio file
                                        </label>

                                        <div className='d-flex justify-content-around'>
                                            <CommanButton onClick={() => { whichAudio.value = 'Single' }} className='btnBack btn m-2' disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)}  >Single Prompt</CommanButton>
                                            <CommanButton onClick={() => { whichAudio.value = 'Multiple' }} className='btnBack btn m-2' disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} >Multi Prompt</CommanButton>
                                        </div>
                                    </div>



                                    <div className='mx-4' style={messageRadio.value === 'audio' ? { display: 'block' } : { display: 'none' }} >
                                        <div>
                                            {
                                                whichAudio.value === "Single"
                                                    ?
                                                    <div>
                                                        <hr />
                                                        <div className='d-flex'>
                                                            <div className="mx-2">
                                                                <input type="file" accept='.wav' className="form-control border-secondary" id="audioMessage" onChange={(e) => { (e.target.files[0].size <= 2000000) ? audioMessage.value = e.target.files[0] : toast.error("file must be less than 2MB") }} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} />
                                                                <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
                                                            </div>
                                                            <div className="mx-2">
                                                                <CommanButton type="submit" className="btnBack mb-3" onClick={messageTypeValidation} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} >Submit</CommanButton>
                                                            </div>
                                                            <div className="col-auto">

                                                                <CommanButton type="submit" className="btnBack mb-3" onClick={() => audioPlay(audioMessage.value, "audioPreview")}
                                                                    disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false) || (audioMessage.value === null ? true : false)} >{audioPreview.value ? "Pause" : "Play"}</CommanButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    undefined


                                            }
                                        </div>

                                        <div>
                                            {
                                                whichAudio.value === "Multiple"
                                                    ?
                                                    <div>
                                                        <hr />
                                                        <div className='d-flex justify-content-between'>
                                                            <label className="form-check-label mt-1 " htmlFor="">Welcome file</label>
                                                            <div className="mx-2">
                                                                <input type="file" accept='.wav' className="form-control border-secondary" id="welcomeFile" onChange={(e) => { (e.target.files[0].size <= 2000000) ? welcomeFile.value = e.target.files[0] : toast.error("file must be less than 2MB") }} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} />
                                                                <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <CommanButton type="submit" className="btnBack mb-3" onClick={() => audioPlay(welcomeFile.value, "welcomePreview")}
                                                                    disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false) || (welcomeFile.value === null ? true : false)} >{welcomePreview.value ? "Pause" : "Play"}</CommanButton>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className="form-check-label mt-1 " htmlFor="">Menu file</label>
                                                            <div className="mx-2">
                                                                <input type="file" accept='.wav' className="form-control border-secondary" id="menuFile" onChange={(e) => { (e.target.files[0].size <= 2000000) ? menuFile.value = e.target.files[0] : toast.error("file must be less than 2MB") }} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} />
                                                                <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <CommanButton type="submit" className="btnBack mb-3" onClick={() => audioPlay(menuFile.value, "menuPreview")}
                                                                    disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false) || (menuFile.value === null ? true : false)} >{menuPreview.value ? "Pause" : "Play"}</CommanButton>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className="form-check-label mt-1 " htmlFor="">Thanks file</label>
                                                            <div className="mx-2">
                                                                <input type="file" accept='.wav' className="form-control border-secondary" id="thanksFile" onChange={(e) => { (e.target.files[0].size <= 2000000) ? thanksFile.value = e.target.files[0] : toast.error("file must be less than 2MB") }} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} />
                                                                <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <CommanButton type="submit" className="btnBack mb-3" onClick={() => this.audioPlay(thanksFile.value, "thanksPreview")}
                                                                    disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false) || (thanksFile.value === null ? true : false)} >{thanksPreview.value ? "Pause" : "Play"}</CommanButton>
                                                            </div>
                                                        </div>
                                                        <div className="mx-2 d-flex justify-content-center">
                                                            <CommanButton type="submit" className="btnBack mb-3" onClick={messageTypeValidation} disabled={(messageRadio.value === 'text' ? true : false) || (p3.value === 'active' ? true : false)} >Submit</CommanButton>
                                                        </div>
                                                    </div>
                                                    :
                                                    undefined
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check m-2 ">
                                    <div className='d-flex align-items-center mt-2'>
                                        <input className="form-check-input m-2 border-secondary" type="radio" disabled={p3.value === 'active' ? true : false} name="messageRadio" onChange={(e) => { messageRadio.value = e.target.value }} id="messageRadio" value="text" />
                                        <label className="form-check-label m-2" htmlFor="flexRadioDefault1">
                                            Text to Speech – TTS tool
                                        </label>
                                        <CommanButton type="submit" onClick={() => { modal.value = !modal.value }} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)} >Add Template</CommanButton>

                                    </div>
                                    <Modal show={modal.value} onHide={() => { modal.value = !modal.value }} aria-labelledby="contained-modal-title-vcenter">
                                        <Modal.Header closeButton>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                Select message
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="grid-example">
                                            <div className='d-flex justify-content-center '>
                                                <CommanButton onClick={() => { whichMessage.value = 'write' }} className='btnBack btn m-2' >Writing Message</CommanButton>
                                                <CommanButton onClick={() => { whichMessage.value = 'exist' }} value='exist' className='btnBack btn m-2' >Existing Template</CommanButton>
                                            </div>
                                            <div>
                                                {
                                                    whichMessage.value === "write"
                                                        ?
                                                        <textarea className="form-control border-secondary" placeholder="Write your message here" id="writeMessage" rows={3} cols={20} onChange={(e) => { writeMessage.value = e.target.value }}  >
                                                        </textarea>
                                                        :
                                                        undefined


                                                }
                                            </div>

                                            <div>
                                                {
                                                    whichMessage.value === "exist"
                                                        ?
                                                        <select className="form-select border-secondary" id="existMessage" onChange={(e) => { existMessage.value = e.target.value }}  >
                                                            <option value="" >Select your template</option>
                                                            {
                                                                getTemplates.value?.map((temp) => {
                                                                    return <option key={temp?.templateid} value={temp?.templatemessage} >{temp?.templatemessage}</option>
                                                                })
                                                            }

                                                        </select>
                                                        :
                                                        undefined
                                                }
                                            </div>


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CommanButton className='btn btn-success' onClick={onWhatMessage}>Submit</CommanButton>
                                        </Modal.Footer>
                                    </Modal>

                                    <div className='m-2 d-flex'>
                                        <div className="mx-2">
                                            <textarea className="form-control border-secondary" placeholder="" id="ttsTextArea" rows={3} cols={25} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)} value={ttsTextArea.value} readOnly="true"  ></textarea>
                                        </div>


                                        <div className='mx-2'>
                                            <div className="input-group mb-3 ">
                                                <select className="form-select border-secondary" id="ttsVoiceTone" onChange={(e) => { ttsVoiceTone.value = e.target.value }} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)}>
                                                    <option value="">Select your voice tone</option>
                                                    {
                                                        getVoice.value?.map((voice, index) => {
                                                            return <option key={index} value={voice} >{voice}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-group ">
                                                <select className="form-select border-secondary" id="ttsVoiceSpeed" onChange={(e) => { ttsVoiceSpeed.value = e.target.value }} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)} >
                                                    <option value="" >Select your voice speed</option>
                                                    <option value="slow">Slow</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="fast">Fast</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    {
                                        personalized.value ?
                                            <div className='mx-2 my-2 '>
                                                <Tooltip title="Put var value for existing selected template" >
                                                    <input type="text" id='textData' className='form-control border-secondary w-50 m-auto' placeholder='Text Data'
                                                        disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)} onChange={(e) => { textData.value = e.target.value }} />
                                                </Tooltip>
                                            </div>
                                            : undefined
                                    }
                                    <div className="mx-2 d-flex justify-content-center">
                                        <CommanButton type="submit" className="btnBack m-2" onClick={handleGenerateSpeech} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)}>Preview</CommanButton>
                                        <div style={{ display: 'none' }}>
                                            {Url.value && <audio controls src={Url.value} autoPlay="true" />}
                                        </div>

                                        <CommanButton type="submit" className="btnBack m-2" onClick={messageTypeValidation} disabled={(messageRadio.value === 'audio' ? true : false) || (p3.value === 'active' ? true : false)}>Submit</CommanButton>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="5" able={p4.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Origination Number. &nbsp;
                            <span className="form-text">Origin of a communication, such as a phone call or text message</span></label>
                        <div className="row g-4 justify-content-center">

                            <div className="col-auto w-25">
                                <div className="input-group mb-3 ">
                                    <select className="form-select border-secondary" id="originNo" onChange={(e) => { originNo.value = e.target.value }}
                                        disabled={p4.value === 'active' ? true : false}
                                    >
                                        <option >Select Origination Number</option>
                                        {
                                            originationNumber.value?.map((each, index) => {
                                                return <option key={index} value={each?.origination_no}>{each?.origination_no}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-auto">
                                <CommanButton type="submit" className="btnBack mb-3" onClick={originNoValidation} disabled={p4.value === 'active' ? true : false} >Submit</CommanButton>
                            </div>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="6" able={p5.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Upload your list . &nbsp;
                            <span className="form-text">Your list must include personalization variables in the text file. </span></label>

                        <div className="row g-3 justify-content-center ">

                            <div className='col-auto w-25'>
                                <div className="input-group mb-3">
                                    <select className="form-select border-secondary" id="country" onChange={(e) => { country.value = e.target.value }}
                                        disabled={p5.value === 'active' ? true : false}
                                    >
                                        <option >Select Country Code</option>
                                        {
                                            countryCodes.value?.map((each) => {
                                                return <option key={each?.id} value={each?.country_code}>{each?.country_name} [{each?.country_code}]</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-auto">
                                <Tooltip title={<h6>"Inside in txt file must having following content like this - Number , $var1 , $var2 , $var3 "</h6>} style={{ fontSize: '15px' }} >

                                    {/* <input type="file" accept='.txt' className="form-control border-secondary" id="listTxt"
                                        onChange={(e) => { (e.target?.files[0]?.size <= 2000000) ? listTxt.value = e.target?.files[0] : (toast.error("file must be less than 2MB"); e.target?.files[0] === "") }}
                                    disabled={p5.value === 'active' ? true : false}
                                     /> */}

                                    <input
                                        type="file"
                                        accept=".txt"
                                        className="form-control border-secondary"
                                        id="listTxt"
                                        onChange={(e) => listTxt.value = e.target.files[0]}
                                        disabled={p5.value === 'active'}
                                    />

                                    <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .txt file(maxsize-2mb)</label>
                                </Tooltip>
                            </div>
                            <div className="col-auto">
                                <CommanButton type="submit" className="btnBack mb-3" onClick={listTxtValidation} disabled={p5.value === 'active' ? true : false} >Submit</CommanButton>
                            </div>

                        </div>
                    </div>
                </OuterBox>

                <OuterBox value="7" able={p6.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Schedule. &nbsp;
                            <span id="" className="form-text" >Set up days and time at which your campaign will run. Please notice that the platform does not make any call on sunday.</span></label>
                        <div className="row g-4 justify-content-center">
                            <div className='d-flex justify-content-center align-items-center' >
                                <span className='align-self-center mx-4' >Date range</span>
                                <CalendarMonthIcon fontSize='large' className='mx-2' />
                                <span className='align-self-center mx-2' >From</span>
                                <ReactDatePicker
                                    selected={scheduleFrom.value}
                                    onChange={(date) => { scheduleFrom.value = date }}
                                    dateFormat="dd-MM-YYYY"
                                    // minDate={new Date()}
                                    className='p-2 border-1 rounded'
                                    disabled={(p6.value === 'active' ? true : false)}
                                />

                                <span className='align-self-center mx-2' >to</span>
                                <ReactDatePicker
                                    selected={scheduleTo.value}
                                    onChange={(date) => { scheduleTo.value = date }}
                                    dateFormat="dd-MM-YYYY"
                                    minDate={scheduleFrom.value}
                                    className='p-2 border-1 rounded'
                                    disabled={(scheduleFrom.value === null ? true : false) || (p6.value === 'active' ? true : false) || (startTime.value === null ? true : false)}
                                />

                            </div>
                            <div className='d-flex justify-content-center align-items-center' >
                                <span className='align-self-center mx-4' >Hour range</span>
                                <AccessTimeIcon fontSize='large' className='mx-2' />
                                <span className='align-self-center mx-2' >From</span>
                                <ReactDatePicker
                                    selected={startTime.value}
                                    onChange={(time) => { startTime.value = time }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="hh:mm aa"
                                    // minTime={(dayjs(scheduleFrom.value).format("YYYY-MM-DD") == dayjs().format("YYYY-MM-DD")) ? new Date().setHours(dayjs().get('hour'),dayjs().get('minute')) : new Date().setHours(7, 0) }
                                    // maxTime={new Date().setHours(19, 0)}
                                    className='p-2 border-1 rounded'
                                    disabled={(scheduleFrom.value === null ? true : false) || (p6.value === 'active' ? true : false)}
                                />
                                <span className='align-self-center mx-2' >to</span>
                                <ReactDatePicker
                                    selected={lastTime.value}
                                    onChange={(time) => { lastTime.value = time }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={5}
                                    dateFormat="hh:mm aa"
                                    // minTime={(dayjs(scheduleFrom.value).format() === dayjs(scheduleTo.value).format()) ? startTime.value : new Date().setHours(7, 0)}
                                    // maxTime={new Date().setHours(19, 0)}
                                    className='p-2 border-1 rounded'
                                    disabled={(scheduleFrom.value === null ? true : false) || (p6.value === 'active' ? true : false) || (scheduleTo.value === null ? true : false) || (startTime.value === null ? true : false)}
                                />

                            </div>
                            <div className='d-flex justify-content-center align-items-center' >
                                <p className='align-self-center mx-4' >Day Range</p>
                                <CalendarTodayIcon fontSize='large' className='mx-2' />
                                <div
                                    className="col-auto"
                                    style={{
                                        margin: '5px 10px',
                                        minWidth: '100px',
                                        maxWidth: '150px',
                                        height: '100px',
                                        overflowY: 'scroll',
                                        padding: '4px',
                                        border: '1px solid ',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <SelectInput type="checkbox" id="monday" onChange={daysEntry} value="1" title="Monday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="tuesday" onChange={daysEntry} value="2" title="Tuesday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="wednesday" onChange={daysEntry} value="3" title="Wednesday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="thursday" onChange={daysEntry} value="4" title="Thursday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="friday" onChange={daysEntry} value="5" title="Friday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="saturday" onChange={daysEntry} value="6" title="Saturday" disabled={weekDayDisable} />
                                    <br />
                                    <SelectInput type="checkbox" id="sunday" onChange={daysEntry} value="7" title="Sunday" disabled={weekDayDisable} />
                                </div>


                            </div>

                        </div>
                    </div>
                </OuterBox>

                <OuterBox value="8" able={p7.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="exampleInputEmail1" className="form-label fw-semibold formLabel">Review. &nbsp;
                            <span id="emailHelp" className="form-text">Confirm that all the information has been filled correctly.</span></label>
                        <div className="row g-3 justify-content-center">

                            <div className="form-check col-auto">
                                <input className="form-check-input border-secondary" type="checkbox" onChange={onReviewChange} id="review" disabled={p7.value === 'active' ? true : false} />
                                <label className="form-check-label" htmlFor="review">
                                    I ́ve reviewed all the information that will be submitted
                                </label>
                            </div>
                        </div>
                    </div>
                </OuterBox>



            </div>
            <div className="d-flex justify-content-center">
                <CommanButton type="submit" className="btnBack mb-3" onClick={onSubmitHandler} >Save and submit campaign</CommanButton>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading.value}
            // onClick={}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )

}


export default AddPvm;