import React from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProgressBar from '../../../component/progressBar/ProgressBar';
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep';
import OuterBox from '../../../component/OuterBox';
import { minDateTime } from '../../../common/MinDate';
import CommanButton from '../../../component/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCorpList, modifyCorpRbt } from '../../../redux/slice/ListCorp';
import { useSignal } from '@preact/signals-react';
import Loader from '../../../component/loader/Loader';



function UPCCorpRbt() {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)
    const audioFile = useSignal(null);
    const upgrade = useSignal('');
    const scheduleFrom = useSignal('');
    const scheduleTo = useSignal('');
    const getData = useSignal(location.state?.data);
    const p1 = useSignal('active');
    const p2 = useSignal('active');
    const loading = useSignal(false)

    if (scheduleFrom.value?.length && scheduleTo.value?.length !== 0) {
        p2.value = 'completed'
    } else {
        p2.value = 'active'
    }



    const dataValidation = () => {
        let formData = null;
        let data = {
            template_name: getData.value?.template_name,
            start_date: scheduleFrom.value,
            end_date: scheduleTo.value
        };

        if (p1.value === 'completed' && p2.value === 'completed') {
            formData = new FormData();
            formData.append('filetxt', upgrade.value === 'yes' ? audioFile.value : null);
            formData.append('corpRbt', JSON.stringify(data));
        }

        return new Promise((resolve, reject) => {
            if (formData !== null) {
                resolve(formData);
            } else {
                reject("form data is null");
            }
        });
    };

    const onSubmitHandler = () => {
        dataValidation()
            .then((data) => {
                loading.value = true
                return dispatch(modifyCorpRbt({ data: data, id: getData.value?.corp_ID, token: token }))
            })
            .then((result) => {
                if (result?.payload?.data?.httpStatusCode === 200) {
                    dispatch(getCorpList({ token: token, id: userId }))
                    navigate('/successtemp', { state: { temptype: 'Corp-RBT', mode: 're-submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewCorpRbt' } })
                } else {
                    toast.error("Internal server error")
                }
                loading.value = false
            })
            .catch((err) => {
                loading.value = false
                toast.error("Error while modify")
            });

    };

    if (loading.value) {
        return <Loader />
    }


    return (
        <>
            <ProgressBar>
                <ProgressBarStep value="1" mode={p1.value} />
                <ProgressBarStep value="2" mode={p2.value} />
            </ProgressBar>
            <div className='container-fluid m-2 '>

                <div className="d-flex justify-content-between my-2 mx-4">
                    <div className='mb-4 border border-secondary p-3 rounded w-75' style={{ border: '1px solid', }}>
                        <div className='fw-bold fs-5'>Service: Corp-RBT</div>
                        <div className='fw-bold fs-5'>Selected Campaign: {getData.value?.template_name}</div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <Link to="/campaign/viewCorpRbt">
                            <CommanButton type="submit" className="btnBack mb-3">
                                <ArrowBackIosIcon />Back
                            </CommanButton>
                        </Link>
                    </div>
                </div>


                <OuterBox value="1" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="fw-semibold formLabel ">Update your employee list.</label>
                        <span className="form-text"></span>
                        <div className='d-flex flex-column align-items-center'>
                            <div>
                                <div className='form-check d-flex align-items-center '>
                                    <input className="form-check-input mx-2 border-secondary my-2" type="radio" name="upgrade" id="upgrade" value="no" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'completed' }} />
                                    <label className="form-check-label mx-2" htmlFor="">
                                        I will use the exact same list
                                    </label>
                                </div>
                                <div className='d-flex form-check flex-wrap  mt-3'>
                                    <input className="form-check-input mx-2 border-secondary" type="radio" name="upgrade" id="upgrade" value="yes" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'active' }} />
                                    <label className="form-check-label mx-2" >
                                        I want to update my list
                                    </label>
                                </div>

                            </div>
                            {
                                upgrade.value === 'yes' &&
                                <div className='my-4 d-flex '>
                                    <div>
                                        <input type="file" accept='.txt' className="form-control border-secondary" id="audioFile" onChange={(e) => { (e.target.files[0].size < 2000000) ? audioFile.value = e.target.files[0] : toast.error("file size") }} disabled={upgrade.value === 'no' ? true : false} />
                                        <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .txt file(maxsize-2mb)</label>
                                    </div>
                                    <div className='mx-2'>
                                        <CommanButton type='submit' text='Submit' disabled={upgrade.value === 'no' ? true : false} onClick={() => { audioFile.value === null ? toast.error('Please select audio file') : p1.value = 'completed' }} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="2" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel ">Re-schedule.</label>
                        <span className="form-text"></span>
                        <div className="row g-4 justify-content-center">

                            <div className="col-auto d-flex align-items-center">
                                <label htmlFor="scheduleFrom" className="mx-2">from</label>
                                <input type="datetime-local" min={minDateTime("")} className="border-secondary-subtle p-2 mx-1 rounded" id="scheduleFrom" onChange={(e) => { scheduleFrom.value = e.target.value }} />
                            </div>
                            <div className="col-auto d-flex align-items-center">
                                <label htmlFor="scheduleTo" className="mx-2">to</label>
                                <input type="datetime-local" min={minDateTime(scheduleFrom)} className="border-secondary-subtle p-2 mx-1 rounded" id="scheduleTo" onChange={(e) => { scheduleTo.value = e.target.value }} disabled={scheduleFrom.value?.length === 0 ? true : false} />
                            </div>
                        </div>
                    </div>
                </OuterBox>
            </div>
            <div className="d-flex justify-content-center my-4">
                <CommanButton type="submit" className="btnBack mb-3" onClick={onSubmitHandler}  >SEND TEMPLATE</CommanButton>
            </div>
        </>
    )
}

export default UPCCorpRbt
