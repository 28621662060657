import { createAsyncThunk  } from '@reduxjs/toolkit';
import { axiosInstance } from '../store/Api'; // Adjust the path if needed
import { toast } from 'react-toastify';

export const getRevenueReportList = createAsyncThunk(
  'reportRevenue/getRevenueReportList',
  async ({token,billingDate}) => {
    try {
      const response = await axiosInstance.get(`/reportRevenueReport1/${billingDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data from the response
    } catch (error) {
      // Handle specific status codes if needed
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      throw error; // Properly propagate the error
    }
  }
);

export const getAccountStatusReportList = createAsyncThunk(
  'reportRevenue/getRevenueReportList',
  async ({token,billingDate}) => {
    try {
      const response = await axiosInstance.get(`/reportAccountStatusProcedure/${billingDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data from the response
    } catch (error) {
      // Handle specific status codes if needed
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      throw error; // Properly propagate the error
    }
  }
);


export const getTransactionReportList = createAsyncThunk(
  'reportRevenue/getRevenueReportList',
  async ({token,billingDate}) => {
    try {
      const response = await axiosInstance.get(`/transactional_report/${billingDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data from the response
    } catch (error) {
      // Handle specific status codes if needed
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      throw error; // Properly propagate the error
    }
  }
);


export const getCampaignReportList = createAsyncThunk(
  'reportRevenue/getRevenueReportList',
  async ({token,billingDate}) => {
    try {
      const response = await axiosInstance.get(`/campaign_report_details/${billingDate}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data from the response
    } catch (error) {
      // Handle specific status codes if needed
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      throw error; // Properly propagate the error
    }
  }
);