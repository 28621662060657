import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../store/Api'; // Import axios instance
import { toast } from 'react-toastify'; // Import toast for error notifications

const initialState = {
    data: null,
    isLoading: true,
    error: false
};

// Async thunk for fetching PVM report
export const getPvmReport = createAsyncThunk("getPvmReport", async (data) => {
    try {
        const response = await axiosInstance.get(`/betweenDatesFromCampaign_master/${data.userId}`, {
            params: {
                startDate: `${data.start} 00:00:00`,
                endDate: `${data.end} 00:00:00`
            },
            headers: {
                "Authorization": `Bearer ${data.token}`
            }
        });
        return response.data; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});

// Slice for PVM report
export const ReportPvm = createSlice({
    name: 'reportPvm',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPvmReport.fulfilled, (state, action) => {
                state.data = action.payload?.body[0] || null; // Adjust based on actual response structure
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getPvmReport.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getPvmReport.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default ReportPvm.reducer;
