import React, { useEffect } from 'react'
import CommanButton from '../../component/CommanButton'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaignReportList } from '../../redux/slice/RevenueReport'
import { useSignal } from '@preact/signals-react'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../../component/loader/Loader'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify'

export default function CampaignReport() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token?.data?.token)
  const data = useSignal([])
  const onLoad = useSignal(false)
  const currentPage = useSignal(1)

  const monthYear = useSignal()
  const billingCycle = useSignal('')

  const getData = async (date) => {
    onLoad.value = true;
    try {
      // Fetch data using dispatch
      const response = await dispatch(getCampaignReportList({ token: token, billingDate: date }));
      console.log(response?.payload?.data?.body)

      data.value = response?.payload?.data?.body
    } catch (err) {
      console.error(err);
    } finally {
      onLoad.value = false;
    }
  };


  // useEffect(() => {
  //   getData("")
  // }, [])


  const downloadCSV = () => {
    const csvData = [
      [
        "Id",
        "Country Code",
        "User Id",
        "Company Name",
        "Campaign Id",
        "Interface",
        "Campaign Name",
        "Created By",
        "Start Date",
        "Duration",
        "Total Call",
        "Executed Call",
        "Scheduled Call",
        "Status",
        "Success Call",
        "No Answer",
        "User Busy",
        "Failed"
    ],
      ...data.value?.map(item => [
        item.id,
        item.countryCode,
        item.usersId,
        item.cpassCompanyName,
        item.campainigId,
        item.interfaceType,
        item.cpassCampaignName,
        item.cpassCreatedBy,
        item.cpassStartedDate,
        // item.cpassEndDate,
        item.durationTime,
        item.totalCalls,
        item.executedCalls,
        item.sheduledCalls,
        item.statusOfCampaign,
        item.successCall,
        item.noAnswer,
        item.userBusy,
        item.failed
    ]
    )
    ].map(e => e.join(",")).join("\n");

    let cycle = 1 ;
    if (billingCycle.value === '05') {
      cycle = "01"
    } else if (billingCycle.value === '20') {
      cycle = "02"
    } else if (billingCycle.value === '30') {
      cycle = "03"
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `Tonecast_Campaign_Report_${dayjs(data.value[0]?.Date).format('YYYYMM')}_Cycle${cycle}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Tonecast_Campaign_Report_YYYYMM_CycleX

  const onSubmit = () => {
    if (monthYear.value === undefined) {
      toast.error("Enter month and year")
    } else if (billingCycle.value?.length === 0) {
      toast.error("Select Billing cycle")
    } else {
      getData(dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value)
    }
  }


  const perPage = 10;
  let indexofLast = currentPage.value * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = data.value?.slice(indexofFirst, indexofLast)

  if (onLoad.value) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className='mx-3' >
        <div className='d-flex justify-content-between my-2'>
          <h3 className='fw-bolder'>Campaign Reports ✨</h3>
          <CommanButton disabled={data.value?.length === 0} onClick={downloadCSV}  >Download</CommanButton>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label={'"month" and "year"'} views={['month', 'year']} value={monthYear.value} onChange={(e) => { monthYear.value = e }} />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl sx={{ mx: 2, minWidth: 250 }} >
            <InputLabel id="demo-select-small-label">Billing Cycle</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={billingCycle.value}
              label="Billing Cycle"
              onChange={(e) => { billingCycle.value = e.target.value }}

            >
              <MenuItem value={"05"}>01</MenuItem>
              <MenuItem value={"20"}>02</MenuItem>
              <MenuItem value={"30"}>03</MenuItem>
            </Select>
          </FormControl>
          <div>
            <CommanButton onClick={onSubmit} >Submit</CommanButton>
          </div>
        </div>

        {
          data.value?.length === 0 ?
            <div className='d-flex justify-content-center fw-bold mx-5' >No Data found</div>
            :
            <>
              <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className='bodyColor'>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Country Code</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>User Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campany Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campaign Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Interface</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campaign Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Created By</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Start Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Duration</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Total Call</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Executed Call</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Scheduled Call</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Status</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Success Call</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>No Answer</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>User Busy</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Failed</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="">
                    {activePage?.map((each, index) => {
                      return <ViewList key={index} data={each} date={dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value} billingCycle={billingCycle.value} />
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
                {
                  data.value?.length > perPage &&
                  <div className='d-flex justify-content-center my-4'>
                    <Pagination count={Math.ceil(data.value?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                  </div>
                }
            </>
        }
      </div>
    );
  }
}



const ViewList = ({ data, date, billingCycle }) => {

  if (billingCycle === '05') {
    billingCycle = "01-05"
  } else if (billingCycle === '20') {
    billingCycle = "06-20"
  } else if (billingCycle === '30') {
    billingCycle = "21-30"
  }
  return (
    <TableRow >
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.id}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.countryCode}
      </TableCell>
      <TableCell align="center" >
        {data.usersId}
      </TableCell>
      <TableCell align="center" >
        {data.cpassCompanyName}
      </TableCell>
      <TableCell align="center" >
        {data.campainigId}
      </TableCell>
      <TableCell align="center" >
        {data.interfaceType}
      </TableCell>
      <TableCell align="center" >
        {data.cpassCampaignName}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.cpassCreatedBy}
      </TableCell>
      <TableCell align="center" >
        {data.cpassStartedDate?.slice(0,10)}
      </TableCell>
      {/* <TableCell align="center" >
        {data.cpassEndDate}
      </TableCell> */}
      <TableCell align="center" >
        {data.durationTime}
      </TableCell>
      <TableCell align="center" >
        {data.totalCalls}
      </TableCell>
      <TableCell align="center" >
        {data.executedCalls}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.sheduledCalls}
      </TableCell>
      <TableCell align="center" >
        {data.statusOfCampaign}
      </TableCell>
      <TableCell align="center" >
        {data.successCall}
      </TableCell>
      <TableCell align="center" >
        {data.noAnswer}
      </TableCell>
      <TableCell align="center" >
        {data.userBusy}
      </TableCell>
      <TableCell align="center" >
        {data.failed}
      </TableCell>
    </TableRow>
  );
}
