import React, { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Chip, Tabs } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function CreditLimitHistory() {
    const navigate = useNavigate()
    const [value, setValue] = useState('2024');
    const [months, setmonths] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getMonths(newValue)
    };

    const getMonths = (newValue) => {
        let monthData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        if (parseInt(newValue) === dayjs().year()) {
            setmonths(monthData?.slice(0, dayjs().month() + 1))
        } else {
            setmonths(monthData)
        }
    }

    useEffect(() => {
        getMonths(value)
    }, [])

    function getYearsBetween(startYear, endYear) {
        let result = [];
        for (let year = startYear; year <= endYear; year++) {
            result.push(year+'');
        }
        return result.reverse();
    }
    
    let startYear = process.env.REACT_APP_CREDIT_LIMIT_HISTORY_YEAR;
    let endYear = dayjs().year() + '' ;

    let years = getYearsBetween(startYear, endYear);

    return (
        <div className='mx-3'>
            <TabContext value={value} >
                <Tabs value={value} onChange={handleChange} sx={{ color: '#6366f1' }}>
                    {
                        years?.map((each,index)=>{
                            return <Tab key={index} label={each} value={each} />
                        })
                    }
                </Tabs>
                <TabPanel value={value}>
                </TabPanel>


            </TabContext>
            <div className='row d-flex justify-content-center'>
                {
                    months?.map((each, index) => {
                        return <Chip label={each}  onClick={() => { navigate(`/creditHistory/${value}/${each}`) }} sx={{ fontSize: '18px', margin: '20px 10px' }} className='col-3' key={index} />
                    })
                }
            </div>
        </div>
    )
}
