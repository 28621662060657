import React, { useEffect } from 'react'
import CommanButton from '../../component/CommanButton'
import { useDispatch, useSelector } from 'react-redux'
import { getRevenueReportList } from '../../redux/slice/RevenueReport'
import { useSignal } from '@preact/signals-react'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../../component/loader/Loader'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify'

export default function RevenueReport() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token?.data?.token)
  const data = useSignal([])
  const onLoad = useSignal(false)
  const currentPage = useSignal(1)

  const monthYear = useSignal()
  const billingCycle = useSignal('')

  const getData = async (date) => {
    onLoad.value = true;
    try {
      // Fetch data using dispatch
      const response = await dispatch(getRevenueReportList({ token: token, billingDate: date }));
      // console.log(response?.payload?.data?.body)

      // Process the response data
      const items = response?.payload?.data?.body.flatMap(item => [
        {
          "TotalExecutedCalls": item["Plan Type One Way"]["Total Executed Calls One Way"],
          "ProductPlan": item["Product Plan"],
          "Date": item["Date"],
          "NoofCampaign": item["Plan Type One Way"]["Plan Type One Way(Total no of Campagin)"],
          "MArket": item["MArket"],
          "BillingCycle": item["Billing Cycle"],
          "Charges": item["Plan Type One Way"]["Charges of One Way"],
          "NoofAccounts": item["No of Accounts"],
          "PlanType": "One Way"
        },
        {
          "TotalExecutedCalls": item["Plan Type Two Way"]["Total Executed Calls Two Way"],
          "ProductPlan": item["Product Plan"],
          "Date": item["Date"],
          "NoofCampaign": item["Plan Type Two Way"]["Plan Type Two Way(Total no of Campagin)"],
          "MArket": item["MArket"],
          "BillingCycle": item["Billing Cycle"],
          "Charges": item["Plan Type Two Way"]["Charges of Two Way"],
          "NoofAccounts": item["No of Accounts"],
          "PlanType": "Two Way"
        }
      ]);

      const uniqueItems = [];
      const seen = new Set();

      items.forEach(item => {
        // Check if NoofCampaign, TotalExecutedCalls, and Charges have value 0
        if (
          parseInt(item.NoofCampaign) !== 0 &&
          parseInt(item.TotalExecutedCalls) !== 0 &&
          parseFloat(item.Charges) !== 0
        ) {
          const key = `${item.NoofCampaign}|${item.TotalExecutedCalls}|${item.Charges}`;
          if (!seen.has(key)) {
            seen.add(key);
            uniqueItems.push(item);
          }
        }
      });

      // Assign result to data and turn off loading
      data.value = uniqueItems;
    } catch (err) {
      console.error(err);
    } finally {
      onLoad.value = false;
    }
  };


  // useEffect(() => {
  //   getData("")
  // }, [])


  const downloadCSV = () => {
    const csvData = [
      ['Date', 'Product Plan', 'Plan Type', 'Billing Cycle', 'Market', 'Number of Accounts', 'Number of Campaigns', 'Total Executed Calls', 'Charges'],
      ...data.value?.map(item => [item.Date, item.ProductPlan, item.PlanType, item.BillingCycle, item.MArket, item.NoofAccounts, item.NoofCampaign, item.TotalExecutedCalls, item.Charges])
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `Tonecast_Revenue_Report_${dayjs(data.value[0]?.Date).format('YYYYMM')}_Cycle${data.value[0]?.BillingCycle}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onSubmit = () => {
    if (monthYear.value === undefined) {
      toast.error("Enter month and year")
    } else if (billingCycle.value?.length === 0) {
      toast.error("Select Billing cycle")
    } else {
      getData(dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value)
      // getData('2024-11-22')
    }
  }


  const perPage = 10;
  let indexofLast = currentPage.value * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = data.value?.slice(indexofFirst, indexofLast)

  if (onLoad.value) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className='mx-3' >
        <div className='d-flex justify-content-between my-2'>
          <h3 className='fw-bolder'>Revenue Reports ✨</h3>
          <CommanButton disabled={data.value?.length === 0} onClick={downloadCSV}  >Download</CommanButton>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label={'"month" and "year"'} views={['month', 'year']} value={monthYear.value} onChange={(e) => { monthYear.value = e }} />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl sx={{ mx: 2, minWidth: 250 }} >
            <InputLabel id="demo-select-small-label">Billing Cycle</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={billingCycle.value}
              label="Billing Cycle"
              onChange={(e) => { billingCycle.value = e.target.value }}

            >
              <MenuItem value={"05"}>01</MenuItem>
              <MenuItem value={"20"}>02</MenuItem>
              <MenuItem value={"30"}>03</MenuItem>
            </Select>
          </FormControl>
          <div>
            <CommanButton onClick={onSubmit} >Submit</CommanButton>
          </div>
        </div>

        {
          data.value?.length === 0 ?
            <div className='d-flex justify-content-center fw-bold mx-5' >No Data found</div>
            :
            <>
              <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className='bodyColor'>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Product Plan</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Plan Type</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Cycle</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Market</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>No. of Accounts</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>No. of Campaigns</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Total Executed Calls</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Charges</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="">
                    {activePage?.map((each, index) => {
                      return <ViewList key={index} data={each} />
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
                {
                  data.value?.length > perPage &&
                  <div className='d-flex justify-content-center my-4'>
                    <Pagination count={Math.ceil(data.value?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                  </div>
                }
            </>
        }
      </div>
    );
  }

}



const ViewList = ({ data }) => {

  return (
    <TableRow >
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.Date}
      </TableCell>
      <TableCell align="center" >
        {data.ProductPlan}
      </TableCell>
      <TableCell align="center" >
        {data.PlanType}
      </TableCell>
      <TableCell align="center" >
        {data.BillingCycle}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.MArket}
      </TableCell>
      <TableCell align="center" >
        {data.NoofAccounts}
      </TableCell>
      <TableCell align="center" >
        {data.NoofCampaign}
      </TableCell>
      <TableCell align="center" >
        {data.TotalExecutedCalls}
      </TableCell>
      <TableCell align="center" >
        {data.Charges}
      </TableCell>

    </TableRow>
  );
}