import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false,
};

// Async thunk for fetching ad list
export const getAdList = createAsyncThunk("getAdList", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getAllAddRbt/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for creating an ad
export const createAd = createAsyncThunk("createAd", async ({ token, id, data }) => {
    try {
        const response = await axiosInstance.post(`/saveAddRbt/${id}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for modifying an ad
export const modifyAdRbt = createAsyncThunk("modifyAdRbt", async ({ token, id, data }) => {
    try {
        const response = await axiosInstance.put(`/updateAddRbt/${id}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for deleting an ad
export const deleteAd = createAsyncThunk("deleteAd", async ({ token, id }) => {
    try {
        const response = await axiosInstance.delete(`/deleteAddRbtById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching all templates list
export const getAllTemplatesList = createAsyncThunk("getAllTemplatesList", async ( token ) => {
    try {
        const response = await axiosInstance.get("/getTemplateName_AdvRbt", {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching ad by ID
export const getAdById = createAsyncThunk("getAdById", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getAddRbtById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

export const ListAd = createSlice({
    name: 'adList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAdList.fulfilled, (state, action) => {
                if (action.payload?.httpStatusCode === 200) {
                    state.data = action.payload?.body;
                    state.error = false;
                } else {
                    state.data = action.payload?.body;
                    state.error = true;
                }
                state.isLoading = false;
            })
            .addCase(getAdList.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getAdList.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    },
});

export default ListAd.reducer;
