import React, { useEffect, useState } from 'react';
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from 'react-bootstrap/Modal';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import { useSignal } from '@preact/signals-react';
import Loader from '../../../component/loader/Loader';
import ErrorPage from '../../../component/error/ErrorPage';
import CommanButton from '../../../component/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAd, getAdList } from '../../../redux/slice/ListAd';


const AdRBT = () => {
    const currentPage = useSignal(1)
    const dispatch = useDispatch();
    let data = useSelector(state => state.adList)
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)

    useEffect(() => {
        dispatch(getAdList({ token: token, id: userId }))
    }, []);

    let deleteId = (id) => {
        dispatch(deleteAd({ id: id, token: token })).then((resp) => {
            if (resp.payload?.data?.httpStatusCode === 200) {
                toast.success('Deleted Successfully')
                dispatch(getAdList({ token: token, id: userId }))
            } else {
                toast.error('Internal server error')
            }
        })
    }

    const perPage = 15;
    let indexofLast = currentPage * perPage
    let indexofFirst = indexofLast - perPage
    let activePage = data.data?.slice(indexofFirst, indexofLast)

    if (data.isLoading) {
        return (
            <Loader />
        )
    } else if (data.error) {
        return (
            <ErrorPage />
        )
    } else {
        return (
            <div className='mx-3' >
                <div className='d-flex justify-content-between my-2 mx-2'>
                    <h3 className='fw-bolder'>View Ad-RBT ✨</h3>
                    <Link to='/campaign/addAdRbt' style={{ textDecoration: 'none' }}>
                        <CommanButton type="submit" className="" ><AddIcon />Add Ad-RBT</CommanButton>
                    </Link>
                </div>


                {
                    data?.data?.length === 0
                        ?
                        <div className='d-flex justify-content-center fw-bold' >No Ad-Rbt Campaign found</div>
                        :
                        <>
                            <p >This list shows all the Ad-RBT campaigns carried out by the user.</p>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="bodyColor">
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                TEMPLATE ID
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                TEMPLATE NAME
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                START DATE
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                END DATE
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                EXPECTED SUBS
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                STATUS
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                VIEW
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>
                                                RE-USE
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className="">

                                        {activePage?.map((listCard) => (
                                            <ViewAdList
                                                key={listCard.templateID}
                                                list={listCard}
                                                remove={deleteId}
                                            />
                                        ))}

                                    </TableBody >
                                </Table>
                                {
                                    data.data?.length > perPage &&
                                    <div className='d-flex justify-content-center my-4'>
                                        <Pagination count={Math.ceil(data.data?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                                    </div>
                                }
                            </TableContainer>
                        </>
                }
            </div>
        );
    }


};


const ViewAdList = ({ list, remove }) => {
    const modal = useSignal(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(list);
    }, []);

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
                {data.templateID}
            </TableCell>
            <TableCell align="center" >
                {data.templatename}
            </TableCell>
            <TableCell align="center" >
                {data.startdate?.slice(0, 10)}
            </TableCell>
            <TableCell align="center" >
                {data.enddate?.slice(0, 10)}
            </TableCell>
            <TableCell align="center" >
                {data.expectedsubscribers}
            </TableCell>
            <TableCell align="center" >
                {(data.status === 'R' && "Running") || (data.status === 'P' && "Paused") || (data.status === 'N' && "New") || (data.status === 'C' && "Completed") || (data.status === 'A' && "Approved") || (data.status === 'E' && "Reject") || (data.status === 'T' && "Tested")}
            </TableCell>
            <TableCell align='center' >
                <Link to={`/campaign/viewAdRbt/${data.templateID}`} state={{ data }} >
                    <VisibilityOutlinedIcon style={{ color: 'black' }} />
                </Link>
            </TableCell>
            <TableCell align="center" >
                <Link to='/campaign/modifyAdRbt' state={{ data }}>
                    <EditNoteSharpIcon />
                </Link>
            </TableCell>
            <TableCell align="center">
                <button className='border-0 bg-transparent' onClick={() => { modal.value = !modal.value }}>
                    <DeleteForeverIcon style={{ color: 'red' }} />
                </button>
                <Modal show={modal.value} onHide={() => { modal.value = !modal.value }}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger'>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, you want to delete this {data.templatename} ?</Modal.Body>
                    <Modal.Footer>
                        <CommanButton className='btn btn-danger' onClick={() => { remove(data.templateID); modal.value = !modal.value }}>
                            Delete
                        </CommanButton>
                    </Modal.Footer>
                </Modal>
            </TableCell>
        </TableRow>
    );
};


export default AdRBT;
