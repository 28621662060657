import React, { useEffect } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from 'react-redux';
import { useSignal } from '@preact/signals-react';


function CreditBarComponent() {
    const getProfileData = useSelector(state => state.profile.data)
    const data = useSignal({})
    useEffect(() => {
        data.value = getProfileData
    }, [getProfileData])

    let total = data.value?.creditlimit - data.value?.availbalance;
    let credit = (total / data.value?.creditlimit) * 100 + "";
    let creditlimit = parseInt(credit.slice(0, 2))

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='clsflex' style={{ justifyContent: 'center' }} >
                <label style={{ fontWeight: 'bold', display: 'flex' }}>Credit limit available US ${data.value?.availbalance} </label>
            </div>
            <div className='mb-2' style={{ width: '50%' }}>
                <ProgressBar completed={creditlimit} bgColor="#6366f1" animateOnRender={true} height="22px" customLabelStyles={{ overflow: 'hidden' }} />
            </div>
            <div style={{ height: '1px', width: '100%', borderColor: 'gray', backgroundColor: 'gray', color: 'gray', marginTop: '', marginBottom: '10px' }}></div>

        </div>
    );
}



export default CreditBarComponent;