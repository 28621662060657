import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false,
};

// Async thunk for fetching corporate list
export const getCorpList = createAsyncThunk("getCorpList", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getAllCorpRbt/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for creating a corporate entry
export const createCorp = createAsyncThunk("createCorp", async ({ token, id, data }) => {
    try {
        const response = await axiosInstance.post(`/saveCorpRbt/${id}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for modifying a corporate entry
export const modifyCorpRbt = createAsyncThunk("modifyCorpRbt", async ({ token, id, data }) => {
    try {
        const response = await axiosInstance.put(`/updateCorpRbt/${id}`, data, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for deleting a corporate entry
export const deleteCorp = createAsyncThunk("deleteCorp", async ({ token, id }) => {
    try {
        const response = await axiosInstance.delete(`/deleteCorpRbtById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching all corporate templates list
export const getAllTemplatesList = createAsyncThunk("getAllTemplatesList", async ( token ) => {
    try {
        const response = await axiosInstance.get("/getTemplateName_CorpRbt", {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

// Async thunk for fetching corporate entry by ID
export const getCorpById = createAsyncThunk("getCorpById", async ({ token, id }) => {
    try {
        const response = await axiosInstance.get(`/getCorpRbtById/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

export const ListCorp = createSlice({
    name: 'corpList',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getCorpList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getCorpList.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getCorpList.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            })

    },
});

export default ListCorp.reducer;
