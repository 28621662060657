import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Box, TextField, Typography } from '@mui/material';
import authImage from '../../images/auth-decoration.png'
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { addData } from '../../redux/slice/VendorDataSlice';
import { addToken } from '../../redux/slice/TokenSlice'
import { useSignal } from '@preact/signals-react';
import { LoadingButton } from '@mui/lab';

const emptyToastId = "notNull"
const invalidToastId = 'notValid'


const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const username = useSignal('');
    const password = useSignal('');
    const loading = useSignal(false);
    const firstElement = useRef("");
    const lastElement = useRef("");


    const loginApi = () => {
        if (username.length !== 0 && password.length !== 0) {
            let response = {
                username: username.value,
                password: password.value
            };
            let url = `${process.env.REACT_APP_API_URL}/signin`
            loading.value = true;
            axios.post(url, response)
                .then((resp) => {
                    console.log(resp)
                    const redirectStatus = resp.data.httpStatusCode;

                    if (redirectStatus === 200) {

                        const links = resp.data?.body[2]?.httpLinkslst
                        links?.map((link) => {
                            if (link?.linkId === parseInt(process.env.REACT_APP_CAMPAIGN_APPROVAL_LINK)) {
                                sessionStorage.setItem("campApprove", JSON.stringify(link))
                            }
                        })
                        dispatch(addData(resp.data?.body[0]))
                        dispatch(addToken(resp.data?.body[1]))
                        const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(resp.data.body[0]), `${process.env.React_APP_TOKEN_SECURITY_KEY}`).toString();
                        const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(resp.data.body[1]), `${process.env.React_APP_TOKEN_SECURITY_KEY}`).toString();
                        sessionStorage.setItem("Token", encryptedToken);
                        sessionStorage.setItem("UserData", encryptedUserData);
                        sessionStorage.setItem("sessionExpired", JSON.stringify(resp.data?.body[4]))

                        navigate('/')

                    } else {
                        loading.value = false
                        toast.error("Invalid credentail", { position: "top-center", toastId: invalidToastId });
                        firstElement.current.focus()
                    }
                })
                .catch((error) => {
                    loading.value = false
                    toast.error("Error Found", { position: "top-center", toastId: invalidToastId });
                    console.log(error);
                });
        } else {
            toast.error("Please enter credentail", { toastId: emptyToastId })
            firstElement.current.focus()
        }

    }


    useEffect(() => {
        firstElement.current.focus()
    }, [])

  
        return (
            <Box display="flex" height="100vh">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
                    <Typography variant="h5" className='m-2 fw-bold'>Welcome To Flow Tonecast! ✨</Typography>
                    <TextField
                        id="Username"
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        onChange={(e) => username.value = e.target.value}
                        onKeyDown={(e) => e.key === "Enter" ? lastElement.current.focus() : undefined}
                        inputRef={firstElement}
                        sx={{ width: '60%' }}
                    />
                    <TextField
                        id="Password"
                        label="Password"
                        variant="outlined"
                        type='password'
                        margin="normal"
                        fullWidth
                        onChange={(e) => password.value = e.target.value}
                        onKeyDown={(e) => e.key === "Enter" ? loginApi() : undefined}
                        inputRef={lastElement}
                        sx={{ width: '60%' }}
                    />
                    <LoadingButton
                        loading={loading.value}
                        variant="contained"
                        onClick={loginApi}
                        sx={{ mt: 2 }}
                    >
                        Login
                    </LoadingButton>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                    bgcolor="rgb(211,215,253)"
                    position="relative"
                >
                    <Box
                        component="img"
                        src={authImage}
                        alt="Authentication"
                        sx={{
                            position: 'absolute',
                            width: 218,
                            height: 224,
                            // top: '-8%',
                            left: '-8%',
                        }}
                    />
                </Box>
            </Box>
        );
    }



export default Login;
