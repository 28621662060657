import React, { useEffect } from 'react'
import CommanButton from '../../component/CommanButton'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionReportList } from '../../redux/slice/RevenueReport'
import { useSignal } from '@preact/signals-react'
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loader from '../../component/loader/Loader'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify'

export default function TransActionalReport() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token?.data?.token)
  const data = useSignal([])
  const onLoad = useSignal(false)
  const currentPage = useSignal(1)

  const monthYear = useSignal()
  const billingCycle = useSignal('')

  const getData = async (date) => {
    onLoad.value = true;
    try {
      // Fetch data using dispatch
      const response = await dispatch(getTransactionReportList({ token: token, billingDate: date }));
      console.log(response?.payload?.data?.body)

      data.value = response?.payload?.data?.body
    } catch (err) {
      console.error(err);
    } finally {
      onLoad.value = false;
    }
  };


  // useEffect(() => {
  //   getData("")
  // }, [])


  const downloadCSV = () => {
    const csvData = [
      [
        "Date",
        "Billing Cycle",
        "Market",
        "Billing Account Id",
        "Service Account Id",
        "Billing Number",
        "ShortCode Number",
        "Campaign Type",
        "Campaign Id",
        "Company Name",
        "Campaign Name",
        "Product Plan",
        "Plan Type",
        "Plan Price",
        "Factor Audio Duration",
        "Approve Date",
        "Charge Amount",
        "Date Call",
        "Destination Msisdn",
        "Message Duration",
        "Call Duration",
        "Call Status",
        "Billing System"
      ],
      ...data.value?.map(item => [
        dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value,                   // Date
        billingCycle.value,           // Billing Cycle
        item.market,            // Market
        item.billingId,         // Billing Account Id
        item.serviceId,         // Service Account Id
        item.billingMsisdn,     // Billing Number
        0,                      // ShortCode Number (static value as per your table)
        item.campaignType,      // Campaign Type
        item.campaignId,        // Campaign Id
        item.companyName,       // Company Name
        item.campaignName,      // Campaign Name
        item.packID,            // Product Plan
        item.interaction,       // Plan Type
        item.charge,            // Plan Price (if applicable)
        item.factor,            // Factor Audio Duration
        item.approveDate,       // Approve Date
        item.charge,            // Charge Amount
        item.callTime,          // Date Call
        item.msisdn,            // Destination Msisdn
        item.duration,          // Message Duration
        item.callDuration,      // Call Duration
        item.cause,             // Call Status
        item.billingSystem      // Billing System
      ])
    ].map(e => e.join(",")).join("\n");

    let cycle = 1 ;
    if (billingCycle.value === '05') {
      cycle = "01"
    } else if (billingCycle.value === '20') {
      cycle = "02"
    } else if (billingCycle.value === '30') {
      cycle = "03"
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `Tonecast_Transactional_Calls_Report_${dayjs(data.value[0]?.Date).format('YYYYMM')}_Cycle${cycle}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  const onSubmit = () => {
    if (monthYear.value === undefined) {
      toast.error("Enter month and year")
    } else if (billingCycle.value?.length === 0) {
      toast.error("Select Billing cycle")
    } else {
      getData(dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value)
    }
  }


  const perPage = 10;
  let indexofLast = currentPage.value * perPage
  let indexofFirst = indexofLast - perPage
  let activePage = data.value?.slice(indexofFirst, indexofLast)

  if (onLoad.value) {
    return (
      <Loader />
    )
  } else {
    return (
      <div className='mx-3' >
        <div className='d-flex justify-content-between my-2'>
          <h3 className='fw-bolder'>Transaction Status Reports ✨</h3>
          <CommanButton disabled={data.value?.length === 0} onClick={downloadCSV}  >Download</CommanButton>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center flex-wrap'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label={'"month" and "year"'} views={['month', 'year']} value={monthYear.value} onChange={(e) => { monthYear.value = e }} />
            </DemoContainer>
          </LocalizationProvider>
          <FormControl sx={{ mx: 2, minWidth: 250 }} >
            <InputLabel id="demo-select-small-label">Billing Cycle</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={billingCycle.value}
              label="Billing Cycle"
              onChange={(e) => { billingCycle.value = e.target.value }}

            >
              <MenuItem value={"05"}>01</MenuItem>
              <MenuItem value={"20"}>02</MenuItem>
              <MenuItem value={"30"}>03</MenuItem>
            </Select>
          </FormControl>
          <div>
            <CommanButton onClick={onSubmit} >Submit</CommanButton>
          </div>
        </div>

        {
          data.value?.length === 0 ?
            <div className='d-flex justify-content-center fw-bold mx-5' >No Data found</div>
            :
            <>
              <TableContainer >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className='bodyColor'>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Cycle</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Market</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Account Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Service Account Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing Number</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>ShortCode Number</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campaign Type</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campaign Id</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campany Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Campagin Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Product Plan</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Plan Type</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Plan Price</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Factor Audio Duration </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Approve Date</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Charge Amount</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Date Call</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Destination Msisdn</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Message Duration</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Call Duration </TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Call Status</TableCell>
                      <TableCell align="center" style={{ fontWeight: 'bolder' }}>Billing System</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="">
                    {activePage?.map((each, index) => {
                      return <ViewList key={index} data={each} date={dayjs(monthYear.value).format('YYYY-MM') + '-' + billingCycle.value} billingCycle={billingCycle.value} />
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
                {
                  data.value?.length > perPage &&
                  <div className='d-flex justify-content-center my-4'>
                    <Pagination count={Math.ceil(data.value?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                  </div>
                }
            </>
        }
      </div>
    );
  }

}



const ViewList = ({ data, date, billingCycle }) => {

  if (billingCycle === '05') {
    billingCycle = "01-05"
  } else if (billingCycle === '20') {
    billingCycle = "06-20"
  } else if (billingCycle === '30') {
    billingCycle = "21-30"
  }
  return (
    <TableRow >
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {date}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {billingCycle}
      </TableCell>
      <TableCell align="center" >
        {data.market}
      </TableCell>
      <TableCell align="center" >
        {data.billingId}
      </TableCell>
      <TableCell align="center" >
        {data.serviceId}
      </TableCell>
      <TableCell align="center" >
        {data.billingMsisdn}
      </TableCell>
      <TableCell align="center" >
        0
      </TableCell>
      <TableCell align="center" >
        {data.campaignType}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.campaignId}
      </TableCell>
      <TableCell align="center" >
        {data.companyName}
      </TableCell>
      <TableCell align="center" >
        {data.campaignName}
      </TableCell>
      <TableCell align="center" >
        {data.packID}
      </TableCell>
      <TableCell align="center" >
        {data.interaction}
      </TableCell>
      <TableCell align="center" >
        {data.charge}
      </TableCell>
      <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
        {data.factor}
      </TableCell>
      <TableCell align="center" >
        {data.approveDate}
      </TableCell>
      <TableCell align="center" >
        {data.charge}
      </TableCell>
      <TableCell align="center" >
        {data.callTime}
      </TableCell>
      <TableCell align="center" >
        {data.msisdn}
      </TableCell>
      <TableCell align="center" >
        {data.duration}
      </TableCell>
      <TableCell align="center" >
        {data.callDuration}
      </TableCell>
      <TableCell align="center" >
        {data.cause}
      </TableCell>
      <TableCell align="center" >
        {data.billingSystem}
      </TableCell>
    </TableRow>
  );
}
