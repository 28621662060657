import React from 'react';
import { Box, Grid, InputLabel, TextField, Typography } from '@mui/material';

const ContactInfo = ({ data }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Company Information
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Input title="Company name" value={data?.companyname} />
        <Input title="Tax ID" value={data?.taxid} />
        <Input title="Initiation date" value={data?.initiationdate} />
        <Input title="Billing number" value={data?.billingmsisdn} />
        <Input title="Account number" value={data?.accountnumber} />
        <Input title="Billing Id" value={data?.billingId} />
        <Input title="Service Id" value={data?.serviceId} />
      </Grid>

      <Typography variant="h6" gutterBottom>
        User Information
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Input title="Name" value={data?.username} />
        <Input title="Position" value={data?.position} />
        <Input title="Email" value={data?.email} />
        <Input title="Phone" value={data?.compphone} />
        <Input title="Ext." value={data?.ext} />
        <Input title="Mobile" value={data?.mobile} />
      </Grid>

      <Typography variant="h6" gutterBottom>
        Sales Resp.
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Input title="Name" value={data?.salesresp} />
        <Input title="Email" value={data?.salesemail} />
        <Input title="Phone" value={data?.compphone} />
        <Input title="Ext." value={data?.ext} />
        <Input title="Mobile" value={data?.salesemobile} />
      </Grid>
    </Box>
  );
};

export default ContactInfo;

export function Input({ title, value }) {
  return (
    <Grid item xs={12} sm={6} md={3}> 
      <InputLabel variant="standard" htmlFor="uncontrolled-native" sx={{color:'black',fontSize:'1em'}} >
      {title}
      </InputLabel>
      <TextField
        // label={title}
        value={value}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
        size='small'
        fullWidth
      />
    </Grid>
  );
}
