import React from 'react'
import './ErrorPage.css'

export default function ErrorPage() {
    return (
        <div id="main">
            <div class="fof">
                <h1>Error 404</h1>
                <h3>Page Not Found</h3>
            </div>
        </div>
    )
}
