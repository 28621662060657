import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import AWS from 'aws-sdk';
import { store } from './redux/store/Store'
import { Provider } from 'react-redux'
import ErrorBoundary from './component/error/ErrorBoundary';

AWS.config.update({
  region: 'us-east-1',
  credentials: new AWS.Credentials({
    accessKeyId: 'AKIASSMPCZIRU3M5UNME',
    secretAccessKey: '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'
  })
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary >
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

