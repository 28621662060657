import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../redux/store/Api';

export const getAllVendorCreditHistoryByCalculating = createAsyncThunk(
  'vendor/getAllVendorCreditHistoryByCalculating',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/getAllVendorCreditHistoryByCalculating', data.data, {
        headers: {
          'Authorization': `Bearer ${data.token}`
        }
      });
      return response; // Return only the data
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);


export const getAllVendorCreditHistory = createAsyncThunk(
    'vendor/getAllVendorCreditHistory',
    async (token, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get('/getAllVendorCreditHistory', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );


export const getAllVendorCreditHistorySpecifiedBYDate = createAsyncThunk(
    'vendor/getAllVendorCreditHistorySpecifiedBYDate',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(`/getAllVendorCreditHistorySpecifiedBYDate`, {
          params: {
            year: data.year,
            month: data.month,
            userID: data.userId
          },
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );


export const getAllVendorCreditHistoryByCalculatingForTHreeMOnths = createAsyncThunk(
    'vendor/getAllVendorCreditHistoryByCalculatingForTHreeMOnths',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post('/getAllVendorCreditHistoryByCalculatingForTHreeMOnths', data.data, {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );