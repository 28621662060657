import React from 'react';
import OuterBox from '../../../component/OuterBox';
import ProgressBar from '../../../component/progressBar/ProgressBar';
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep';
import { toast } from 'react-toastify';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSignal } from '@preact/signals-react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommanButton from '../../../component/CommanButton';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { getAdList, modifyAdRbt } from '../../../redux/slice/ListAd';



const UPCAdRbt = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const getData = useSignal(location.state?.data)
    const dispatch = useDispatch();
    const audioFile = useSignal(null)
    const upgrade = useSignal(null)
    const scheduleFrom = useSignal(null)
    const scheduleTo = useSignal(null)
    const numberOfSubscriber = useSignal(0)
    const p1 = useSignal('active')
    const p2 = useSignal('active')
    // console.log(getData.value)

    let getProfileData = useSelector(state => state.profile.data)
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)

    const onSubmitHandler = () => {
        // Your data validation logic here
        let formData = null;
        if ((p1.value && p2.value === 'completed') && (numberOfSubscriber.value !== 0)) {

            let startDate = `${scheduleFrom.value.$y}-${((scheduleFrom.value.$M + 1 + '').length) === 1 ? "0" + (scheduleFrom.value.$M + 1 + '') : (scheduleFrom.value.$M + 1 + '')}-${((scheduleFrom.value.$D + '').length) === 1 ? "0" + (scheduleFrom.value.$D + '') : (scheduleFrom.value.$D + '')}` + ` ${((scheduleFrom.value.$H + '').length) === 1 ? "0" + (scheduleFrom.value.$H + '') : (scheduleFrom.value.$H + '')}:${((scheduleFrom.value.$m + '').length) === 1 ? "0" + (scheduleFrom.value.$m + '') : (scheduleFrom.value.$m + '')}:00`

            let endDate = `${scheduleTo.value.$y}-${((scheduleTo.value.$M + 1 + '').length) === 1 ? "0" + (scheduleTo.value.$M + 1 + '') : (scheduleTo.value.$M + 1 + '')}-${((scheduleTo.value.$D + '').length) === 1 ? "0" + (scheduleTo.value.$D + '') : (scheduleTo.value.$D + '')}` + ` ${((scheduleTo.value.$H + '').length) === 1 ? "0" + (scheduleTo.value.$H + '') : (scheduleTo.value.$H + '')}:${((scheduleTo.value.$m + '').length) === 1 ? "0" + (scheduleTo.value.$m + '') : (scheduleTo.value.$m + '')}:00`

            let data = {
                templatename: getData.value?.templatename,
                expectedsubscribers: numberOfSubscriber.value,
                startdate: startDate,
                enddate: endDate
            }


            formData = new FormData();
            if (upgrade.value === "yes") {
                formData.append('filewav', audioFile.value);
            } else if (upgrade.value === "no") {
                formData.append('filewav', null);
            }
            formData.append('addRbt', JSON.stringify(data))

            let send = {
                data: formData,
                id: getData.value?.templateID,
                token: token
            }

            dispatch(modifyAdRbt(send)).then((resp) => {
                // console.log(resp)
                if (resp?.payload?.data?.httpStatusCode === 200) {
                    dispatch(getAdList({ token: token, id: userId }))
                    navigate('/successtemp', { state: { temptype: 'Ad-RBT', mode: 're-submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewAdRbt' } })
                }else{
                    toast.error("Internal server error")
                }
            })
        } else if (numberOfSubscriber.value === 0) {
            toast.error("Please enter the number of subscriber")
        }

    };

    return (
        <>
            <ProgressBar>
                <ProgressBarStep value="1" mode={p1.value} />
                <ProgressBarStep value="2" mode={p2.value} />
            </ProgressBar>

            <div className='container-fluid m-2 '>
                <div className="d-flex justify-content-between my-2 mx-4">
                    <div className='mb-4 border border-secondary p-3 rounded w-75 ' style={{ border: '1px solid', }}>
                        <div className='fw-bold fs-5'>Service : Ad-RBT Campaign</div>
                        <div className='fw-bold fs-5'>Selected Campaign : {getData.value?.templatename}</div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/campaign/viewAdRbt">
                            <CommanButton ><ArrowBackIosIcon />Back</CommanButton>
                        </Link>
                    </div>
                </div>

                <OuterBox value="1" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="fw-semibold formLabel ">Update your subscribers list.</label>
                        <span className="form-text"></span>
                        <div className='d-flex flex-column align-items-center'>
                            <div>
                                <div className='form-check d-flex align-items-center '>
                                    <input className="form-check-input mx-2 border-secondary my-2" type="radio" name="upgrade" id="upgrade" value="no" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'completed' }} />
                                    <label className="form-check-label mx-2" htmlFor="">
                                        I will use the exact same list
                                    </label>
                                </div>
                                <div className='d-flex form-check flex-wrap  mt-3'>
                                    <input className="form-check-input mx-2 border-secondary" type="radio" name="upgrade" id="upgrade" value="yes" onChange={(e) => { upgrade.value = e.target.value; p1.value = 'active' }} />
                                    <label className="form-check-label mx-2" >
                                        I want to update my list
                                    </label>
                                </div>

                            </div>
                            {
                                upgrade.value === 'yes' &&
                                <div className='my-4 d-flex '>
                                    <div>
                                        <input type="file" accept='.txt' className="form-control border-secondary" id="audioFile" onChange={(e) => { (e.target.files[0].size < 2000000) ? audioFile.value = e.target.files[0] : toast.error("file size") }} disabled={upgrade.value === 'no' ? true : false} />
                                        <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .txt file(maxsize-2mb)</label>
                                    </div>
                                    <div className='mx-2'>
                                        <CommanButton type='submit' text='Submit' disabled={upgrade.value === 'no' ? true : false} onClick={() => { audioFile.value === null ? toast.error('Please select audio file') : p1.value = 'completed' }} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="2" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel ">Re-schedule.
                            <span className="form-text">Set up days and time at which your Ad-RBT will run</span>
                        </label>
                        <div className="d-flex justify-content-center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker', 'DatePicker']} >
                                    <DatePicker
                                        disabled={p1.value === 'active' ? true : false}
                                        label="From" className='mx-2' value={scheduleFrom.value} onChange={(e) => { scheduleFrom.value = e; p2.value = 'active' }} minDate={dayjs(new Date())} minTime={dayjs('0000-00-00T07:00')} maxTime={dayjs('0000-00-00T19:00')}
                                    />
                                    <DatePicker
                                        disabled={(scheduleFrom.value === undefined ? true : false) || (p1.value === 'active' ? true : false)}
                                        label="To" className='mx-2' value={scheduleTo.value} onChange={(e) => { scheduleTo.value = e; p2.value = 'completed' }} minDate={scheduleFrom.value} minTime={scheduleFrom.value} maxTime={dayjs('0000-00-00T19:00')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>
                </OuterBox>

                <div className='row g-3 justify-content-center my-2'>
                    <strong className='col-auto'>With your current credit limit you will be able to reach</strong>
                    <div className="col-auto w-25">
                        <Tooltip title={'You have US $' + getProfileData?.availbalance + ' available balance and US $1 per subscriber. (i.e. ' + getProfileData?.availbalance + ' * 1 = ' + getProfileData.availbalance * 1 + ')'}>
                            <input type="number" className="form-control border-secondary" id="subs" onChange={(e) => { numberOfSubscriber.value = e.target.value }} value={numberOfSubscriber.value} placeholder='Type max. expected no. of subs' disabled={p2.value === 'active' ? true : false} />
                        </Tooltip>
                    </div>
                    <strong className='col-auto'>subs</strong>
                </div>
            </div>

            <div className="d-flex justify-content-center my-4">
                <CommanButton text="SEND TEMPLATE" onClick={onSubmitHandler} />

            </div>
        </>
    );
};

export default UPCAdRbt;
































// import React, { Component } from 'react'
// import OuterBox from '../../../component/OuterBox'
// import { minDateTime } from '../../../common/MinDate'
// import ProgressBar from '../../../component/progressBar/ProgressBar'
// import ProgressBarStep from '../../../component/progressBar/ProgressBarStep'
// import { Button } from 'reactstrap'
// import { toast } from 'react-toastify'
// import { Link, Navigate } from 'react-router-dom'
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import axios from 'axios'
// import CommanButton from '../../../component/CommanButton'



// export class UPCAdRbt extends Component {

//     constructor(props) {
//         super(props)

//         this.state = {
//             audioFile: null,
//             upgrade: '',
//             scheduleFrom: '',
//             scheduleTo: '',
//             subs: '',
//             getData: [],
//             p1: "active",
//             p2: "active",
//             p3: "active",
//             crdlmt: "",
//             availblnc: "",
//             status: ''
//         }
//     }

//     onChangeHandler = (event) => {
//         this.setState({
//             [event.target.id]: event.target.value
//         }, () => {
//             if ((event.target.id === 'upgrade') && (event.target.value === 'no')) {
//                 this.fileValidation()
//             }
//             if ((event.target.id === 'upgrade') && (event.target.value === 'yes')) {
//                 this.setState({
//                     p2: "active",
//                 })
//             }
//             if (event.target.id === 'scheduleTo') {
//                 this.scheduleValidation()
//             }
//         })
//     }

//     onFileHandler = (event) => {
//         this.setState({
//             audioFile: event.target.files[0]
//         })
//     }


//     fileValidation = () => {
//         let flag = false
//         if (this.state.upgrade === 'yes') {
//             if (this.state.audioFile !== null) {
//                 this.setState({
//                     p1: 'completed'
//                 })
//                 flag = true
//             } else {
//                 this.setState({
//                     p1: 'active'
//                 })
//                 flag = false
//                 toast.error("Please upload your file")
//             }
//         } else if (this.state.upgrade === 'no') {
//             this.setState({
//                 p1: 'completed'
//             })
//             flag = true
//         } else {
//             this.setState({
//                 p1: 'active'
//             })
//             flag = false
//             toast.error("Please select the list")
//         }
//         return flag


//     }

//     scheduleValidation = () => {
//         let flag = false
//         if (this.state.scheduleFrom.length && this.state.scheduleTo.length !== 0) {
//             this.setState({
//                 p2: 'completed'
//             })
//             flag = true
//         } else {
//             this.setState({
//                 p2: 'active'
//             })
//             flag = false
//             toast.error("Please enter schedule timing")
//         }
//         return flag
//     }

//     susbValidation = () => {
//         let flag = true
//         if (this.state.subs.length === 0) {
//             toast.error("Please enter the expected subscriber")
//         }
//         return flag
//     }


//     lastValidation = () => {
//         let v1 = this.fileValidation();
//         let v2 = this.scheduleValidation();
//         let v3 = this.susbValidation();

//         console.log(v1, v2, v3)
//         return new Promise((resolve, reject) => {
//             if (v1 && v2 && v3) {
//                 resolve()
//             } else {
//                 reject("enter detail in above field")
//             }
//         })
//     }

//     dataValidation = () => {
//         let formData = null;
//         let data = {
//             templatename: this.state.getData.templatename,
//             startdate: this.state.scheduleFrom,
//             enddate: this.state.scheduleTo,
//             expectedsubscribers: this.state.subs
//         }

//         if ((this.state.p1 && this.state.p2 === 'completed') && (this.state.subs.length !== 0)) {

//             if (this.state.upgrade === "yes") {
//                 formData = new FormData();
//                 formData.append('filewav', this.state.audioFile);
//                 formData.append('addRbt', JSON.stringify(data))
//             } else if (this.state.upgrade === "no") {
//                 formData = new FormData();
//                 formData.append('filewav', null);
//                 formData.append('addRbt', JSON.stringify(data))
//             }
//         }


//         return new Promise((resolve, reject) => {
//             if (formData !== null) {
//                 resolve(formData)
//             } else {
//                 reject("formData is null")
//             }
//         })
//     }

//     modifyAd = (data, id) => {
//         let token = sessionStorage.getItem('Bearer');
//         return axios({
//             method: 'put',
//             url: 'http://192.168.1.18:8888/updateAddRbt/' + id,
//             data: data,
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },
//         })
//     }

//     onSubmitHandler = () => {
//         this.lastValidation()
//             .then(() => {
//                 return this.dataValidation()
//             }).then((data) => {
//                 return this.modifyAd(data, this.state.getData.templateID)
//             }).then((result) => {
//                 this.setState({
//                     result: result,
//                     status: result.status
//                 })
//             }).catch((err) => {
//                 console.log(err)
//             })
//     }

//     componentDidMount = () => {
//         const { location } = this.props;
//         console.log(location)
//         this.setState({
//             getData: location
//         })

//     }

//     render() {
//         return (
//             <>
//                 {
//                     this.state.status === 200
//                         ?
//                         <Navigate to='/successPage' />
//                         :
//                         undefined
//                 }

//                 <ProgressBar>
//                     <ProgressBarStep value="1" mode={this.state.p1} />
//                     <ProgressBarStep value="2" mode={this.state.p2} />
//                 </ProgressBar>
//                 {/* <div className='container-fluid m-2 '>

//                     <div className="d-flex justify-content-between my-2 mx-4">
//                         <div className='mb-4 border border-secondary p-3 rounded w-75 ' style={{ border: '1px solid', }}>
//                             <div className='fw-bold fs-5'>Service: Ad-RBT</div>
//                             <div className='fw-bold fs-5'>Selected Campaign: {this.state.getData.templatename}</div>
//                         </div>

//                         <div className="d-flex justify-content-center">
//                             <Link to="/createCampaign/adRbt">
//                                 <CommanButton type="submit" className="btnBack mb-3">
//                                     <ArrowBackIosIcon />Back
//                                 </CommanButton>
//                             </Link>
//                         </div>
//                     </div>



//                     <OuterBox value="1" >
//                         <div className="my-2 w-100" >
//                             <label htmlFor="" className=" fw-semibold formLabel ">Update your subscribers list.</label>
//                             <span className="form-text"></span>
//                             <div className='d-flex flex-column align-items-center'>
//                                 <div>
//                                     <div className='form-check '>
//                                         <input className="form-check-input m-2 border-secondary" type="radio" name="upgrade" id="upgrade" value="no" onChange={this.onChangeHandler} />
//                                         <label className="form-check-label m-2" htmlFor="">
//                                             I will use the exact same list
//                                         </label>
//                                     </div>
//                                     <div className='d-flex form-check flex-wrap'>
//                                         <input className="form-check-input m-2 border-secondary" type="radio" name="upgrade" id="upgrade" value="yes" onChange={this.onChangeHandler} />
//                                         <label className="form-check-label m-2" htmlFor="">
//                                             I want to update my list
//                                         </label>
//                                         <div className="m-2">
//                                             <input type="file" accept='.wav' className="form-control border-secondary" id="audioFile" onChange={this.onFileHandler} disabled={this.state.upgrade === 'no' ? true : false} />
//                                             <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
//                                         </div>
//                                         <div className="m-2">
//                                             <button type="submit" className="btn btnBack mb-3" onClick={this.fileValidation} disabled={this.state.upgrade === 'no' ? true : false} >Submit</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </OuterBox>


//                     <OuterBox value="2" >
//                         <div className="my-2 w-100" >
//                             <label htmlFor="" className="form-label fw-semibold formLabel ">Re -schedule.
//                                 <span className="form-text">Set up days and time at which your Ad-RBT will run</span></label>
//                             <div className="row g-4 justify-content-center">

//                                 <div className="col-auto d-flex align-items-center">
//                                     <label htmlFor="scheduleFrom" className="mx-2">from</label>
//                                     <input type="datetime-local" min={minDateTime("")} className="border-secondary-subtle p-2 mx-1 rounded" id="scheduleFrom" onChange={this.onChangeHandler} />
//                                 </div>
//                                 <div className="col-auto d-flex align-items-center">
//                                     <label htmlFor="scheduleTo" className="mx-2">to</label>
//                                     <input type="datetime-local" min={minDateTime(this.state.scheduleFrom)} className="border-secondary-subtle p-2 mx-1 rounded" id="scheduleTo" onChange={this.onChangeHandler} disabled={this.state.scheduleFrom.length === 0 ? true : false} />
//                                 </div>
//                             </div>
//                         </div>
//                     </OuterBox>

//                     <div className='row g-3 justify-content-center my-2'>
//                         <strong className='col-auto'>With your current credit limit you will be able to reach</strong>
//                         <div className="col-auto w-25">
//                             <input type="text" className="form-control border-secondary" id="subs" onChange={this.onChangeHandler} />
//                         </div>
//                         <strong className='col-auto'>subs</strong>
//                     </div>
//                 </div> */}
//                 <div className="d-flex justify-content-center my-4">
//                     <CommanButton type="submit" className="btnBack mb-3" onClick={this.onSubmitHandler} >SEND TEMPLATE</CommanButton >
//                 </div>
//             </>
//         )
//     }
// }



// export default UPCAdRbt
