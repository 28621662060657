import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api"; // Import your configured axios instance
import { toast } from 'react-toastify';

const initialState = {
    data: null,
    isLoading: false,
    error: null
};

// Async thunk to get PVM report by specific ID
export const getPvmReportBySpecificId = createAsyncThunk(
  "reportPvm/getPvmReportBySpecificId",
  async ({ id, token }) => {
    try {
      const response = await axiosInstance.get(`/CampaignConfiguration/getCampaignById/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      return response.data; // Return the response data directly
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || "An unexpected error occurred.");
      }
      return Promise.reject(error.message || "An error occurred"); // Properly reject with the error message
    }
  }
);

// Slice to manage PVM report state
export const reportPvmBySpecificIdSlice = createSlice({
    name: 'reportPvmBySpecificId',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(getPvmReportBySpecificId.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getPvmReportBySpecificId.fulfilled, (state, action) => {
            state.data = action.payload?.body[0]
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getPvmReportBySpecificId.rejected, (state, action) => {
            state.error = action.error.message; // Capture the error message
            state.isLoading = false;
          });
    }
});

export default reportPvmBySpecificIdSlice.reducer;
