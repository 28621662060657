import React, { Component } from 'react'
import './ProgressBar.css'

export class ProgressBar extends Component {
    render() {
        return (
            <div className='container' style={{width:'50%',  backgroundColor:'white',   }}>
                <div className="stepper-wrapper">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default ProgressBar