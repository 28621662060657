import { Button } from '@mui/material';

export default function CommanButton({ children, text, onClick, type, disabled , ...props }) {

    return (
        <Button
            onClick={onClick}
            type={type}
            disabled={disabled}
            sx={{
                borderRadius: 1,
                marginLeft: 2,
                marginY: 1,
                fontWeight: 'bold',
            }}
            variant='contained'
            size='small'
            {...props}
        >
            {children}
            {text}
        </Button>
    );
}
