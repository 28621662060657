import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CampaignIcon from '@mui/icons-material/Campaign';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link, Navigate, Outlet } from 'react-router-dom';
import HeaderComponent from '../header/HeaderComponent';
import { signal } from '@preact/signals-react';
import CreditBarComponent from '../creditLimit/CreditBarComponent';
import logo from '../../images/CW.png'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactlessIcon from '@mui/icons-material/Contactless';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


const dark = {
    sidebar: {
        backgroundColor: '#0084dd',
        color: '#ffffff',
    },
    menu: {
        menuContent: '#0084dd',
        icon: '#59d0ff',
        hover: {
            backgroundColor: '#0084dd',
            color: '#C8C8C8',
        },
        disabled: {
            color: '#3e5e7e',
        }
    },
}

const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const collapsed = signal(false)
const toggled = signal(false)



let navigate = null;

export const setNavigate = (nav) => {
    navigate = nav;
};

export const getNavigate = () => navigate;


const Playground = () => {
    let token = sessionStorage.getItem('Token')
    const roleId = useSelector(state => state.vendor?.data?.roleId)


    // useEffect(() => {
    //     window.addEventListener("resize", () => {window.innerWidth <  610 ? collapsed.value = true : collapsed.value = false});
    // }, [])

    const menuItemStyles = {
        root: {
            // fontSize: '16px',
            // fontWeight: 600,
        },
        icon: {
            color: dark.menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: dark.menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? hexToRgba(dark.menu.menuContent, 1) : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: dark.menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(dark.menu.hover.backgroundColor, 1),
                color: dark.menu.hover.color,
            }
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };

    if (token === null) {
        return <Navigate to="/login" />
    }



    return (
        <div style={{ display: 'flex', height: '100%' }} className='user-select-none'>
            <Sidebar
                collapsed={collapsed.value}
                transitionDuration="600"
                toggled={toggled.value}
                onBackdropClick={() => toggled.value = false}
                breakPoint="md"
                backgroundColor={hexToRgba(dark.sidebar.backgroundColor, 1)}
                rootStyles={{
                    color: dark.sidebar.color,
                }}
                style={{ height: '100vh', position: 'sticky', top: '0', left: "0", zIndex:12 }}
            >

                <div className='' style={{background:'none'}} >
                    <img src={logo} alt="Flow logo"  sizes='8em' width='100%' height='50%'/>
                </div>
                <div className='mx-2 mb-3 p-3 d-flex align-items-end justify-content-center fs-3 fw-bold rounded' >
                    <button id="collapse"
                        onClick={() => { collapsed.value = !collapsed.value }} style={{ border: 0, background: 'transparent', color: 'white', display: 'flex', alignItems: 'center' }}><MenuOutlinedIcon />
                        {
                            !collapsed.value &&
                            <div className='mx-3 fw-bold' >
                                TONECAST
                            </div>
                        }
                    </button>


                </div>
                <Menu menuItemStyles={menuItemStyles} closeOnClick >
                    <SubMenu label="My Profile" icon={<ContactPageIcon style={{ color: 'white' }} />} component={<Link to='/' />} >
                        <MenuItem icon={<ReceiptIcon style={{ color: 'white' }} />} component={<Link to='/creditHistory' />} >Spending History</MenuItem>

                    </SubMenu>
                    <SubMenu label="Campaign Manager" icon={<EditNoteIcon style={{ color: 'white' }} />}  >
                        <MenuItem icon={<VoiceChatIcon style={{ color: 'white' }} />}
                            component={<Link to='/campaign/viewpvm' />}
                        >PVM</MenuItem>
                        <MenuItem icon={<CampaignIcon style={{ color: 'white' }} />}
                            component={roleId === 1 && <Link to='/campaign/viewAdRbt' />}
                            onClick={() => { roleId !== 1 && toast.info('This Features will be coming soon') }}
                        >Ad-RBT</MenuItem>
                        <MenuItem icon={<CorporateFareIcon style={{ color: 'white' }} />} component={<Link to='/campaign/viewCorpRbt' />} >Corp-RBT</MenuItem>
                    </SubMenu>
                    <SubMenu label="Reporting" icon={<AssessmentIcon style={{ color: 'white' }} />} >
                        <MenuItem icon={<VoiceChatIcon style={{ color: 'white' }} />}
                            component={<Link to='/pvmReport' />}
                        >PVM-Report</MenuItem>
                        <MenuItem icon={<CorporateFareIcon style={{ color: 'white' }} />}
                            component={roleId === 1 && <Link to='/adReport' />}
                            onClick={() => { roleId !== 1 && toast.info('This Features will be coming soon') }}
                        >Ad-Report</MenuItem>
                        <MenuItem icon={<CampaignIcon style={{ color: 'white' }} />}
                            component={<Link to='/corpReport' />} >Corp-Report</MenuItem>
                        {
                            roleId === 1 &&
                            <>
                                <MenuItem icon={<AttachMoneyIcon style={{ color: 'white' }} />}
                                    component={<Link to='/revenueReport' />} >Revenue Report</MenuItem>
                                <MenuItem icon={<ContactlessIcon style={{ color: 'white' }} />}
                                    component={<Link to='/transactionalReport' />} >TransActional Calls Report</MenuItem>
                                <MenuItem icon={<CampaignIcon style={{ color: 'white' }} />}
                                    component={<Link to='/campaignReport' />} >Campaign Report</MenuItem>
                                <MenuItem icon={<AccountBalanceIcon style={{ color: 'white' }} />}
                                    component={<Link to='/AccountStatusReport' />} >Account Status Report</MenuItem>

                            </>

                        }
                    </SubMenu>
                    <MenuItem icon={<AutoStoriesIcon style={{ color: 'white' }} />} component={<Link to='/tutorial' />} >Tutorials</MenuItem>
                </Menu>
            </Sidebar>
            <main className='w-100 container-fluid overflow-hidden'  >
                <HeaderComponent />
                <CreditBarComponent />
                <Outlet />

            </main>
        </div>
    );
};

export default Playground;
