import React from 'react';
import { TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from 'react-redux';
import { getMsisdnDetailsByCampaignId } from '../../../redux/slice/PvmSummaryReports';
import { useSignal } from '@preact/signals-react';
import { toast } from 'react-toastify';
import BackDropLoader from '../../../component/loader/BackDropLoader';


function PvmReportListData(props) {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token?.data?.token)
    const loading = useSignal(false)

    const downloadCSV = async (id, date) => {
        loading.value = true
        dispatch(getMsisdnDetailsByCampaignId({ 'token': token, 'campId': id, 'date': date }))
            .then((resp) => {
                console.log(resp)
                if (resp?.payload?.status === 200) {
                    let data = resp?.payload?.data?.body

                    if(data?.length === 0){
                        toast.error("Data not found")
                        loading.value = false
                        return
                    }

                    const csvData = [
                        ['Msisdn', 'Cause',],
                        ...data.map(item => [item.msisdn, item.cause])
                    ].map(e => e.join(",")).join("\n");

                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.setAttribute('hidden', '');
                    a.setAttribute('href', url);
                    a.setAttribute('download', `Campaign_${id}_${date}.csv`);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    loading.value = false
                } else {
                    toast.error("Data not found")
                }
                loading.value = false
            }).catch(() => {
                toast.error("Error while loading")
                loading.value = false
            })


    };

    return (
        <>
            <TableBody key={props.key}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className='reportsList' >

                    <TableCell component="th" scope="row" align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.date?.slice(0, 10)} </TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{props.totalNoOfCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{props.completedCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.unansweredCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{props.interruptedCalls}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>{props.invalidNumbers}</TableCell>

                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        {props.interactions}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: '500', fontSize: '12px', height: '4em' }}>
                        <Tooltip title="Download per day csv file" >
                            <button onClick={() => { downloadCSV(props?.campId, props.date?.slice(0, 10)) }} style={{ border: 'none', textDecoration: 'underLine', color: '#6366f1', fontWeight: '700', backgroundColor: 'transparent' }}>
                                <DownloadIcon />
                            </button>
                        </Tooltip>
                        <BackDropLoader opener={loading.value} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </>
    );
}

export default PvmReportListData;