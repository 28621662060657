import { toast } from "react-toastify";

let MinDate = (data) => {


    let currentDate = null;
    if (data.length === 0) {
        currentDate = new Date()
    }else{
        currentDate = new Date(data)
    }

    if (currentDate.getDay() === 0) {
        toast.error("This Platform does not make any call on sunday")
    }

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export let minDateTime = (data) => {
    let date = null

    if (data.length === 0) {
        date = new Date()
    }else{
        date = new Date(data)
    }
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate
}



export default MinDate