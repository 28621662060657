import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../store/Api'; // Ensure this path is correct
import { toast } from 'react-toastify';

export const  SendMail = createAsyncThunk(
  'mail/sendMail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/sendMail', data.data, {
        headers: {
          'Authorization': `Bearer ${data.token}`
        }
      });
      return response; // Return only the data from the response
    } catch (error) {
      // Handle specific status codes if needed
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      return rejectWithValue(error.message || 'An error occurred'); // Use rejectWithValue to handle errors in the slice
    }
  }
);
