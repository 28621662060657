import React, { useEffect } from 'react'
import ProgressBar from '../../../component/progressBar/ProgressBar'
import ProgressBarStep from '../../../component/progressBar/ProgressBarStep'
import { useSignal } from '@preact/signals-react'
import { Link, useNavigate } from 'react-router-dom'
import CommanButton from '../../../component/CommanButton'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OuterBox from '../../../component/OuterBox'
import { Modal } from 'react-bootstrap'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { createAd, getAdList, getAllTemplatesList } from '../../../redux/slice/ListAd'
import BackDropLoader from '../../../component/loader/BackDropLoader'


const toastID = 'AddAdRbt'

export default function AddAdRBT() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const p1 = useSignal('active')
    const p2 = useSignal('active')
    const p3 = useSignal('active')
    const p4 = useSignal('active')
    const p5 = useSignal('active')
    const p6 = useSignal('active')
    const helpModal = useSignal(false)
    const typeIVR = useSignal('general')
    const templateName = useSignal('')
    const numberOfSubscriber = useSignal()
    const audioFile = useSignal()
    const audioPlayStatus = useSignal({ status: false, filePlay: null })
    const scheduleFrom = useSignal()
    const scheduleTo = useSignal()
    const premiumReport = useSignal(null)
    const reviewCheck = useSignal(false)
    const existTempName = useSignal([])
    const loading = useSignal(true)

    let getProfileData = useSelector(state => state.profile.data)
    const token = useSelector(state => state.token?.data?.token)
    const userId = useSelector(state => state.vendor?.data?.user_id)

    useEffect(() => {
        // loading.value = true
        dispatch(getAllTemplatesList(token))
            .then((resp) => {
                if (resp.payload?.data?.httpStatusCode === 200) {
                    existTempName.value = resp.payload?.data?.body
                }
                loading.value = false
            }).catch(() => {
                loading.value = false
            })
    }, [])


    const audioPlay = () => {
        const audioURL = URL.createObjectURL(audioFile.value);

        if (audioPlayStatus.value.status) {
            audioPlayStatus.value.filePlay.pause();
            audioPlayStatus.value = { status: false, filePlay: audioPlayStatus.value.filePlay };
        } else {
            if (audioPlayStatus.value.filePlay) {
                audioPlayStatus.value.filePlay.play();
                audioPlayStatus.value = { status: true, filePlay: audioPlayStatus.value.filePlay };
            } else {
                const newAudio = new Audio(audioURL);

                newAudio.addEventListener('ended', () => {
                    audioPlayStatus.value = { status: false, filePlay: null };
                    // console.log('Audio playback completed');
                });

                newAudio.play();
                audioPlayStatus.value = { status: true, filePlay: newAudio };
            }
        }
    }


    const ivrTypeValidation = () => {
        if (typeIVR.value === 'general') {
            p1.value = 'completed'
        } else {
            p1.value = 'active'
            navigate('/success', { state: { mailBody: 'Requesting to short code for IVR', sendMail: getProfileData?.salesresp, subBody: 'You will get an update within 2-3 weeks on your registered email address', url: '/campaign/addAdRbt' } })
        }
    }


    const nameAndSubscriberValidation = () => {
        if (templateName.value.length === 0) {
            toast.error('Must enter the name', { toastId: toastID })
            p2.value = 'active'
        } else if ((numberOfSubscriber.value === 0) || (numberOfSubscriber.value === undefined)) {
            toast.error('Must enter the number of subscriber', { toastId: toastID })
            p2.value = 'active'
        } else if (numberOfSubscriber > parseFloat(getProfileData?.availbalance)) {
            p2.value = 'active'
            toast.error('Credit Limit is not enough. ' + `You have US $ ${getProfileData?.availbalance} available balance and US $1 per subscriber. (i.e. ${getProfileData?.availbalance} * 1 = ${getProfileData.availbalance * 1} )`, { toastId: toastID })
        } else {
            const foundTemplate = existTempName.value?.find(element => element.toLowerCase() === templateName.value?.toLowerCase().trim());
            if (foundTemplate !== undefined) {
                p2.value = 'active'
                toast.error('Template name already exist', { toastId: toastID })
            } else {
                p2.value = 'completed'
            }
        }

    }

    const audioFileValidation = () => {
        if (audioFile.value === undefined) {
            toast.error('Must select the .wav file', { toastId: toastID })
            p3.value = 'active'
        } else {
            p3.value = 'completed'
        }
    }

    if (premiumReport.value === null) {
        p5.value = 'active'
    } else {
        p5.value = 'completed'
    }

    if (reviewCheck.value) {
        p6.value = 'completed'
    } else {
        p6.value = 'active'
    }


    const sendHit = () => {
        let formData = null;
        loading.value = true

        if (p1.value && p2.value && p3.value && p4.value && p5.value && p6.value === 'completed') {

            let startDate = `${scheduleFrom.value.$y}-${((scheduleFrom.value.$M + 1 + '').length) === 1 ? "0" + (scheduleFrom.value.$M + 1 + '') : (scheduleFrom.value.$M + 1 + '')}-${((scheduleFrom.value.$D + '').length) === 1 ? "0" + (scheduleFrom.value.$D + '') : (scheduleFrom.value.$D + '')}` + ` ${((scheduleFrom.value.$H + '').length) === 1 ? "0" + (scheduleFrom.value.$H + '') : (scheduleFrom.value.$H + '')}:${((scheduleFrom.value.$m + '').length) === 1 ? "0" + (scheduleFrom.value.$m + '') : (scheduleFrom.value.$m + '')}:00`

            let endDate = `${scheduleTo.value.$y}-${((scheduleTo.value.$M + 1 + '').length) === 1 ? "0" + (scheduleTo.value.$M + 1 + '') : (scheduleTo.value.$M + 1 + '')}-${((scheduleTo.value.$D + '').length) === 1 ? "0" + (scheduleTo.value.$D + '') : (scheduleTo.value.$D + '')}` + ` ${((scheduleTo.value.$H + '').length) === 1 ? "0" + (scheduleTo.value.$H + '') : (scheduleTo.value.$H + '')}:${((scheduleTo.value.$m + '').length) === 1 ? "0" + (scheduleTo.value.$m + '') : (scheduleTo.value.$m + '')}:00`

            formData = new FormData();

            let data =
            {
                "templatename": templateName.value,
                "expectedsubscribers": numberOfSubscriber.value,
                "startdate": startDate,
                "enddate": endDate,
                "dayschedular": "",
                "codetwo": "",
                "codethree": "",
                "codeone": "",
                "attendmessage": "",
                "voicetone": "",
                "voicespeed": ""
            }



            formData.append('filetxt', null);
            formData.append('filewav', audioFile.value)
            formData.append('profilewav', null)
            formData.append('addRbt', JSON.stringify(data))
        }


        if (formData !== null) {
            dispatch(createAd({ token: token, data: formData, id: userId })).then((resp) => {
                // console.log(resp)
                if (resp?.payload?.data?.httpStatusCode === 200) {

                    dispatch(getAdList({ token: token, id: userId }))
                    navigate('/successtemp', { state: { temptype: 'Ad-RBT', mode: 'submitted', body: 'You campaign will run in 24 hours', url: '/campaign/viewAdRbt' } })
                } else {
                    toast.error("Internal server error", { toastId: toastID })
                }
                loading.value = false
            })
        } else {
            loading.value = false
            toast.error("Please enter the details", { toastId: toastID })
        }

    }




    return (
        <div className='mx-3'>

            <div >
                <ProgressBar>
                    <ProgressBarStep value="1" mode={p1.value} />
                    <ProgressBarStep value="2" mode={p2.value} />
                    <ProgressBarStep value="3" mode={p3.value} />
                    <ProgressBarStep value="4" mode={p4.value} />
                    <ProgressBarStep value="5" mode={p5.value} />
                    <ProgressBarStep value="6" mode={p6.value} />
                </ProgressBar>
            </div>
            <div className='d-flex justify-content-between my-2 mx-2'>
                <h3 className='fw-bolder'>Create Ad-RBT campaign ✨</h3>
                <Link to='/campaign/viewAdRbt' style={{ textDecoration: 'none' }}>
                    <CommanButton type="submit" className="mb-3" ><ArrowBackIosIcon />Back</CommanButton>
                </Link>
            </div>
            <div className=''>
                <OuterBox value="1" able="false" >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel ">Select your IVR option &nbsp;
                            <span className="form-text">Please push one of the buttons</span></label>
                        <div className="row g-4 justify-content-center">

                            <div className="col-auto">
                                <select className="form-select border-secondary" id="IVR" value={typeIVR.value} onChange={(e) => { typeIVR.value = e.target.value; p1.value = 'active' }} >
                                    <option defaultChecked value="general">General short code </option>
                                    <option value="private">Private short code</option>
                                </select>

                            </div>
                            <div className="col-auto">
                                <CommanButton
                                    type="submit"
                                    className="btnBack mb-3"
                                    onClick={ivrTypeValidation}
                                >Submit</CommanButton>
                            </div>
                            <div className="col-auto">
                                <Tooltip title='help' placement='top' enterTouchDelay={0} >
                                    <IconButton onClick={() => { helpModal.value = true }}>
                                        <HelpOutlineSharpIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Modal
                                show={helpModal.value}
                                onHide={() => helpModal.value = !helpModal.value}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        IVR Help
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Your subscriber will have access to a general menu among other brands. If you select “private auto attendant” Your brand gets a private short code with a customized auto attendant. This process might take between 2-3 weeks and you will be charged US$ 250.
                                    </p>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </OuterBox>



                <OuterBox value="2" able={p1.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel ">Name your Ad-RBT campaign. &nbsp;
                            <span className="form-text">This name identifies your Ad-RBT campaign so you can re-use it in the future. Type your template name below. Your potential number of subscribers are calculated based on your credit limit</span></label>
                        <div className="row g-4 justify-content-center">

                            <div className="col-auto">
                                <input type="text" className="form-control border-secondary" id="templateName" maxLength={15} onChange={(e) => { templateName.value = e.target.value }} value={templateName.value} placeholder='Type your Ad-RBT name here' disabled={p1.value === 'active' ? true : false} />
                            </div>
                            <div className="col-auto d-flex flex-col">
                                <div>
                                    <Tooltip title={'You have US $' + getProfileData?.availbalance + ' available balance and US $1 per subscriber. (i.e. ' + getProfileData?.availbalance + ' * 1 = ' + getProfileData.availbalance * 1 + ')'}>
                                        <input type="number" className="form-control border-secondary" id="subs"  maxLength={7} onChange={(e) => { numberOfSubscriber.value = e.target.value }} value={numberOfSubscriber.value} placeholder='Type max. expected no. of subs' disabled={p1.value === 'active' ? true : false} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-auto">
                                <CommanButton type="submit" className="btnBack mb-3" onClick={nameAndSubscriberValidation} disabled={p1.value === 'active' ? true : false} >Submit</CommanButton>
                            </div>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="3" able={p2.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Upload your audio file . &nbsp;
                            <span className="form-text">This is a *.wav file that represents your Ad-RBT. This is the message that will be heard when somebody calls your staff.</span></label>
                        <div className="row g-3 justify-content-center">

                            <div className="col-auto">
                                <input type="file" accept='.wav' className="form-control border-secondary" id="audioFile" onChange={(e) => {
                                    (e.target?.files[0]?.size <= 2000000) ? audioFile.value = e.target.files[0] : toast.error("File must be less than 2MB", { toastId: toastID })
                                }}
                                    disabled={p2.value === 'active'} />
                                <label style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '0.7rem', color: '#ff0202c7' }}> *Extension Must be .wav file(maxsize-2mb)</label>
                            </div>
                            <div className="col-auto">
                                <CommanButton type="submit" className="btnBack mb-3" onClick={audioFileValidation} disabled={p2.value === 'active'} >Submit</CommanButton>
                            </div>
                            <Tooltip title="Play / Stop" >
                                <div className="col-auto">
                                    <IconButton disabled={(p2.value === 'active') || (audioFile.value === null)} onClick={audioPlay} >
                                        {audioPlayStatus.value.status ? <StopIcon /> : <PlayArrowIcon />}
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </OuterBox>

                <OuterBox value="4" able={p3 === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Schedule. &nbsp;
                            <span className="form-text"> Set up days at which your Ad-RBT campaign will be run</span></label>
                        <div className="row g-4 justify-content-center">
                            <div className="col-auto d-flex align-items-center">


                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                                        <DatePicker
                                            label="From"
                                            value={scheduleFrom.value}
                                            onChange={(newValue) => scheduleFrom.value = newValue}
                                            minDate={dayjs(new Date())}
                                            disabled={p3.value === 'active' ? true : false}
                                        />
                                        <DatePicker
                                            label="To"
                                            value={scheduleTo.value}
                                            onChange={(newValue) => { scheduleTo.value = newValue; p4.value = 'completed' }}
                                            minDate={scheduleFrom.value}
                                            disabled={(scheduleFrom.value === undefined ? true : false) || (p3.value === 'active' ? true : false)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>

                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="5" able={p4.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Select premium reporting . &nbsp;
                            <span className="form-text">By checking the box you add US $500 to your monthly bill and get access to premium data detail.</span></label>
                        <div className="row g-3 justify-content-center">
                            <div className="form-check col-auto d-flex align-items-center">
                                <ToggleButtonGroup

                                    size='small'
                                    value={premiumReport.value}
                                    exclusive
                                    onChange={(e, v) => { premiumReport.value = v }}
                                    disabled={p4.value === 'active' ? true : false}
                                    sx={{
                                        boxShadow: 1,
                                        borderRadius: 2,
                                    }}
                                >
                                    <ToggleButton value="Yes" aria-label="Yes" >
                                        <CheckIcon />
                                    </ToggleButton>
                                    <ToggleButton value="No" aria-label="No" >
                                        <ClearIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <label className="form-check-label mx-2" htmlFor="">
                                    {premiumReport.value === null && "Want access to premium reporting ?"}
                                    {premiumReport.value === 'Yes' && "Yes. I want access to premium reporting."}
                                    {premiumReport.value === 'No' && "No. I don't want access to premium reporting."}
                                </label>
                            </div>
                        </div>
                    </div>
                </OuterBox>


                <OuterBox value="6" able={p5.value === 'active' ? true : false} >
                    <div className="my-2 w-100" >
                        <label htmlFor="" className="form-label fw-semibold formLabel">Review. &nbsp;
                            <span className="form-text">Confirm that all the information has been filled correctly.</span></label>
                        <div className="row g-3 justify-content-center">

                            <div className="form-check col-auto">
                                <input className="form-check-input border-secondary" type="checkbox" value="" id="review" onChange={() => { reviewCheck.value = !reviewCheck.value }} disabled={p5.value === 'active' ? true : false} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    I´ve reviewed all the information that will be submitted
                                </label>
                            </div>
                        </div>
                    </div>
                </OuterBox>

                <div className="d-flex justify-content-center">
                    <CommanButton type="submit" className="btnBack m-3" onClick={sendHit} >SUBMIT</CommanButton>
                </div>
                <BackDropLoader opener={loading.value} />

            </div >
        </div >
    )
}


