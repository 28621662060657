import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false
};

// Async thunk for fetching LBS templates
export const getLbsTemplates = createAsyncThunk("getLbsTemplates", async ( token ) => {
    try {
        const response = await axiosInstance.get("/CampaignConfiguration/getLbsTemplates", {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return response.data;
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage);
        }
        throw error;
    }
});

export const lbsTemplates = createSlice({
    name: 'lbsTemplates',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLbsTemplates.fulfilled, (state, action) => {
                state.data = action.payload?.body[0];
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getLbsTemplates.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getLbsTemplates.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default lbsTemplates.reducer;
