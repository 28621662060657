import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false
};

// Async thunk for fetching corporate pack details
export const getCorpPackDetails = createAsyncThunk("getCorpPackDetails", async (token) => {
    try {
        const response = await axiosInstance.get('/getCorp_PackDetails', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        return response; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});

// Slice for corporate pack details
export const CorpPackDetails = createSlice({
    name: 'corpPackDetails',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getCorpPackDetails.fulfilled, (state, action) => {
                state.data = action.payload; // Adjust based on the actual response structure
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getCorpPackDetails.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getCorpPackDetails.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default CorpPackDetails.reducer;
