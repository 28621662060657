import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CommanButton from '../../../component/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCorpById } from '../../../redux/slice/ListCorp';
import { useSignal } from '@preact/signals-react';
import { toast } from 'react-toastify';
import Loader from '../../../component/loader/Loader';
import ErrorPage from '../../../component/error/ErrorPage';

function ViewCorp() {
    let { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(state => state.token?.data?.token)
    const data = useSignal({})
    const loading = useSignal(true)
    const isError = useSignal(false)

    if ((id === null) || (id === undefined)) {
        navigate('/campaign/viewCorpRbt')
    }

    useEffect(() => {
        dispatch(getCorpById({ token: token, id: id }))
            .then((resp) => {
                console.log(resp)
                if (resp?.payload?.data?.body?.length !== 0) {
                    data.value = resp?.payload?.data?.body[0]
                    loading.value = false
                } else {
                    toast.error("Corp-Rbt not found")
                    navigate('/campaign/viewCorpRbt')
                }
            }).catch(() => {
                toast.error("Error while loading")
                isError.value = true
            })
    }, [])


    const renderTableRow = (label, value) => {
        return (
            <tr style={{ border: '1px solid #c1bbbb' }}>
                <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>{label}</td>
                <td className='px-5 py-3 text-break'>{value}</td>
            </tr>
        );
    };

    if (loading.value) {
        return <Loader />
    } else if (isError.value) {
        return <ErrorPage />
    } else {
        return (
            <div className='mx-3'>
                <div className='d-flex justify-content-between my-2 mx-2'>
                    <h3 className='fw-bolder'>View Corp-RBT ✨</h3>
                    <div className="d-flex justify-content-center ">
                        <Link
                            to='/campaign/addCorpRbt'
                            style={{ textDecoration: 'none' }}>
                            <CommanButton type="submit"  ><AddIcon />Add Corp-RBT</CommanButton>
                        </Link>
                        <Link to="/campaign/viewCorpRbt">
                            <CommanButton type="submit"  ><ArrowBackIosIcon />Back</CommanButton>
                        </Link>
                    </div>
                </div>
                <div className='clsflex'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <table>
                            <thead>
                            {renderTableRow('Time', data.value.time)}
                                {renderTableRow('Template Name', data.value.template_name)}
                                {renderTableRow('Upload List', data.value.upload_list)}
                                {renderTableRow('Audio File', data.value.audio_file)}
                                {renderTableRow('Start Date', data.value.start_date?.slice(0, 10))}
                                {renderTableRow('End Date', data.value.end_date?.slice(0, 10))}
                                {renderTableRow('Day Scheduler', data.value.day_schedular)}
                                {data.value.status !== null && renderTableRow('Status', data.value.status)}
                                {renderTableRow('Time', data.value.time)}
                                {renderTableRow('Pack Id', data.value.pack_id)}
                            </thead>
                        </table>
                    </div>

                </div>
            </div>
        )
    }
}

export default ViewCorp;
