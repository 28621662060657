import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CommanButton from '../../../component/CommanButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSignal } from '@preact/signals-react';
import { getCampaignById } from '../../../redux/slice/ListPVM';
import { toast } from 'react-toastify';
import Loader from '../../../component/loader/Loader';
import ErrorPage from '../../../component/error/ErrorPage';

function ViewPvm(props) {
    let { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useSelector(state => state.token?.data?.token)
    const data = useSignal({})
    const loading = useSignal(true)
    const isError = useSignal(false)

    if ((id === null) || (id === undefined)) {
        navigate('/campaign/viewpvm')
    }

    useEffect(() => {
        dispatch(getCampaignById({ token: token, id: id }))
            .then((resp) => {
                console.log(resp)
                if (resp?.payload?.data?.httpStatusCode === 200) {
                    data.value = resp?.payload?.data?.body[0]
                    loading.value = false
                } else {
                    toast.error("Voice Campaign not found")
                    navigate('/campaign/viewpvm')
                }
            }).catch(() => {
                toast.error("Error while loading")
                isError.value = true
            })
    }, [])

    if (loading.value) {
        return <Loader />
    } else if (isError.value) {
        return <ErrorPage />
    } else {
        return (
            <div className='mx-3'>
                <div className='d-flex justify-content-between my-2 mx-2'>
                    <h3 className='fw-bolder'>View Voice Campaign ✨</h3>
                    <div className="d-flex justify-content-center">
                        <Link
                            to='/campaign/addpvm'
                            style={{ textDecoration: 'none' }}>
                            <CommanButton type="submit"  ><AddIcon />Add PVM</CommanButton>
                        </Link>
                        <Link to='/campaign/viewpvm'>
                            <CommanButton type="submit"  ><ArrowBackIosIcon />Back</CommanButton>
                        </Link>
                    </div>
                </div>
                <table style={{ display: 'flex', justifyContent: 'center' }}>
                    <thead>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Campaign Id</td>
                            <td className='px-5 py-3'>{data.value.campaign_ID}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Campaign Name</td>
                            <td className='px-5 py-3'>{data.value.campaign_name}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Campaign Message</td>
                            <td className='px-5 py-3'>{data.value.campaign_message}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Start Date</td>
                            <td className='px-5 py-3'>{data.value.startdate?.slice(0, 10)}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>End Date</td>
                            <td className='px-5 py-3'>{data.value.enddate?.slice(0, 10)}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Created By</td>
                            <td className='px-5 py-3'>{data.value.created_by}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Admin Number</td>
                            <td className='px-5 py-3'>{data.value.admin_msisdn}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Balance</td>
                            <td className='px-5 py-3'>{data.value.balance}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Start Hour</td>
                            <td className='px-5 py-3'>{data.value.startHour}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>End Hour</td>
                            <td className='px-5 py-3'>{data.value.endHour}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Days-of-Week</td>
                            <td className='px-5 py-3'>{data.value.day_of_week}</td>
                        </tr>
                        <tr style={{ border: '1px solid #c1bbbb' }}>
                            <td className='px-5 py-3' style={{ fontWeight: 'bold', border: '1px solid #c1bbbb' }}>Campaign Recycle</td>
                            <td className='px-5 py-3'>{data.value.camp_recycle}</td>
                        </tr>
                    </thead>
                </table>

            </div>
        );
    }
}

export default ViewPvm;
