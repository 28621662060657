import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from "../store/Api";
import { toast } from "react-toastify";

const initialState = {
    data: null,
    isLoading: true,
    error: false
};

// Async thunk for fetching origination numbers
export const getOriginationNumber = createAsyncThunk("getOriginationNumber", async (token) => {
    try {
        const response = await axiosInstance.get('/getAllOriginationNumber', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        // console.log(response.data)
        return response.data; // Return the data from the response
    } catch (error) {
        if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.errorMessage || 'An error occurred');
        }
        throw error; // Rethrow the error to be caught in the rejected case
    }
});

// Slice for origination numbers
export const OriginationNumber = createSlice({
    name: 'originationNumber',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getOriginationNumber.fulfilled, (state, action) => {
                state.data = action.payload?.body[0]; // Adjust based on the actual response structure
                state.error = false;
                state.isLoading = false;
            })
            .addCase(getOriginationNumber.pending, (state) => {
                state.error = false;
                state.isLoading = true;
            })
            .addCase(getOriginationNumber.rejected, (state) => {
                state.error = true;
                state.isLoading = false;
            });
    }
});

export default OriginationNumber.reducer;
