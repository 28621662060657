import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../component/loader/Loader'
import { getAllVendorCreditHistoryByCalculating } from './slice.js/CreditHistory'
import CommanButton from '../../../component/CommanButton'
import { toast } from 'react-toastify'

export default function CreditHistoryPerMonth() {
    let { month, year } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [data, setdata] = useState(null)
    const userId = useSelector(state => state?.profile?.data?.useridd)
    const availableBalance = useSelector(state => state?.profile?.data?.availbalance)
    const creditLimit = useSelector(state => state?.profile?.data?.creditlimit)
    const token = useSelector(state => state?.token?.data?.token)

    if ((month === undefined) || (month === null) || (year === undefined) || (year === null)) {
        navigate('/creditHistory')
    }

    useEffect(() => {
        let request = {
            "userId": userId + '',
            "availBalance": availableBalance,
            "creditLimit": creditLimit,
            "year": year,
            "month": month
        }
        if (availableBalance !== undefined || creditLimit !== undefined) {
            dispatch(getAllVendorCreditHistoryByCalculating({ token: token, data: request }))
                .then((response) => {
                    if(response?.payload?.data?.httpStatusCode === 200){
                        setdata(response?.payload?.data?.body[0]?.Total_Spent)
                    }else{
                        toast.error('Internal server error')
                    }
                    setLoading(false)
                }).catch((error) => {
                    console.log(error)
                    setLoading(false)
                    toast.error("Error while getting list")
                })
        }
    }, [availableBalance, creditLimit])


    if (loading) {
        return <Loader />
    } else {
        return (
            <div className='m-4'>
                <div className='container fs-6 fw-medium w-50'>
                    <Row className='text-left'>
                        <Col>Period</Col>
                        <Col>{month} - {year}</Col>
                    </Row>
                    <Row className='text-left'>
                        <Col>Credit Limit</Col>
                        <Col>US$ {parseFloat(creditLimit).toLocaleString("en-US")}</Col>
                    </Row>
                    <Row className='text-left'>
                        <Col>Total Spent</Col>
                        <Col>US$ {data === null ? 0 : parseFloat(data).toLocaleString("en-US") } </Col>
                    </Row>
                    <Row className='text-left'>
                        <Col>Credit available</Col>
                        <Col>US$ {parseFloat(availableBalance).toLocaleString("en-US")}</Col>
                    </Row>
                </div>
                <div className='d-flex justify-content-center my-4'>
                    <CommanButton onClick={()=>{navigate(`/creditHistoryDaily/${year}/${month}`)}}>Daily Spent</CommanButton>
                </div>
            </div>
        )
    }
}



