import React, { useEffect } from 'react';
import ContactInfo from './ContactInfo';
import LimitsGraph from './LimitsGraph';
import { useSignal } from "@preact/signals-react";
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from '../../component/error/ErrorPage';
import Loader from '../../component/loader/Loader';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { SendMail } from '../../redux/slice/SendMail';
import BackDropLoader from '../../component/loader/BackDropLoader';
import Box from '@mui/material/Box';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { RequestService, saveRequestService } from '../../redux/slice/ServiceManager';

function MyProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let getProfileData = useSelector(state => state.profile);
    const data = useSignal({});
    const sendMailLoading = useSignal(false);


    useEffect(() => {
        data.value = getProfileData;
    }, [getProfileData]);



    if (data.value?.isLoading) {
        return <Loader />;
    } else if (data.value?.error) {
        return <ErrorPage />;
    } else {
        return (
            <Box sx={{ marginBottom: '2em', marginLeft: '25px', fontSize: '15px' }}>
                <ContactInfo data={data.value?.data} />
                <Divider sx={{ my: 2 }} />
                <LimitsGraph />
                <Divider sx={{ my: 2 }} />
                <RequestSection />
            </Box>
        );
    }
}

export default MyProfile;


const RequestSection = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sendMailLoading = useSignal(false);
    let getProfileData = useSelector(state => state.profile);
    let userId = useSelector(state => state.vendor?.data?.user_id)
    let userName = useSelector(state => state.profile?.data?.username)
    const token = useSelector(state => state.token?.data?.token);
    const requestData = useSignal([])
    const loading = useSignal()
    const selectRequest = useSignal("")

    useEffect(() => {
        dispatch(RequestService(token))
            .then((resp) => {
                // console.log(resp)
                if (resp?.payload?.status === 200) {
                    requestData.value = resp?.payload?.data?.body
                }

            })
            .catch((err) => {
                toast.error("Error while creating")
            });
    }, [])

    // console.log(getProfileData)

    let username = getProfileData?.data?.username

    let recipientMail = getProfileData?.data?.email;
    let recipientName = getProfileData?.data?.salesresp;
    let sendMailData = {
        "recipient": recipientMail,
        "msgBody": `Dear ${username},\n\n ${selectRequest.value?.serviceDescription}`,
        "subject": selectRequest.value?.serviceSubject,
        "date": dayjs().format('YYYY-MM-DD HH:mm:ss')
    };

    console.log(sendMailData)

    let createMailBody = () => {
        if (Object.keys(selectRequest.value).length === 0) {
            toast.error('Please select Request type');
        } else {

            let data = {
                "requestDate": dayjs().format('YYYY-MM-DD HH:mm:ss'),
                "userId": userId,
                "serviceId": selectRequest.value?.serviceId,
                "status": "P",
                "updateDate": dayjs().format('YYYY-MM-DD HH:mm:ss'),
                "username": userName
            }
            sendMailLoading.value = true
            dispatch(saveRequestService({ 'data': data, token: token }))
                .then((resp) => {
                    // console.log(resp)
                    if (resp?.payload?.status === 200) {
                        // requestData.value = resp?.payload?.data?.body
                        // navigate('/success', {
                        //     state: {
                        //         mailBody: selectRequest.value?.serviceDescription,
                        //         sendMail: recipientName,
                        //         url: '/'
                        //     }
                        // });
                        return dispatch(SendMail({ 'data': sendMailData, "token": token }))
                    }
                    // sendMailLoading.value = false

                })
                .then((resp) => {
                    console.log(resp)
                    if (resp?.payload?.status === 200) {
                        // requestData.value = resp?.payload?.data?.body
                        navigate('/success', {
                            state: {
                                mailBody: selectRequest.value?.serviceName,
                                sendMail: recipientName,
                                url: '/'
                            }
                        });
                    }
                    sendMailLoading.value = false

                })
                .catch((err) => {
                    toast.error("Error While Requesting")
                });
        }
    };


    if (loading.value) {
        return <Loader />
    } else {
        return (
            <>
                <Box>
                    <Typography variant="h6" gutterBottom>Request Section</Typography>
                    <Typography component={'label'} sx={{ fontWeight: '600' }} >Please select the request you need</Typography>
                    <Grid container alignContent={'center'} sx={{ my: 2 }} spacing={1} >
                        <Grid item xs={4} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Request</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Request"
                                    value={selectRequest.value}

                                    onChange={(event) => { selectRequest.value = event.target.value; }}
                                >
                                    {requestData.value?.map((each, index) => (
                                        <MenuItem key={index} value={each}>{each.serviceName}-${each.serviceCharges}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="body1" >
                                {
                                    selectRequest.value !== ""
                                    &&
                                    `Request for ${selectRequest.value?.serviceName}`
                                }
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button variant="contained" color="primary" onClick={createMailBody} >Send</Button>
                </Box>
                <BackDropLoader opener={sendMailLoading.value} />
            </>
        )
    }
}