import React, { useEffect, useState } from 'react';
import BarChart from '../../component/graph/BarChart';
import { useSignal } from '@preact/signals-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getAllVendorCreditHistoryByCalculatingForTHreeMOnths } from './creditHistory/slice.js/CreditHistory';
import { Box, Typography, CircularProgress, Grid } from '@mui/material';

const LimitsGraph = () => {
    const dispatch = useDispatch();
    const data = useSignal({});
    const hitData = useSignal({});
    const [loading, setLoading] = useState(true);
    const userId = useSelector(state => state?.profile?.data?.useridd);
    const availableBalance = useSelector(state => state?.profile?.data?.availbalance);
    const creditLimit = useSelector(state => state?.profile?.data?.creditlimit);
    const token = useSelector(state => state?.token?.data?.token);
    let monthData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    useEffect(() => {
        let request = {
            "userId": userId + '',
            "availBalance": availableBalance,
            "creditLimit": creditLimit,
            "year": dayjs().year(),
            "month": monthData.at(dayjs().month())
        };
        setLoading(true);
        if (availableBalance !== undefined || creditLimit !== undefined) {
            dispatch(getAllVendorCreditHistoryByCalculatingForTHreeMOnths({ token: token, data: request }))
                .then((response) => {
                    if (response?.payload?.data?.httpStatusCode === 200) {
                        hitData.value = response?.payload?.data?.body[0];
                    } else {
                        toast.error('Internal server error');
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast.error("Error while getting list");
                });
        }

        data.value = { creditLimit, availableBalance };
    }, [creditLimit, availableBalance]);

    function getLastThreeMonths() {
        const currentDate = new Date();
        const lastThreeMonths = [];

        for (let i = 0; i < 3; i++) {
            lastThreeMonths.unshift(monthData[currentDate.getMonth()]);
            currentDate.setMonth(currentDate.getMonth() - 1); // Move back one month
        }

        return lastThreeMonths;
    }

    let lastMonth = getLastThreeMonths();

    // console.log(hitData.value);

    if (loading) {
        return <CircularProgress />;
    } else {
        return (
            <Box display="flex" justifyContent="space-around" flexWrap="wrap" m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box textAlign="center" p={2}>
                            <Typography variant="h5">Available Balance</Typography>
                            <Typography variant="h4" my={2}>US$ {parseFloat(data.value?.availableBalance).toLocaleString("en-US")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box textAlign="center" p={2}>
                            <Typography variant="h5">Credit Limit</Typography>
                            <Typography variant="h4" my={2}>US$ {parseFloat(data.value?.creditLimit).toLocaleString("en-US")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box textAlign="center" p={2}>
                            <Typography variant="h5">Graph</Typography>
                            <BarChart data={[hitData.value?.Second_LastMOnth_TotalSpent, hitData.value?.Last_MOnth_Total_Spent, hitData.value?.Total_Spent]} catagory={lastMonth} width='300px' />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default LimitsGraph;
