import React, { useEffect, useState } from 'react';
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Modal } from 'react-bootstrap';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Loader from '../../../component/loader/Loader';
import ErrorPage from '../../../component/error/ErrorPage';
import { useSignal } from '@preact/signals-react';
import CommanButton from '../../../component/CommanButton';
import { deletePvm, getCampaignById, getProfileDataById, getPvmList, GetServiceCampaignMailling, SendCampaningMail } from '../../../redux/slice/ListPVM';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';


function PVM() {
    const campApprove = JSON.parse(sessionStorage.getItem('campApprove'))
    const dispatch = useDispatch()

    const getProfileData = useSelector(state => state.profile.data)
    const token = useSelector(state => state.token?.data?.token)
    const data = useSelector(state => state.pvmList)
    const userId = useSelector(state => state.vendor?.data?.user_id)
    // console.log(data)
    const isLoading = useSignal(false)

    const currentPage = useSignal(1)
    const requestData = useSignal({})


    useEffect(() => {
        isLoading.value = true
        dispatch(getPvmList({ token: token, id: userId })).then(() => {
            return dispatch(GetServiceCampaignMailling({ token: token, id: 1 }))
        }).then((resp) => {
            if (resp?.payload?.status === 200) {
                // console.log(resp?.payload?.data)
                requestData.value = resp?.payload?.data?.body[0]
                isLoading.value = false
            }

        })
    }, []);

    const onDelete = (id) => {
        dispatch(deletePvm({ id: id, token: token })).then((resp) => {
            console.log(resp)
            if (resp.payload?.data?.httpStatusCode === 200) {
                toast.success('Deleted Successfully')
                dispatch(getPvmList({ token: token, id: userId }))
            } else {
                toast.error('Error while deleting')
            }
        })
    }
    const campaignApproval = async (status, campId, campaignName) => {
        try {
            isLoading.value = true;
    
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/updateCampaignStatus/${status}/${campId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (resp?.data?.httpStatusCode !== 200) {
                throw new Error("Error while approving campaign.");
            }
    
            await dispatch(getPvmList({ token, id: userId }));
            const campaignResp = await dispatch(getCampaignById({ token, id: campId }));
    
            const userIIIId = campaignResp?.payload?.data?.body?.[0]?.userId;
            if (!userIIIId) throw new Error("User ID missing in campaign data.");
    
            const profileResp = await dispatch(getProfileDataById({ id: userIIIId, token }));
            const UserData = profileResp?.payload?.body?.[0];
    
            if (!UserData) throw new Error("User profile data not found.");
    
            const { username, email: recipientMail, salesemail } = UserData;
            const sendMailData = {
                recipient: recipientMail,
                msgBody: `Dear ${username},\n\n${requestData.value?.serviceDescription} ${campId}, ${campaignName}`,
                subject: requestData.value?.serviceSubject,
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                salesemail,
            };
    
            const mailResp = await dispatch(SendCampaningMail({ data: sendMailData, token }));
    
            if (mailResp?.payload?.status !== 200) {
                throw new Error("Error while sending campaign email.");
            }
    
            toast.info("Campaign Approved Successfully");
        } catch (error) {
            console.error(error);
            toast.error("Error while Approving Campaign");
        } finally {
            isLoading.value = false;
        }
    };
    


    const perPage = 15;
    let indexofLast = currentPage.value * perPage
    let indexofFirst = indexofLast - perPage
    let activePage = data.data?.slice(indexofFirst, indexofLast)

    if (isLoading.value) {
        return (
            <Loader />
        )
    } else if (data.isError) {
        return (
            <ErrorPage />
        )
    } else {
        return (
            <div className='mx-3' >
                <div className='d-flex justify-content-between my-2'>
                    <h3 className='fw-bolder'>View Voice Campaign ✨</h3>
                    <Link
                        to='/campaign/addpvm'
                        style={{ textDecoration: 'none' }}>
                        <CommanButton type="submit" className="" ><AddIcon />Add PVM</CommanButton>
                    </Link>
                </div>
                {
                    data.data?.length === 0 ?
                        <div className='d-flex justify-content-center fw-bold' >No PVM Campaign found</div>
                        :
                        <>
                            <p >This list shows all the Voice campaigns carried out by the user.</p>
                            <TableContainer >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className='bodyColor'>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>CAMPAIGN ID</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>CAMPAIGN NAME</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>START DATE</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>END DATE</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>STATUS</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>VIEW</TableCell>
                                            {
                                                campApprove?.linkId === parseInt(process.env.REACT_APP_CAMPAIGN_APPROVAL_LINK) &&
                                                <TableCell align="center" style={{ fontWeight: 'bolder' }}>ACTION</TableCell>
                                            }
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}>RE-USE</TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bolder' }}></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className="">
                                        {activePage?.map((listCard) => (
                                            <ViewPvmList
                                                key={listCard.CAMPAIGN_ID}
                                                list={listCard}
                                                remove={onDelete}
                                                campApprove={campApprove}
                                                approval={campaignApproval}
                                            />
                                        ))}

                                    </TableBody>
                                </Table>
                                {
                                    data.data?.length > perPage &&
                                    <div className='d-flex justify-content-center my-4'>
                                        <Pagination count={Math.ceil(data.data?.length / perPage)} variant="outlined" shape="rounded" onChange={(e, p) => { currentPage.value = p }} />
                                    </div>
                                }
                            </TableContainer>
                        </>
                }
            </div>
        );
    }



}

export default PVM;

const ViewPvmList = ({ list, remove, campApprove, approval }) => {
    const data = useSignal(list)
    const modal = useSignal(false)
    const isHovered = useSignal(false)

    const textStyle = {
        color: isHovered.value ? '#6366f1' : '#6082B6', // Change 'red' to the desired hover color
        cursor: 'pointer', // Optional: Change cursor style on hover
        fontWeight: 'bolder',
        margin: '0',
        padding: '0'
    };

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align='center' style={{ color: '#6366F1', fontWeight: '600' }}>
                {data.value.CAMPAIGN_ID}
            </TableCell>
            <TableCell align="center" >
                {data.value.CAMPAIGN_NAME}
            </TableCell>
            <TableCell align="center" >
                {data.value.START_DATE?.slice(0, 10)}
            </TableCell>
            <TableCell align="center" >
                {data.value.END_DATE?.slice(0, 10)}
            </TableCell>
            <TableCell align="center" >
                {(data.value.STATUS === 'R' && "Running") || (data.value.STATUS === 'P' && "Paused") || (data.value.STATUS === 'N' && "New") || (data.value.STATUS === 'C' && "Completed") || (data.value.STATUS === 'A' && "Approved") || (data.value.STATUS === 'E' && "Reject") || (data.value.STATUS === 'T' && "Tested")}
            </TableCell>
            <TableCell align='center' >
                <Link to={`/campaign/viewpvmlist/${data.value.CAMPAIGN_ID}`}>
                    <VisibilityOutlinedIcon style={{ color: 'black' }} />
                </Link>
            </TableCell>
            {
                campApprove?.linkId === parseInt(process.env.REACT_APP_CAMPAIGN_APPROVAL_LINK) &&
                <TableCell align="center" >
                    <p style={textStyle} onClick={() => { approval("A", data.value.CAMPAIGN_ID,data.value.CAMPAIGN_NAME) }} onMouseEnter={() => { isHovered.value = true }}
                        onMouseLeave={() => { isHovered.value = false }} >Approve</p>
                </TableCell>
            }
            <TableCell align="center" >
                <Tooltip title={`Modify ${data.value.CAMPAIGN_NAME}`} >
                    <Link to='/campaign/modifyPVM' state={{ data: data.value }} >
                        <EditNoteSharpIcon />
                    </Link>
                </Tooltip>
            </TableCell>
            <TableCell align="center">
                <Tooltip title={`Delete ${data.value.CAMPAIGN_NAME}`} >
                    <button className='border-0 bg-transparent' onClick={() => { modal.value = !modal.value }}>
                        <DeleteForeverIcon style={{ color: 'red' }} />
                    </button>
                </Tooltip>

                <Modal show={modal.value} onHide={() => { modal.value = !modal.value }}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-danger'>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, you want to delete this {data.value.CAMPAIGN_NAME} ?</Modal.Body>
                    <Modal.Footer>
                        <CommanButton className='btn btn-danger' onClick={() => remove(data.value.CAMPAIGN_ID)}>
                            Delete
                        </CommanButton>
                    </Modal.Footer>
                </Modal>
            </TableCell>
        </TableRow>
    );
}