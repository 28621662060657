import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Login from "./pages/loginPage/Login";
import MyProfile from "./pages/myprofile/MyProfile";
import Playground from "./component/sidebar/Playground";
import AddPvm from "./pages/createCamp/pvm/AddPvm";
import PVM from "./pages/createCamp/pvm/PVM";
import ViewPvm from "./pages/createCamp/pvm/ViewPvm";
import AddAdRBT from "./pages/createCamp/adCorp/AddAdRBT";
import AdRBT from "./pages/createCamp/adCorp/AdRBT";
import AddCorp from "./pages/createCamp/corp/AddCorp";
import Corp from "./pages/createCamp/corp/Corp";
import ViewAd from "./pages/createCamp/adCorp/ViewAd";
import SuccessPage from "./component/success/SuccessPage";
import PvmList from "./pages/reporting/pvmReport/PvmList";
import SpecificPvmCamp from "./pages/reporting/pvmReport/SpecificPvmCamp";
import GeneratedReport from "./pages/reporting/pvmReport/GeneratedReport";
import ViewCorp from "./pages/createCamp/corp/ViewCorp";
import UPCAdRbt from "./pages/createCamp/adCorp/UPCAdRbt";
import UPCCorpRbt from "./pages/createCamp/corp/UPCCorpRbt";
import UPCPVM from "./pages/createCamp/pvm/UPCPVM";
import SuccessTemp from "./component/success/SuccessTemp";
import CorpList from "./pages/reporting/corpReport/CorpList";
import SpecificCorpCamp from "./pages/reporting/corpReport/SpecificCorpCamp";
import CorpGeneratedReport from "./pages/reporting/corpReport/CorpGeneratedReport";
import AdList from "./pages/reporting/adReport/AdList";
import SpecificAdCamp from "./pages/reporting/adReport/SpecificAdCamp";
import AdGeneratedReport from "./pages/reporting/adReport/AdGeneratedReport";
import Tutorial from "./pages/tutorial/Tutorial";
import ErrorPage from "./component/error/ErrorPage";
import CreditLimitHistory from "./pages/myprofile/creditHistory/CreditLimitHistory";
import CreditHistoryPerMonth from "./pages/myprofile/creditHistory/CreditHistoryPerMonth";
import CreditHistoryDaily from "./pages/myprofile/creditHistory/CreditHistoryDaily";
import ManageCharges from "./pages/manageCharges/ManageCharges";
import { useSelector } from "react-redux";
import RevenueReport from "./pages/reporting/RevenueReport";
import TransActionalReport from "./pages/reporting/TransActionalReport";
import CampaignReport from "./pages/reporting/CampaignReport";
import AccountStatusReport from "./pages/reporting/AccountStatusReport";


const Router = () => {
    const roleId = useSelector(state => state.vendor?.data?.roleId)

    return createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/login" element={<Login />} />
                <Route path='/' element={<Playground />} >
                    <Route path="" element={<MyProfile />} />

                    <Route path="manageCharges" element={ <ManageCharges /> } />


                    <Route path="creditHistory" element={<CreditLimitHistory />} />
                    <Route path="creditHistory/:year/:month" element={<CreditHistoryPerMonth />} />
                    <Route path="creditHistoryDaily/:year/:month" element={<CreditHistoryDaily />} />

                    <Route path="campaign/addpvm" element={<AddPvm />} />
                    <Route path="campaign/viewpvm" element={<PVM />} />
                    <Route path="campaign/viewpvmlist/:id" element={<ViewPvm />} />
                    <Route path="campaign/modifyPVM" element={<UPCPVM />} />

                    <Route path="campaign/addAdRbt" element={<AddAdRBT />} />
                    <Route path="campaign/viewAdRbt" element={<AdRBT />} />
                    <Route path="campaign/viewAdRbt/:id" element={<ViewAd />} />
                    <Route path="campaign/modifyAdRbt" element={<UPCAdRbt />} />

                    <Route path="campaign/addCorpRbt" element={<AddCorp />} />
                    <Route path="campaign/viewCorpRbt" element={<Corp />} />
                    <Route path="campaign/viewCorpRbt/:id" element={<ViewCorp />} />
                    <Route path="campaign/modifyCorpRbt" element={<UPCCorpRbt />} />



                    <Route path="pvmReport" element={<PvmList />} />
                    <Route path="graphPvmReport/:id" element={<SpecificPvmCamp />} />
                    <Route path="generatePvmReport/:id" element={<GeneratedReport />} errorElement={<ErrorPage />} />

                    <Route path="corpReport" element={<CorpList />} />
                    <Route path="graphCorpReport/:id/:userid" element={<SpecificCorpCamp />} />
                    <Route path="generateCorpReport/:id/:userid" element={<CorpGeneratedReport />} />

                    <Route path="adReport" element={<AdList />} />
                    <Route path="graphAdReport/:id" element={<SpecificAdCamp />} />
                    <Route path="generateAdReport/:id" element={<AdGeneratedReport />} />

                    <Route path="revenueReport" element={<RevenueReport />} />
                    <Route path="transactionalReport" element={<TransActionalReport />} />
                    <Route path="campaignReport" element={<CampaignReport />} />
                    <Route path="AccountStatusReport" element={<AccountStatusReport />} />


                    <Route path="tutorial" element={<Tutorial />} />

                    <Route path="/success" element={<SuccessPage />} />
                    <Route path="/successtemp" element={<SuccessTemp />} />


                </Route>
            </>
        ),{basename:'/Outreach/Vendor'}
    )
}

export default Router;
