import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../../redux/store/Api';



export const getAdReport = createAsyncThunk(
    'reportAd/getAdReport', // Unique name for the action
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get('/betweenDatesAddRbt', {
          params: {
            startDate: `${data.start}T00:00:00`,
            endDate: `${data.end}T23:59:59`
          },
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
  
        return response.data; // Return only the data from the response
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred'); // Return a rejected value for error handling in the slice
      }
    }
  );
  

// Async thunk for getting ad summary reports
export const getAdSummaryReports = createAsyncThunk(
  'reportAd/getAdSummaryReports',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/SystemConfig/getADVSummaryReports', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);


export const getAdReportBySpecificId = createAsyncThunk(
    'reportAd/getAdReportBySpecificId',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(`/getAddRbtById/${data.id}`, {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );


const initialState = {
  data: null,
  isLoading: true,
  error: false
};

const reportAdSlice = createSlice({
  name: 'reportAd',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle getAdReport
    builder.addCase(getAdReport.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    });
    builder.addCase(getAdReport.pending, (state) => {
      state.error = false;
      state.isLoading = true;
    });
    builder.addCase(getAdReport.rejected, (state, action) => {
      state.error = action.payload || true;
      state.isLoading = false;
    });

    // Handle getAdSummaryReports
    builder.addCase(getAdSummaryReports.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    });
    builder.addCase(getAdSummaryReports.pending, (state) => {
      state.error = false;
      state.isLoading = true;
    });
    builder.addCase(getAdSummaryReports.rejected, (state, action) => {
      state.error = action.payload || true;
      state.isLoading = false;
    });

    // Handle getAdReportBySpecificId
    builder.addCase(getAdReportBySpecificId.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = false;
      state.isLoading = false;
    });
    builder.addCase(getAdReportBySpecificId.pending, (state) => {
      state.error = false;
      state.isLoading = true;
    });
    builder.addCase(getAdReportBySpecificId.rejected, (state, action) => {
      state.error = action.payload || true;
      state.isLoading = false;
    });
  }
});

export default reportAdSlice.reducer;
