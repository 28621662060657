import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../store/Api'; // Adjust the path as needed
import { toast } from 'react-toastify';

export const RequestService = createAsyncThunk(
  'service/requestService',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/fetchServiceChargesnadIDs', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response; // Return only the data
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
      }
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);


export const saveRequestService = createAsyncThunk(
    'service/saveRequestService',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post('/saveEmailServiceCharges', data.data, {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );

  export const allVendorRequest = createAsyncThunk(
    'vendor/allVendorRequest',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(`/fetchALLVendorRequests/${data.userId}`, {
          headers: {
            'Authorization': `Bearer ${data.token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );



export const ActionRequestService = createAsyncThunk(
    'service/actionRequestService',
    async ({ token, status, updateDate, requestId }, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(`/statusUpdateEmailServiceCharges/${status}/${updateDate}/${requestId}`, null, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );

  export const deleteRequestService = createAsyncThunk(
    'service/deleteRequestService',
    async ({ token, requestId }, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.delete(`/deleteVendorRequest/${requestId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return response; // Return only the data
      } catch (error) {
        if (error?.response?.status === 500) {
          toast.error(error?.response?.data?.errorMessage || 'An unexpected error occurred.');
        }
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );
